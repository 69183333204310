/* import proxima-nova font */
@import url("https://use.typekit.net/hkm8ixt.css");

.cf:after{
    content: "";
    clear: both;
    display: table;
}
body{
    padding-bottom:0px;
}
.container{
    padding:0;
}
label{
    font-weight:initial;
}
a {
    cursor: pointer;
}
#app-wrapper, .fullWidthHeight{
    width:100%;
    height:100%;
}
.fullWidth{
    width:100%;
}
.fullHeight{
    height:100%;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease;
}
.fade-exit{
    opacity:1;
}
.fade-exit.fade-exit-active{
    opacity:0;
    transition: opacity 0.3s ease;
}

.routeWrapper{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    bottom:0;
    right:0;
    overflow-y:scroll;
    overflow-x:hidden;
}
.mainAreaRight{
    position:relative;
    width:calc(100% - 350px);
    height:100%;
    display:block;
    float:left;
}

.errorFocus:focus {
    outline: none
}
.transitionGroup{
    position:relative;
    width:100%;
    height:100%;
    display:block;
}
.react-toggle--focus .react-toggle-thumb,
#currently-work-here-checkbox [type="checkbox"]:focus + label:before,
.checkboxItem [type="checkbox"]:focus + label:before,
.econsentArea [type="checkbox"]:focus + label:before{
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
}
#nav-wrapper .navbar-brand{
    width:100% !important;
    height:100% !important;
    padding:0 !important;
}
.lastNameAndSuffixWrapper .apply-legal-last-name {
    width: 70%;
    float: left;
    padding-right: 0 !important;
}

.lastNameAndSuffixWrapper .apply-suffix {
    width: 25%;
    float: right;
    padding-right: 0 !important;
}
.flex {
    display: flex;
    flex-direction: row;
}

.flex-end {
    justify-content: flex-end
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-stretch {
    align-items: stretch!important;
}


.justify-space-between {
    justify-content: space-between;
}

.justify-space-around {
    justify-content: space-around;
}
#landingPageWrapper, #floify-login-page, #floify-login-page-main, #mobile-modal, #floify-reset-password-page {
    -webkit-font-smoothing: antialiased;
    font-family: proxima-nova, sans-serif;
    font-size: 12.5px!important;
    line-height: 1.6!important;
    letter-spacing: -0.24px!important;
}
#landingPageWrapper{
    width: 100%;
    height: 100vh;
    background: #f9fafb;
    transition:filter 0.5s ease;
    display: flex;
    flex-direction: column;
}
#landingPageWrapper input, #floify-login-page input, #floify-login-page-main input, #floify-reset-password-page input {
    height:40px;
    box-sizing:border-box;
    padding:0 12px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -0.24px;
    line-height:inherit;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #dfe8ea;
    color:#303637;
}

#floify-login-page-main .intro-text {
    font-size: 15.5px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.3px;
    color: #677375;
    margin-bottom:40px;
}

@media screen and (max-width: 900px) {
    #landingPageWrapper input, #floify-login-page input, #floify-login-page-main input, #floify-reset-password-page input {
        font-size: 16px;
        height: 35px;
    }

    #landingPageWrapper {
        height: auto;
    }
}
@media all and (max-width: 875px) {
    .mainAreaRight{
        width:100%;
    }
    .slideInnerContainer{
        padding:0 24px;
    }
}
