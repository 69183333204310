html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width:100%;
  height:100%;
}
#root{width:100%;height:100%;}

#an2Target {
  height: 100%;
}