@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800");
@import url("https://use.typekit.net/hkm8ixt.css");

:root {
  /* Neutral Colors */
  --color-neutral-1: #fdfdfc;
  --color-neutral-2: #f9f9f8;
  --color-neutral-3: #f1f0ef;
  --color-neutral-4: #e9e8e6;
  --color-neutral-5: #e2e1de;
  --color-neutral-6: #dad9d6;
  --color-neutral-7: #cfceca;
  --color-neutral-8: #bcbbb5;
  --color-neutral-9: #8d8d86;
  --color-neutral-10: #82827c;
  --color-neutral-11: #63635e;
  --color-neutral-12: #21201c;

  /* Blue Colors */
  --color-blue-1: #fbfdff;
  --color-blue-2: #f4faff;
  --color-blue-3: #e6f4fe;
  --color-blue-4: #d5efff;
  --color-blue-5: #c2e5ff;
  --color-blue-6: #acd8fc;
  --color-blue-7: #8ec8f6;
  --color-blue-8: #5eb1ef;
  --color-blue-9: #0090ff;
  --color-blue-10: #0588f0;
  --color-blue-11: #0d74ce;
  --color-blue-12: #113264;

  /* Red Colors */
  --color-red-1: #fffcfc;
  --color-red-2: #fff7f7;
  --color-red-3: #feebec;
  --color-red-4: #ffdbdc;
  --color-red-5: #ffcdce;
  --color-red-6: #fdbdbe;
  --color-red-7: #f4a9aa;
  --color-red-8: #eb8e90;
  --color-red-9: #e5484d;
  --color-red-10: #dc3e42;
  --color-red-11: #ce2c31;
  --color-red-12: #641723;

  /* Orange Colors */
  --color-orange-1: #fefcfb;
  --color-orange-2: #fff7ed;
  --color-orange-3: #ffefd6;
  --color-orange-4: #ffdfb5;
  --color-orange-5: #ffd19a;
  --color-orange-6: #ffc182;
  --color-orange-7: #f5ae73;
  --color-orange-8: #ec9455;
  --color-orange-9: #f76b15;
  --color-orange-10: #ef5f00;
  --color-orange-11: #cc4e00;
  --color-orange-12: #582d1d;

  /* Green Colors */
  --color-green-1: #fbfefc;
  --color-green-2: #f4fbf6;
  --color-green-3: #e6f6eb;
  --color-green-4: #d6f1df;
  --color-green-5: #c4e8d1;
  --color-green-6: #adddc0;
  --color-green-7: #8eceaa;
  --color-green-8: #5bb98b;
  --color-green-9: #30a46c;
  --color-green-10: #2b9a66;
  --color-green-11: #218358;
  --color-green-12: #193b2d;
}

.clearfix:after,
.div-table .div-table-head:after,
.div-table .div-table-row:after,
.div-table .div-table-row-wrap:after {
  content: "";
  display: table;
  clear: both;
}

.milestone-btn .milestone-icon-wrapper,
.milestone-btn,
#tab-highlight-wrapper,
.form-group .question-icon,
.form-group #modalDesktopNotifsAnchor,
.form-group,
.text-center .pending-review-wrapper .bell-icon,
.text-center .pending-review-wrapper,
.right-nav,
.left-nav,
.nav-links-wrapper,
.navbar-brand img,
.navbar-header,
#nav-wrapper {
  position: relative;
  display: block;
}

.loan-dashboard-search-box .dataTables_filter label:before,
#tab-highlight,
.form-group #loanFlowFilter-label,
.mobile-nav-toggle {
  position: absolute;
  display: block;
}

.cloneModal #team-results-wrap .team-item .checkbox,
.cloneModal #team-selected-wrap .team-item .checkbox,
.tree-modal #team-results-wrap .team-item .checkbox,
.tree-modal #team-selected-wrap .team-item .checkbox,
.borrower-contact-info-wrapper,
.loan-flow-status-label,
.loan-dashboard-search-box .dataTables_filter label:before,
.new-loan-wrapper #arrowBouncing,
.mobile-nav-toggle,
.right-nav,
.left-nav,
.navbar-brand img,
.search-icon:before {
  top: 50%;
  transform: translateY(-50%);
}

.text-center .pending-review-wrapper,
.pageBlocker #pageBlockerImgWrap {
  left: 50%;
  transform: translateX(-50%);
}

.cloneModal #team-search-wrap #team-select-all .checkbox.full:before,
.tree-modal #team-search-wrap #team-select-all .checkbox.full:before,
.cloneModal #team-search-wrap #team-select-all .checkbox.partial:before,
.tree-modal #team-search-wrap #team-select-all .checkbox.partial:before,
.cloneModal .cloneTree .fancytree-selected span.fancytree-checkbox:before,
.cloneModal .tree-view .fancytree-selected span.fancytree-checkbox:before,
.tree-modal .cloneTree .fancytree-selected span.fancytree-checkbox:before,
.tree-modal .tree-view .fancytree-selected span.fancytree-checkbox:before,
.cloneModal .cloneTree .fancytree-partsel span.fancytree-checkbox:before,
.cloneModal .tree-view .fancytree-partsel span.fancytree-checkbox:before,
.tree-modal .cloneTree .fancytree-partsel span.fancytree-checkbox:before,
.tree-modal .tree-view .fancytree-partsel span.fancytree-checkbox:before,
.cloneModal #team-results-wrap .team-item .full.checkbox:before,
.cloneModal #team-selected-wrap .team-item .full.checkbox:before,
.tree-modal #team-results-wrap .team-item .full.checkbox:before,
.tree-modal #team-selected-wrap .team-item .full.checkbox:before,
.cloneModal #team-results-wrap .team-item .partial.checkbox:before,
.cloneModal #team-selected-wrap .team-item .partial.checkbox:before,
.tree-modal #team-results-wrap .team-item .partial.checkbox:before,
.tree-modal #team-selected-wrap .team-item .partial.checkbox:before {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-backdropFilter {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdropFilter.in {
  opacity: 1 !important;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes tabsHide {
  0% {
    display: block !important;
    left: 0;
    opacity: 1;
    position: relative !important;
  }
  99% {
    display: block !important;
    left: 0;
    opacity: 0;
    position: relative !important;
  }
  100% {
    display: block !important;
    left: -99999px;
    opacity: 0;
    position: absolute !important;
  }
}
@keyframes tabsShow {
  0% {
    display: block !important;
    left: -99999px;
    opacity: 0;
    position: absolute !important;
  }
  1% {
    display: block !important;
    left: 0px;
    opacity: 0;
    position: relative !important;
  }
  100% {
    display: block !important;
    left: 0px;
    opacity: 1;
    position: relative !important;
  }
}
.fa-pencil-square-o:before {
  content: "";
}

.fa-trash-o:before {
  content: "";
  font-weight: 900 !important;
}

.fa-envelope-o:before {
  content: "";
}

.fa-list-ul {
  font-weight: 900 !important;
}
.fa-list-ul:before {
  content: "" !important;
}

.fa-sticky-note {
  font-weight: 900;
}

.fa-envelope {
  font-weight: 900;
}

.fa-check-square-o {
  font-weight: 500;
}
.fa-check-square-o:before {
  content: "";
}

.fa-arrow-circle-o-up:before {
  content: "";
  font-weight: 900 !important;
}

.fa-arrow-circle-o-down:before {
  font-weight: 900;
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-dropbox {
  font-family: "Font Awesome 5 Brands";
}
.fa-dropbox:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-google {
  font-family: "Font Awesome 5 Brands";
}
.fa-google:before {
  content: "";
}

.fa-slack {
  font-family: "Font Awesome 5 Brands";
}
.fa-slack:before {
  content: "";
}

.fa-building-o:before {
  font-weight: 500;
  content: "";
}

.fa-share-alt:before,
.fa-share:before {
  font-weight: 900;
  content: "";
}

.fa-file-text:before {
  font-weight: 900;
  content: "";
}

.fa-commenting-o:before {
  font-weight: 500;
  content: "";
}

.fa-hourglass-2:before {
  content: "";
}

.modalStepper {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 1;
  transition: left 0.5s ease 0s, right 0.5s ease 0s, opacity 0.3s ease 0s;
}
.modalStepper.hideRight {
  right: -999px;
  left: 999px;
  opacity: 0;
  transition: left 0.5s ease 0s, right 0.5s ease 0s, opacity 0.3s ease 0s;
}
.modalStepper.hideLeft {
  left: -999px;
  right: 999px;
  opacity: 0;
  transition: left 0.5s ease 0s, right 0.5s ease 0s, opacity 0.3s ease 0s;
}

.flo-modal-btn {
  opacity: 1;
  z-index: 1;
  transition: opacity 0.3s ease 0.2s, max-width 0.3s ease 0s,
    padding 0.3s ease 0s, margin 0.3s ease 0s;
}
.flo-modal-btn.flo-hide {
  opacity: 0;
  z-index: -999;
  transition: opacity 0.3s ease 0s, max-width 0.3s ease 0.3s,
    padding 0.3s ease 0.3s, margin 0.3s ease 0.3s;
}

/* FLO-6730. As part of this ticket, product asked that we make this global */
input[type="radio"] {
  cursor: pointer;
}

.cloneModal #team-results-wrap .team-item .checkbox,
.cloneModal #team-selected-wrap .team-item .checkbox,
.tree-modal #team-results-wrap .team-item .checkbox,
.tree-modal #team-selected-wrap .team-item .checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.cloneModal #team-results-wrap .team-item .partial.checkbox,
.cloneModal #team-selected-wrap .team-item .partial.checkbox,
.tree-modal #team-results-wrap .team-item .partial.checkbox,
.tree-modal #team-selected-wrap .team-item .partial.checkbox {
  background: #337ab7;
  border-color: #337ab7;
}
.cloneModal #team-results-wrap .team-item .partial.checkbox:before,
.cloneModal #team-selected-wrap .team-item .partial.checkbox:before,
.tree-modal #team-results-wrap .team-item .partial.checkbox:before,
.tree-modal #team-selected-wrap .team-item .partial.checkbox:before {
  top: 7px;
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  color: white;
  font-size: 9px;
  position: absolute;
}
.cloneModal #team-results-wrap .team-item .full.checkbox,
.cloneModal #team-selected-wrap .team-item .full.checkbox,
.tree-modal #team-results-wrap .team-item .full.checkbox,
.tree-modal #team-selected-wrap .team-item .full.checkbox {
  background: #337ab7;
  border-color: #337ab7;
}
.cloneModal #team-results-wrap .team-item .full.checkbox:before,
.cloneModal #team-selected-wrap .team-item .full.checkbox:before,
.tree-modal #team-results-wrap .team-item .full.checkbox:before,
.tree-modal #team-selected-wrap .team-item .full.checkbox:before {
  top: 7px;
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  color: white;
  font-size: 9px;
  position: absolute;
}

input[type="text"]::-ms-clear {
  color: #d5413e;
}

input[type="password"]::-ms-clear,
input[type="password"]::-ms-reveal {
  color: #d5413e;
}

input[type="checkbox"]:not(:disabled):hover {
  cursor: pointer;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body {
  padding-bottom: 20px;
  overflow-x: hidden;
}
.mobile-footer-content a {
  color: #3278b3;
}
.tooltip {
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
  word-break: break-word;
}

div.tooltip-inner {
  max-width: 350px;
}

div.tooltip-inner {
  max-width: 350px;
}

.label {
  padding: 4px;
}

.counts-badge {
  display: inline-flex;
  justify-content: center;
  border-radius: 4px;
  padding: 0 5px;
  width: 28px;
}

.counts-badge a {
  text-decoration: none;
}

.counts-badge-owed {
  color: #7a271a;
  background: #f7c1b5;
}

.counts-badge-pending {
  color: #93370d;
  background: #f9e1a9;
}

.counts-badge-accepted {
  color: #05603a;
  background: #c4ecbb;
}

.counts-badge-conditions {
  color: #555555;
  background: #dadce3;
}

.label-danger,
.badge.badge-danger {
  background-color: #d5413e;
  color: #fff;
}
.label-danger a,
.badge-danger a {
  color: #fff !important;
  font-size: 12px;
  font-weight: 400;
}
.label-danger a:hover,
.badge-danger a:hover {
  color: #fff !important;
}

.label-success,
.badge-success {
  background-color: #3b863b;
  color: #fff;
}
.label-success a,
.badge-success a {
  color: #fff !important;
  font-size: 14px;
  font-weight: 400;
}
.label-success a:hover,
.badge-success a:hover {
  color: #fff !important;
}

.floify-tab-pane {
  display: block !important;
  position: relative;
  opacity: 0;
  height: 0;
  margin-bottom: 0;
  transition: opacity 0.3s ease 0s, height 0s ease 0.3s,
    margin-bottom 0s ease 0.3s;
}

.floify-tab-pane.active {
  display: block !important;
  opacity: 1;
  height: 100%;
  margin-bottom: 15px;
  transition: opacity 0.3s ease 0.35s, height 0s ease 0.3s,
    margin-bottom 0s ease 0.3s;
}

.search-icon {
  position: relative;
}
.search-icon:before {
  position: absolute;
  left: 10px;
  content: "";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  color: #b0b3b6;
  display: inline-block;
}
.search-icon input {
  padding-left: 32px;
  height: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}
.search-icon input:focus {
  outline: none;
}
.search-icon input::placeholder {
  color: #b0b3b6;
}

#alertSuccess,
#alertError {
  display: none;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1160px;
  z-index: 1051; /* NOTE: keep this higher than bootstrap's `.modal` */
}

.text-danger {
  color: #d5413e;
}
.input-border-danger {
  border-color: #d5413e;
}
.badge-warning {
  color: #fff;
  background-color: #f0ad4e;
}

.floify-text-success {
  color: #3b863b;
}
.floify-text-success i {
  color: #3b863b !important;
}

.floify-text-warning {
  color: #f0ad4e;
}
.floify-text-warning i {
  color: #f0ad4e !important;
}

.floify-text-danger {
  color: #d5413e;
}
.floify-text-danger i {
  color: #d5413e !important;
}

.floify-text-disabled {
  color: #dadce3;
}
.floify-text-disabled i {
  color: #dadce3 !important;
}

.text-display-none {
  display: none;
}

.encompass-text-danger {
  color: #d5413e;
}

.encompass-text-warning {
  color: #f0ad4e;
}

.encompass-text-success {
  color: #3b863b;
}

.mobile-menu-overlay {
  z-index: 98;
  position: absolute;
  top: 0;
  width: calc(100%);
  height: calc(100%);
}

.realtor-hide {
  display: none;
}

#pdf-disabled {
  text-align: center;
  padding: 10em 0;
  background: #ededed;
}

#pdf-disabled p {
  font-size: 2em;
}

.disable-row {
  opacity: 0.25;
}
.hide-row {
  display: none;
}

.disable-row input {
  pointer-events: none;
}

@media only screen and (max-width: 991px) {
  .container {
    width: calc(100% - 30px);
  }

  .tooltip {
    display: none !important;
  }

  .page-header h1 a {
    pointer-events: none;
    color: inherit;
  }
}
.btn-default {
  background: #e3e3e3;
}
.btn-default:hover {
  background: lightgrey;
}

.base-btn {
  background-color: #337ab7;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  color: #fff;
  border-radius: 4px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
}
.base-btn i {
  margin-right: 8px;
}
.base-btn:hover {
  background-color: #3b87c8;
  color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
.base-btn:active:focus,
.base-btn:active:hover,
.base-btn:focus {
  background-color: #285f8f;
  color: #fff;
  box-shadow: initial;
  outline: none;
}

.base-btn-grey {
  background-color: #e3e3e3;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  color: #333333;
  border-radius: 4px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
}
.base-btn-grey i {
  margin-right: 8px;
}
.base-btn-grey:hover {
  background-color: #f0f0f0;
  color: #333333;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
.base-btn-grey:active:focus,
.base-btn-grey:active:hover,
.base-btn-grey:focus {
  background-color: #cacaca;
  color: #333333;
  box-shadow: initial;
  outline: none;
}

.btn-warning {
  background-color: #f0ad4e;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  color: #fff;
  border-radius: 4px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
}
.btn-warning i {
  margin-right: 8px;
}
.btn-warning:hover {
  background-color: #f2b866;
  color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
.btn-warning:active:focus,
.btn-warning:active:hover,
.btn-warning:focus {
  background-color: #ec971f;
  color: #fff;
  box-shadow: initial;
  outline: none;
}

.btn-primary-lite {
  color: #004ba1;
  background-color: #c1deff;
  border-color: #c1deff;
}

.btn-primary-lite.focus,
.btn-primary-lite:focus {
  color: #004ba1;
  background-color: #abd0faff;
  border-color: #abd0faff;
}

.btn-primary-lite:hover {
  color: #004ba1;
  background-color: #abd0faff;
  border-color: #abd0faff;
}

.btn-primary-lite.active,
.btn-primary-lite:active,
.open > .dropdown-toggle.btn-primary-lite {
  color: #004ba1;
  background-color: #abd0faff;
  border-color: #abd0faff;
}

.btn-primary-lite.active.focus,
.btn-primary-lite.active:focus,
.btn-primary-lite.active:hover,
.btn-primary-lite:active.focus,
.btn-primary-lite:active:focus,
.btn-primary-lite:active:hover,
.open > .dropdown-toggle.btn-primary-lite.focus,
.open > .dropdown-toggle.btn-primary-lite:focus,
.open > .dropdown-toggle.btn-primary-lite:hover {
  color: #004ba1;
  background-color: #abd0faff;
  border-color: #abd0faff;
}

.btn-primary-lite.active,
.btn-primary-lite:active,
.open > .dropdown-toggle.btn-primary-lite {
  background-image: none;
}

.btn-primary-lite.disabled,
.btn-primary-lite.disabled.active,
.btn-primary-lite.disabled.focus,
.btn-primary-lite.disabled:active,
.btn-primary-lite.disabled:focus,
.btn-primary-lite.disabled:hover,
.btn-primary-lite[disabled],
.btn-primary-lite[disabled].active,
.btn-primary-lite[disabled].focus,
.btn-primary-lite[disabled]:active,
.btn-primary-lite[disabled]:focus,
.btn-primary-lite[disabled]:hover,
fieldset[disabled] .btn-primary-lite,
fieldset[disabled] .btn-primary-lite.active,
fieldset[disabled] .btn-primary-lite.focus,
fieldset[disabled] .btn-primary-lite:active,
fieldset[disabled] .btn-primary-lite:focus,
fieldset[disabled] .btn-primary-lite:hover {
  color: #004ba1;
  background-color: #dbeafe;
  border-color: #dbeafe;
}

.addDocBtn {
  display: flex;
  max-width: 100%;
  display: inline-flex;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.addDocBtnTwo {
  display: flex;
  max-width: 100%;
  display: inline-flex;
}

.addDocBtnLeft {
  overflow: hidden;
  text-overflow: ellipsis;
}

#nav-wrapper {
  height: 100%;
}

.nav > li > a {
  padding: 10px 11px;
}

.nav > li > a:hover,
#cloneConditionsRow {
  cursor: pointer;
}

.navbar-header {
  height: 100%;
  max-width: 275px;
  margin-left: 0;
  margin-right: 0;
}

.navbar-brand {
  width: 100%;
  height: 100%;
  float: left;
  padding: 0;
  margin-left: 0px !important;
}
.navbar-brand img {
  max-height: 100%;
  max-width: 149px;
}

.floify-esign-icon {
  max-width: 24px;
}

.navbar-nothing {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.navbar-nothing:focus,
.navbar-nothing:hover,
.navbar-nothing:visited .navbar-nothing:focus span,
.navbar-nothing:hover span,
.navbar-nothing:visited span .user-team:hover,
.user-team:focus,
.user-team:visited,
.user-team:hover a,
.userteam:focus a,
.userteam:visited a {
  outline: none;
  text-decoration: none;
  border: none;
}
.nav-links-wrapper {
  float: right;
  height: 100%;
}

.navbar-nothing-left {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.nav-pills-style a,
.nav-pills-style a:hover {
  padding-top: 4px !important;
  padding-bottom: 6px !important;
}

.left-nav {
  float: left;
  list-style: none;
  padding: 0 24px 0 0;
  font-size: 13.5px;
}
.left-nav > li {
  float: left;
  margin-left: 20px;
}
.left-nav > li > .dropdown > .dropdown-menu {
  left: -14px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border: 0;
}

.left-nav > li > .dropdown > .dropdown-menu > li > a {
  color: #797676;
  padding: 5px 14px;
  font-size: 13.5px;
}

.left-nav > li:active > a,
.left-nav > li:active > .dropdown > a,
.left-nav > li:focus > a,
.left-nav > li:focus > .dropdown > a {
  text-decoration: none;
}
.left-nav > li > a,
.left-nav > li > .dropdown > a {
  color: #797676;
  underline: none;
  transition: color 0.3s ease, border 0.3s ease;
  border-bottom: 2px solid transparent;
  padding-bottom: 3px;
}
.left-nav > li > a:hover,
.left-nav > li > .dropdown > a:hover {
  color: #337ab7;
  text-decoration: none;
  border-bottom: 2px solid #337ab7;
}
.left-nav > li > a:active,
.left-nav > li > .dropdown > a:active,
.left-nav > li > a:focus,
.left-nav > li > .dropdown > a:focus {
  text-decoration: none;
}
.left-nav > li:first-child {
  margin-left: 0;
}

.right-nav {
  float: left;
  list-style: none;
  padding-left: calc(32px - 25px);
  border-left: 1px solid #797676;
  font-size: 13.5px;
}
.right-nav li {
  float: left;
  margin-left: 15px;
}
.right-nav li a,
.right-nav li i {
  color: #626974;
  transition: color 0.3s ease, border 0.3s ease;
}
.right-nav li a {
  border-bottom: 2px solid transparent;
  padding-bottom: 3px;
}
.right-nav li i {
  margin-right: 8px;
}
.right-nav li:hover i {
  color: #337ab7;
}
.right-nav li:hover a {
  color: #337ab7;
  text-decoration: none;
  /* border-bottom: 2px solid #337ab7; */
}
.right-nav li:hover a:not([href]) {
  color: #626974;
  text-decoration: none;
  /* border-bottom: 2px solid transparent; */
}
.right-nav li:hover a:not([href]) i {
  color: #626974;
}
.right-nav li:nth-child(3) i {
  margin-right: 0;
}
.right-nav .logout-text {
  display: none;
}
.right-nav .user-email,
.right-nav .user-team {
  max-width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-nav {
  height: 91px;
  min-height: 0;
  padding: 0;
  margin: 0;
  background: #fff !important;
  border: none;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  border-radius: 0px;
  transition: height 0.3s ease;
  z-index: 999;
}

.mobile-nav-toggle {
  right: 15px;
  width: 22px;
  display: none;
  cursor: pointer;
}

.mobile-nav-header {
  display: none;
}

#mobile-nav-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  z-index: 10;
  opacity: 0;
  top: -9999px;
  left: -9999px;
  transition: opacity 0.3s ease 0s, top 0s ease 0.3s, left 0s ease 0.3s;
}
#mobile-nav-overlay.visible {
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.3s ease 0.1s, top 0s ease 0s, left 0s ease 0s;
}

@media only screen and (max-width: 1200px) {
  .right-nav-text {
    display: none;
  }
  .navbar-header {
    max-width: 175px;
  }

  .left-nav {
    padding-right: 16px;
  }
  .left-nav > li {
    margin-left: 16px;
  }

  .right-nav {
    padding-left: 4px;
  }
  .right-nav li {
    margin-left: 12px;
  }
  .right-nav li:last-child {
    margin-left: 15px;
  }
  .right-nav .user-email,
  .right-nav .user-team {
    max-width: 125px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .navbar-brand img {
    max-width: 138px;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-nav {
    margin-right: 0;
  }

  .mobile-nav-icon {
    padding: 0 30px 0 0 !important;
    margin-left: 0 !important;
    color: #9e9e9e;
  }

  .mobile-nav-icon i {
    color: #9e9e9e;
    font-size: 30px;
    margin-top: 1px;
  }

  .mobile-navbar-right {
    font-size: 17px;
  }

  .navbar-brand img {
    max-width: 139px;
  }

  li.activity-dropdown-item {
    display: none;
  }
}

#an2-settings {
  margin-top: 20px;
}

.apply-now-preview {
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(216, 216, 216, 0.7);
  border-bottom: 1px solid rgba(216, 216, 216, 0.7);
  background: #f9f9f9;
  margin: 20px 0;
  padding: 0 10px;
}
.apply-now-preview p {
  margin-bottom: 0;
}
.apply-now-preview div:first-of-type {
  display: flex;
  flex-basis: 0;
  flex-grow: 2;
}

.apply-now-preview div:last-of-type {
  display: flex;
  flex-basis: 0;
  flex: 8;
}

.prospect-preview {
  display: block;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.23px;
  color: #636973;
}

.in-progress-message,
.message-dismiss {
  line-height: 1.54;
  letter-spacing: -0.37px;
  color: #6a6a6a;
  font-size: 13px;
}

.message-dismiss {
  font-weight: 500;
  font-style: italic;
  color: #4a4949;
}
.message-dismiss:hover {
  text-decoration: underline;
  cursor: pointer;
}
.an-settings-section {
  margin: 50px 0 25px;
}
#consumerConnectContainer {
  height: calc(100vh - 369px);
  overflow: scroll;
}

@media only screen and (max-width: 991px) {
  .custom-nav {
    height: 53px;
    border-bottom: 5px solid #f3f4f5;
    box-shadow: none;
  }

  #nav-wrapper {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  .mobile-nav-toggle {
    display: block;
  }

  .container > .navbar-header {
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-brand img {
    margin: auto;
  }

  .nav-links-wrapper {
    right: -280px;
    width: 280px;
    height: 100%;
    position: fixed;
    background: #fff;
    top: 0;
    z-index: 999;
    overflow-y: scroll;
    transition: right 0.3s ease 0s;
  }
  .nav-links-wrapper.visible {
    right: 0;
  }
  .nav-links-wrapper .mobile-nav-header {
    display: block;
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
  }
  .nav-links-wrapper .mobile-nav-header .close-mobile-nav {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .nav-links-wrapper .mobile-nav-header .mobile-navbar-brand {
    position: relative;
    display: block;
    margin-top: 35px;
    max-height: 50px;
    width: 100%;
  }
  .nav-links-wrapper .mobile-nav-header .mobile-navbar-brand img {
    display: block;
    margin: auto;
  }
  .nav-links-wrapper .left-nav {
    padding: 0 20px;
    margin: 0;
    top: initial;
    transform: initial;
    width: 100%;
  }
  .nav-links-wrapper .left-nav > li {
    clear: both;
    margin-left: 0;
    border-bottom: 1px solid #d8d8d8;
    width: 100%;
    padding: 15px 0;
  }
  .nav-links-wrapper .left-nav > li > a,
  .nav-links-wrapper .left-nav > li > .dropdown > a {
    color: #121727;
    font-size: 17px;
  }
  .nav-links-wrapper .left-nav > li:hover > a,
  .nav-links-wrapper .left-nav > li:hover > .dropdown > a {
    color: #337ab7;
  }
  .nav-links-wrapper .right-nav {
    top: initial;
    transform: initial;
    padding: 20px;
    width: 100%;
    border-left: 1px solid transparent;
  }
  .nav-links-wrapper .right-nav .user-email,
  .nav-links-wrapper .right-nav .user-team {
    max-width: 100%;
  }
  .nav-links-wrapper .right-nav li {
    width: 100%;
    margin: 0 0 15px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .nav-links-wrapper .right-nav li a {
    color: #121727;
  }
  .nav-links-wrapper .right-nav li i {
    color: #797676;
    width: 15px;
    margin-right: 8px;
  }
  .nav-links-wrapper .right-nav .logout-text {
    display: inline;
  }
  #consumerConnectContainer {
    height: auto;
    overflow: auto;
  }
}
@media only screen and (max-width: 768px) {
  .container > .navbar-header {
    margin: 0;
  }

  .floify-esign-add-doc {
    display: none;
  }
  .floify-esign-mobile-message {
    display: block !important;
    text-align: center;
    margin-bottom: 20px;
  }
}
.page-header {
  margin: 0 0 30px;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 16px;
  position: relative;
}
.page-header.tight {
  border-bottom: none;
  padding: 0;
  margin: 0;
}
.page-header.tight h1 {
  margin-bottom: 0;
}
.page-header h1 {
  font-size: 24px;
  font-family: "Nunito Sans", sans-serif;
  margin-top: 30px;
  margin-bottom: 12px;
  color: #121727;
  font-weight: 600;
  word-break: break-word;
}
.page-header p {
  color: #626974;
}

.enforce-time-based,
.time-based-select {
  padding-left: 20px;
  margin-bottom: 16px;
}

#applyNowRestrictionsEnforcedDays {
  width: 200px;
  height: 34px;
}

#applyNowRestrictionsEnforcedDays:disabled {
  opacity: 0.46;
}

#timeBasedDays {
  height: 30px;
}

@media only screen and (max-width: 900px) {
  .page-header {
    border: none;
    padding-bottom: 0;
    margin-bottom: 12px;
    float: initial;
    width: 100%;
  }
  #floify-mismatch-error,
  #floify-mismatch-error-top {
    float: left;
  }

  .page-header h1 {
    margin-top: 15px;
    margin-bottom: 0;
    word-break: break-word;
  }
  .page-header p {
    display: none;
  }
}
/* label-important color: #b94a48 */
/* label-warning color: #f89406 */
/* Space out content a bit */
.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* workflow */
.you-can-edit-this {
  cursor: pointer;
}

/* follower packs */
.follower-packs-you-can-edit-this {
  cursor: pointer;
}

/* remove some vertical whitespace */
/* btn-default */
.btn-danger {
  background-color: #d4423e;
  border-color: #d4423e;
}

#my1003ViewModal a {
  color: #3174af;
}

#my1003ViewModal .nav-stacked .active a {
  color: #fff;
}

#borrower_loans_table_wrapper .label-warning,
#fileWells .label-warning,
#milestone-wrap .label-warning,
#myNAModal .label-warning,
#add-doc-completed-form .label-warning {
  background: #f0ad4e;
  color: #4a4a4a;
}

.label-danger {
  background-color: #d5413e;
}

#milestone-wrap .lender-name-label.label-default {
  background-color: #777772;
}

.btn-outline-primary {
  color: #286090;
  background-color: transparent;
  background-image: none;
  border-color: #286090;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #286090;
}

/* create some space on the left when a Cancel button comes last in a line of buttons */
.btn-leftmargin {
  margin-left: 6px;
}

/* create some space below when narrowing the browser window causes content to stack beneath the last button */
.btn-bottommargin {
  margin-bottom: 12px;
}

.btn-topmargin {
  margin-top: 6px;
}

.blur-me {
  filter: blur(3px);
}

/* modal header: make it not so tall */
.modal-header {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.modal-header .btn-close {
  margin-top: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

/* modal footer: give it a background colour and put the rounded corners back on */
.modal-footer {
  padding-top: 16px;
  padding-bottom: 16px;
  /*margin-top: 6px;
    margin-bottom: 6px;*/
  background-color: #f5f5f5 !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* badge colours, which were removed in Bootstrap 3 */
.badge-grey {
  color: #dadce3;
}

.badge-doccount {
  background: #fff !important;
  border: 1px solid #dadce3;
  margin-bottom: 1px;
  color: #555;
}

.badge-currentlyloggedin {
  color: #92de92 !important;
}

.modal-header .close {
  opacity: 0.5;
}

.pre-wordwrap {
  white-space: pre-wrap;
  /* CSS3 browsers  */
  white-space: -moz-pre-wrap !important;
  /* 1999+ Mozilla  */
  white-space: -pre-wrap;
  /* Opera 4 thru 6 */
  white-space: -o-pre-wrap;
  /* Opera 7 and up */
  word-wrap: break-word;
}

/* START OF OLD CSS */
/* Top of homepage headlines */
.headlines {
  padding-top: 20px;
  background-color: #e5e5e5;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

/* Further explanation, directly beneath homepage headlines */
.further-explanation {
  padding-top: 20px;
  background-color: #f5f1de;
  /*background-color: #f4f4f4;*/
  border-radius: 8px;
  margin-bottom: 40px;
}

.further-explanation-margins {
  margin-left: 6px;
  margin-right: 6px;
  /*padding-left: 6px;
    padding-right: 6px;*/
}

/* how it works */
.howitworks {
  padding-top: 20px;
  /*background-color: #F5F1DE;*/
  background-color: #f4f4f4;
  border-radius: 8px;
  margin-bottom: 40px;
}

/* pricing */
.pricing {
  padding-top: 20px;
  background-color: #f5f1de;
  border-radius: 8px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.pricing-well {
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 8px;
}

/* FAQ */
.faq {
  padding-top: 20px;
  background-color: #e5e5e5;
  border-radius: 8px;
  margin-bottom: 40px;
}

/* customers */
#clients {
  padding-top: 30px;
  background-color: #e5e5e5;
  /*background-color: #f4f4f4;*/
  border-radius: 8px;
}

.sorticon-left th {
  padding-left: 18px !important;
}

/* yellow sticky notes */
.yellow-sticky {
  background-color: #fdfdc8;
}

.deletethisstickynow,
.deletethisstickynow a:link,
.deletethisstickynow a:visited,
.deletethisstickynow a:hover,
.deletethisstickynow a:active {
  text-decoration: none !important;
  color: grey !important;
}

/* sometimes we don't display an underline with a link */
a.no-link-underline:hover,
button.no-link-underline:hover {
  text-decoration: none;
}

.next-milestone,
a.next-milestone,
a.next-milestone:hover {
  color: #d5413e;
  text-decoration: none;
}

.future-milestones {
  color: #333;
  text-decoration: none;
}

.edit-deadline,
a.edit-deadline,
a.edit-deadline:hover {
  color: #fff;
  text-decoration: none;
}

.rdonly:hover {
  cursor: pointer;
}

#zillowbranding a:hover {
  text-decoration: none;
}

/* special treatment for forms with icons */
.form-no-block {
  display: inline;
  margin: 0;
  padding: 0;
}

.floify-button-icon {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  margin: 0;
  line-height: 0;
  border-width: 2px;
  border-style: outset;
}

.forms-control {
  display: none;
}

/* END OF OLD CSS */
.font-weight-400 {
  font-weight: 400;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.margin-top-0 {
  margin-top: 0;
}
.margin-top-4 {
  margin-top: 4px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-16 {
  margin-top: 16px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-25 {
  margin-top: 25px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.padding-left-20 {
  padding-left: 20px;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}

.padding-right-25 {
  padding-right: 25px !important;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;
}

/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 9px;
  /* was 19px */
}

/* Custom page footer */
.action-link {
  color: #337ab7;
  cursor: pointer;
}

.action-link:hover {
  text-decoration: underline;
}

.action-link.red {
  color: #ee3b51;
}

.invite-borrowers p {
  font-size: 14px;
  font-weight: 400;
}

.invite-borrowers .contact-details {
  font-weight: 500;
}

.reorder-link i {
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  text-align: right;
  color: white;
  background: #337ab7;
  font-size: 20px;
  float: right;
  padding: 4px;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 10px;
}

.is-reordering i {
  font-size: 12px;
  padding: 3px 10px;
}

.disabled {
  background-color: #eee;
  opacity: 1;
  cursor: not-allowed;
}

/*custom questions*/
.section-disabled .custom-question-show-dropdown {
  display: none;
}

.table.table-striped tbody tr.custom-question-row td {
  vertical-align: top;
}

.table.table-striped tbody tr.custom-question-row td input {
  margin-top: 10px;
}

.custom-questions-edit-delete {
  margin-top: 8px;
}

.custom-question-row td:first-of-type {
  padding-bottom: 50px;
}

.custom-question-row td,
.custom-question-row-display td,
.custom-question-row td .form-char-limit {
  position: relative;
}

.custom-question-row input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ccc;
}

.custom-question-row .custom-question-items-list input[type="text"] {
  width: calc(100% - 70px);
  margin-left: 30px;
}

.custom-question-type {
  margin-bottom: 5px;
}

.custom-question-row select {
  background: white;
  border: 1px solid #ccc;
  font-size: 15px;
}

.custom-question-actions {
  margin: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.input-error {
  border: 1px solid #ee3b51 !important;
}

.hide {
  display: none !important;
}

.error {
  color: #a94442;
  margin-bottom: 10px;
}

.success-message {
  color: #4cae4c;
}

.custom-question-error {
  color: #ee3b51;
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: 12px;
}

.custom-question-items-list ol {
  padding-left: 60px;
  padding: 0;
}

.custom-question-items-list li {
  display: flex;
  position: relative;
  list-style-type: none;
  margin: 15px 0px;
}

.custom-question-dragger {
  position: absolute;
  top: 8px;
  font-size: 16px;
  cursor: move;
  color: #337ab7;
}

.reorder-cq-item {
  padding-left: 40px !important;
}

.custom-question-dragger-full {
  display: none;
}

.reorder-live .custom-question-dragger-full {
  display: block;
  position: relative;
  top: auto;
  left: auto;
  padding: 0 13px 0 10px;
  float: right;
}

.custom-question-show-dropdown {
  float: right;
  cursor: pointer;
  font-size: 20px;
  line-height: 1em;
  padding: 0 13px 0 10px;
  position: relative;
  z-index: 1;
}

.reorder-live .custom-question-show-dropdown {
  display: none;
}

.custom-question-dropdown {
  border-radius: 5px;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  top: 36px;
  right: 9px;
  background: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  z-index: 9;
}

.custom-question-dropdown:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 13px;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: white transparent;
}

.drop-down-option {
  padding: 10px 20px;
  cursor: pointer;
}

.drop-down-option:hover {
  background: #f3f4f5;
}

#consentBtnId {
  margin-top: 20px;
}

.question-dropdown-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 8;
}

.conditional-question-row {
  margin-bottom: 10px;
}

.custom-question-multiple .conditional-question-row-cap {
  padding: 0;
}

.custom-question-multiple {
  margin-bottom: 5px;
}

.conditional-question-text {
  padding-right: 15px;
}

.conditonal-question-choose {
  margin-right: 15px;
}

.conditional-question-input {
  border: 1px solid #d8d8d8;
  background: #f5f5f5;
  padding: 6px;
  width: 60px;
  border-radius: 3px;
  margin-right: 15px;
}

.custom-question-action {
  color: #507aae;
  font-size: 17px;
  margin-right: 15px;
  cursor: pointer;
}

.custom-question-action-add {
  color: white;
  background: #507aae;
  font-size: 11px;
  border-radius: 100%;
  padding: 3px 3px 3px 4px;
  line-height: 1em;
}

.conditional-question-row-cap {
  float: left;
}

.conditional-question-row-end {
  padding-top: 7px;
}

.custom-question-multiple .conditional-question-row-front {
  opacity: 0;
}

.conditional-question-actions {
  float: right;
}

div.view-doc-button {
  display: inline-block;
}

.view-doc-button {
  margin-bottom: 5px;
  color: #337ab7;
  background: none;
  padding: 0;
  border: none;
}

.custom-question-row-conditional td:first-of-type,
.custom-question-row td:first-of-type {
  padding-left: 30px;
}

.custom-question-view-item {
  padding: 8px 0;
  display: block;
}

.custom-question-view-required,
.custom-question-view-dropdown {
  display: inline;
}

.custom-question-view-label-child {
  display: block;
  padding: 8px 0 8px 20px;
}

.custom-question-view-label-child b {
  font-weight: normal;
  text-transform: capitalize;
}

.custom-question-view-edit-required {
  padding: 8px 0;
}

.customQuestionAllow {
  margin: 0;
}

.custom-question-view-children {
  padding-top: 8px;
}

/* Customize container */
/*@media (min-width: 768px) {
    .container {
      max-width: 730px;
    }
  }*/
.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}

.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
}

.marketing p + h4 {
  margin-top: 28px;
}

.input-group-addon.disabled {
  pointer-events: none;
}

/* AN classic datepicker fix */
.datepicker-dropdown .datepicker-days table tr th {
  text-align: center;
}
.datepicker-dropdown .datepicker-months table tr th,
.datepicker-dropdown .datepicker-years table tr th {
  text-align: center;
}
.datepicker-dropdown .datepicker-months table tr td span,
.datepicker-dropdown .datepicker-years table tr td span {
  display: block;
  width: 23%;
  height: 30px;
  float: left;
  margin: 1%;
  cursor: pointer;
  text-align: center;
}
.datepicker-dropdown .datepicker-months table tr td span:hover,
.datepicker-dropdown .datepicker-years table tr td span:hover {
  background: rgba(0, 0, 0, 0.1);
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}
/* For Floify internal use. */
#headlineErrorText {
  color: #d5413e;
}

.form-grouping.company-settings-grouping {
  flex: 1 1 auto;
}

.form-grouping fieldset {
  border: 1px solid #666;
  border-radius: 8px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  padding: 5px 14px 5px 14px;
}

.form-grouping fieldset.no-border {
  border: none;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

.form-grouping fieldset.no-border:after {
  content: "";
  display: block;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 34px;
  clear: both;
}

.form-grouping fieldset.no-border legend {
  margin-left: 0;
  padding-left: 0;
}

.form-grouping fieldset a {
  margin-bottom: 8px;
}

.form-grouping legend {
  width: auto;
  font-weight: bold;
  border-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  margin: 20px;
}

.floify-form-required {
  color: #d5413e !important;
}

#languages-container, #quick-assist-radios-container {
  padding-left: 20px;
}

.language-title {
  color: #787676;
  font-weight: bold;
}

.languages-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.language-cell {
  margin-right: 20px;
  margin-bottom: 15px;
  width: 185px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  display: flex;
  align-items: center;
}

.language-cell {
  padding-left: 16px;
}

.language-cell p {
  margin-bottom: 0;
  /*padding-left: 10px*/
}
.subtext {
  font-size: 13px;
  color: #757575;
  margin-top: 0.4em;
}

/* form required inputs are highlighted */
.form-grouping .form-required {
  color: #f0ad4e !important;
}

.form-grouping-readonly .form-required {
  color: #f0ad4e !important;
}

/* Loan Pipeline */
/*fixes word break*/
span#contact-info {
  word-break: break-all;
}

.badge-align-top {
  vertical-align: top;
  margin-top: 1px;
}

.sweet-alert.showSweetAlert h2 {
  position: relative;
}

.required-field {
  color: #f06f4e;
  font-size: 14px;
  vertical-align: top;
  font-weight: 700;
}

.state-specific-form .required-field {
  margin-right: 3px;
}

/* For Floify users to override with their own styles. */
.form-display-aggregate fieldset {
  border: 1px solid #666;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 5px 14px 5px 14px;
}

.form-display-aggregate legend {
  width: auto;
  font-weight: bold;
  border-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  margin: 20px;
}

/* form required inputs are highlighted */
.form-display-aggregate .form-required {
  color: #f0ad4e !important;
}

.form-display-aggregate-readonly .form-required {
  color: #f0ad4e !important;
}

.fa-140 {
  font-size: 140%;
}

.fa-130 {
  font-size: 130%;
}

.fa-120 {
  font-size: 120%;
}

.fa-110 {
  font-size: 110%;
}

.fa-90 {
  font-size: 90%;
}

.fa-80 {
  font-size: 80%;
}

.padding-right-5 {
  padding-right: 5px;
}

.fa,
.fas {
  font-weight: 900 !important;
}

.user-count {
  font-weight: 700;
  color: #bbb;
  letter-spacing: 1px;
  margin-left: 8px;
}

.onboarding-user-count {
  font-size: 11px;
  font-weight: 700;
  color: #777;
  letter-spacing: 1px;
}

.onboarding-well {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 110%;
}

.onboarding-well-done {
  background-color: #dff0d8;
}

.onboarding-well-next {
  background-color: #fcf8e3;
}

.onboarding-milemarker {
  font-size: 150%;
}

/*
  .onboarding-well .col-lg-2 {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
  }
  */
#template-doc-tabs a {
  margin-bottom: 0;
}

#start-realtor-tabs a {
  margin-bottom: 0;
}

#startrealtors_table_filter label {
  margin-bottom: 0;
}

#startrealtors_table_filter label input {
  margin-left: 0.5em;
}

#startrealtors_table {
  margin-top: 0 !important;
}
.fr-popup.fr-active {
  z-index: 2000 !important;
}
.pac-container {
  z-index: 1051 !important;
}

.fadeout {
  position: fixed;
  width: 60%;
  left: 20%;
  opacity: 0.95;
  z-index: 7000;
}

#modalSwitchTeams {
  z-index: 7001;
}
#modalSwitchTeams .modal-dialog .modal-content .modal-header
 {
  /* width: 650px; */
  padding: 0 15px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
#modalSwitchTeams .modal-dialog .modal-content .modal-header::before {
  content: none;
}
#modalSwitchTeams .modal-dialog .modal-content .modal-header::after {
  content: none;
}
#modalSwitchTeams .modal-dialog .modal-content .modal-header button.close,
#modalSwitchTeams .modal-dialog .modal-content .modal-header .close {
  /* close modal X */
    padding: 0;
    margin: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    /* margin-top: 9px; */
}
#modalSwitchTeams .modal-dialog .modal-content .modal-body {
  /* modal body */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
#modalSwitchTeams .modal-dialog .modal-content .modal-footer button {
  /* close button */
  font-weight: normal;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}


#modalSwitchTeams .modal-dialog {
  width: 650px;
}
#modalSwitchTeams #main-available-accounts .main-account-switchee {
  padding-left: 0px;
}
#modalSwitchTeams #main-available-accounts .switchTeamsRadioWrap {
  padding-left: 27px;
}
#modalSwitchTeams p.main-account-switchee {
  margin: 0;
}
#modalSwitchTeams p.main-account-switchee i {
  opacity: 0.6;
  margin-left: 15px;
}
/* hack to fix datepicker in bootstrap 3.1.1 */
.datepicker.dropdown-menu {
  z-index: 10000 !important;
}

.dropdown-menu.arrow-up:after,
.dropdown-menu.arrow-up:before {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  top: -8px;
  content: "";
  left: 90%;
  margin-left: -12px;
  position: absolute;
}

/* Styling for second triangle (border) */

.dropdown-menu.arrow-up:before {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid;
  border-bottom-color: inherit; /* Can't be included in the shorthand to work */
  top: -9px;
  margin-left: -13px;
}

/* getbootstrap.com's side notes for calling out things */
/* Base styles (regardless of theme) */
.bs-callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}

.bs-callout h4 {
  margin-top: 0;
}

.bs-callout p:last-child {
  margin-bottom: 0;
}

.bs-callout code,
.bs-callout .highlight {
  background-color: #fff;
}

/* Themes for different contexts */
.bs-callout-danger {
  background-color: #fcf2f2;
  border-color: #dfb5b4;
}

.bs-callout-warning {
  background-color: #fefbed;
  border-color: #f1e7bc;
}

.bs-callout-info {
  background-color: #f0f7fd;
  border-color: #d0e3f0;
}

/* esignatures */
.floify-esign-mobile-message {
  display: none;
  color: #666;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
}
.esigtag {
  cursor: move;
  color: grey;
  background: #fdfdc8;
  /*width:150px;*/
  /*height:30px;*/
  font-size: 14px;
}

.esig-prepare-page-for-display {
  border: 1px solid lightgrey;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

/* pricing */
#paymentOptions .selectedWell {
  background-color: #d8d8d8;
}

#paymentOptions .users {
  background-color: #f5f5f5;
}

/**
   * Datatables Sorting icons on left: http://stackoverflow.com/a/32217914/794988
   */
table.dataTable thead > tr > th {
  padding-left: 30px !important;
  padding-right: initial !important;
}

table.dataTable#startrealtors_table thead > tr > th,
table.dataTable#companies_table thead > tr > th {
  padding-left: 8px !important;
  padding-right: initial !important;
}

table.dataTable#companies_table tr td {
  min-width: 120px;
}

table.dataTable#regional-loan-officers-table thead > tr > th {
  padding-left: 8px !important;
  padding-right: initial !important;
}

table.dataTable#available-users-table thead > tr > th {
  padding-left: 8px !important;
  padding-right: initial !important;
}

table.dataTable#regions-table thead > tr > th {
  padding-left: 8px !important;
  padding-right: initial !important;
}

table.dataTable#disclosure-packages-table thead > tr > th {
  padding-left: 30px !important;
  padding-right: initial !important;
}

table.dataTable#loan_audit_trail_table thead > tr > th.sorting_disabled {
  padding-left: 8px !important;
  padding-right: initial !important;
}

table.dataTable#disclosure-packages-table thead > tr > th:last-child {
  padding-left: 8px !important;
}

table.dataTable#disclosure-packages-table thead > tr > th:nth-last-child(2) {
  padding-left: 30px !important;
}

table.dataTable#sent-disclosure-packages-table thead > tr > th:last-child {
  padding-left: 8px !important;
}

table.dataTable#sent-disclosure-packages-table
  thead
  > tr
  > th:nth-last-child(2) {
  padding-left: 30px !important;
}

table.dataTable#sent-disclosure-packages-table thead > tr > th {
  padding-left: 30px !important;
  padding-right: initial !important;
}

table.dataTable#company-disclosure-packages-table thead > tr > th:last-child {
  padding-left: 30px !important;
}

table.dataTable thead > tr > th:last-child {
  padding-left: 12px !important;
  padding-right: 0 !important;
}

table.dataTable thead > tr > th.sorting_disabled {
  padding-left: 8px !important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after {
  left: 8px !important;
  right: auto !important;
}

table.dataTable thead > tr > th.floifyDataTableIndent {
  padding-left: 30px !important;
}

table.dataTable thead > tr > th.floifyDataTableNoIndent {
  padding-left: 12px !important;
}
.company-loans table:not(#docs_awaiting_signature_table).dataTable tr,
.team-loans table:not(#docs_awaiting_signature_table).dataTable tr {
  cursor: pointer;
}

/* credit card */
.pricing-plan-name {
  display: inline-block;
  line-height: 120%;
}

.pricing-plan-tagline {
  display: block;
  line-height: 140%;
}

.pricing-plan-well {
  margin-top: 8px;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 4px;
}

.pricing-plan-zero-top-bottom {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.table#disclosure-packages-table thead > tr > th.sorting_disabled {
  padding-left: 8px !important;
}

table#sent-disclosure-packages-table thead > tr > th.sorting_disabled {
  padding-left: 8px !important;
}

table#company-disclosure-packages-table thead > tr > th.sorting_disabled {
  padding-left: 8px !important;
}

/*
  #pricing-plan-div .well {
    margin-left: -12px;
    margin-right: -12px;
  }
  */
.notEncompassReadyHighlight {
  box-shadow: 0 0 3pt 2pt #d5413e;
}

.thumbnail {
  background: #f5f5f5;
}

.doc-row:hover .rename-button {
  display: inline !important;
}

.deadline-schedule {
  width: 100%;
  display: inline-block;
}

.deadline-time,
.idle-application-time {
  width: 113px;
  display: inline-block;
  margin: 0 15px 0 0;
}

.deadline-time-zone,
.idle-application-time-zone {
  width: 113px;
  display: inline-block;
}

.texting-preview {
  padding: 6px;
}

td.details-control {
  background: url("../images/details_open.png") no-repeat center center;
  cursor: pointer;
}

tr.details td.details-control {
  background: url("../images/details_close.png") no-repeat center center;
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.showCursorPointer {
  cursor: pointer;
}

.aHrefDisabled {
  /* pointer-events: none; */
  /* cursor: default; */
  color: lightgrey;
}

.disable-pointer {
  pointer-events: none;
}
.disable-and-fade {
  pointer-events: none;
  opacity: 0.25;
}
.not-allowed {
  cursor: not-allowed !important;
}
a.aHrefDisabled:hover {
  color: lightgrey;
}

.apply-now-required {
  color: #d5413e;
  font-size: 90%;
  font-weight: normal;
}

.apply-now-required-fail {
  color: #d5413e;
}

.apply-now-required-pass {
  color: #5cb85c;
}

.apply-now-government-info-checkbox,
.apply-now-government-info-checkbox-sub-selection {
  margin-bottom: 8px;
}

.apply-now-government-info-checkbox-sub-selection {
  margin-left: 16px;
}

.apply-now-government-info-text-input-sub-selection,
.apply-now-government-info-text-input-help-text {
  margin-left: 32px;
}

.apply-now-autosave-indicator {
  position: absolute;
  left: 49%;
  float: left;
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  color: #585f69;
  padding: 6px 0;
  display: none;
}

.user-initials {
  color: #fff;
  background-color: #797676;
  min-width: 24px;
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
  text-align: center;
  border-radius: 2px;
}

.more-lenders {
  color: #fff;
  background-color: #337ab7;
  min-width: 20px;
  padding-left: 0px;
  padding-right: 0px;
  display: inline-block;
  text-align: center;
  border-radius: 2px;
  opacity: 0.3;
  white-space: pre-wrap;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.hr-padding-margin {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 8px;
  margin-bottom: 0;
}

.hr-padding-margin2 {
  padding-top: 0;
  margin-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0;
}

.hr-no-padding-margin {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.file-input-wrapper {
  max-width: 220px;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  height: 0;
  margin-bottom: 20px;
}
.custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #999999;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  font-weight: normal;
  align-items: center;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.custom-file-input ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 38px;
  padding: 0.775rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #eee;
  border-left: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
}

.custom-file-label:hover:after {
  cursor: pointer;
}

.custom-file-label.selected {
  color: #555;
}

/* CSS styles that are meant to be redefined by customers. */
.top-banner {
  font-size: 1.4rem;
}

.announcement-banner .top-banner {
  border-top: 0;
}

.top-banner {
  padding: 1rem;
  height: 67px;
  background: #fffae5;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.top-prequal-banner {
  padding: 1rem;
  height: 40px;
  background: #dff0d8;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.top-banner__dismiss {
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  background: url(../images/close.svg) no-repeat;
  background-position: right 0 top 0.2rem;
  background-size: 1.2rem;
}

.formatted_content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.formatted_content--no-wrap {
  overflow-wrap: normal;
  word-wrap: normal;
  word-break: normal;
}

.u-display-ib {
  display: inline-block;
}

.app-required-field-marker {
  color: #a94442;
  font-size: 18px;
  font-weight: 700;
}

.app-required-field-unfilled-input {
  border-color: #b86360;
}

.app-settings-error-title {
  font-weight: 700;
  padding-bottom: 6px;
}

.app-settings-error ul {
  padding-left: 16px;
}

.app-settings-error li {
  line-height: 19px;
}

.circle-x-image,
.welcome-login-circle-x-image {
  position: absolute;
  top: 0;
  right: 0;
  background: #e50000;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 25px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  z-index: 99;
}

.container.mobile-container {
  position: relative;
}

.ffu-notification-alert {
  position: absolute;
  z-index: 99;
  width: 100%;
  left: 0;
}

.ffu-example-image {
  float: right;
  width: 200px;
  margin-bottom: -5px;
}

.image-cropper-current-icon-area,
.welcome-logo-area .image-cropper-current-icon-area {
  position: relative;
}

.welcome-logo-area .image-cropper-current-icon-area {
  max-width: 801px;
}

.image-cropper-current-icon-legacy-area {
  position: relative;
  max-width: 150px;
}

.image-cropper-current-icon,
.welcome-logo-area .image-cropper-current-icon {
  border: 1px solid #eee;
  border-radius: 57px;
  width: auto;
  height: auto;
}

.welcome-logo-area .image-cropper-current-icon {
  max-width: 801px;
}

.edit-logo-form .image-cropper-current-icon {
  border-radius: 3px;
}

.edit-profile-confirm-delete-checkbox-wrapper {
  float: left;
  margin-bottom: 20px;
  margin-top: 0;
}

.edit-profile-confirm-delete-checkbox-wrapper label {
  font-weight: 400;
}

.edit-profile-delete-account-button {
  float: left;
  margin-right: 20px;
  height: 44px;
}

.edit-profile-delete-account-cancel-button {
  margin-left: 0 !important;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

.logo-for-settings {
  max-width: 801px;
  height: auto;
  width: auto;
}

.who-are-you-consent-label {
  padding-left: 0 !important;
  font-weight: 700 !important;
}

#borrower_loans_table_filter label {
  width: 100%;
  position: relative;
}

#borrower_loans_table_filter label:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 4px;
  left: 10px;
  color: #999;
}

#borrower_loans_table_filter input {
  padding-left: 30px;
}

#borrower_loans_table_filter input::placeholder {
  font-weight: 400;
}

#borrower_loans_table_filter tr {
  margin-bottom: 30px;
}

.badge-success {
  background-color: #3b863b;
}

.category-tabs {
  cursor: pointer;
}

.progressiveWebAppNameInput {
  width: 130px;
  margin-bottom: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.progressive-web-app-settings-divider {
  height: 1px;
  background-color: #e5e5e5;
  padding: 0 20px;
  margin: 20px 0;
}

.progressive-web-app-button {
  width: 123px;
  margin-right: 10px;
}

.progressive-web-app-configuration-form .panel-title {
  padding: 10px 0;
}

.progressive-web-app-configuration-form label {
  margin-bottom: 10px;
}

.progressive-web-app-configuration-form .image-cropper-icon-container {
  margin-bottom: 20px;
}

.edit-logo-form .image-cropper-icon-container,
.edit-logo-form .welcome-login-image-cropper-icon-container {
  margin-bottom: 20px;
}

.progressive-web-app-configuration-form .help-block {
  margin-top: 10px;
}

.edit-logo-form .help-block {
  margin-top: 10px;
}

.progressive-web-app-configuration-form .panel-body {
  padding: 20px 20px 30px 20px;
}

.pwa-example-area {
  padding-right: 40px;
  margin-bottom: -5px;
  float: right;
  margin-top: -40px;
}

.pwa-item {
  float: right;
}

.pwa-bubble {
  background: #fafafa;
  padding: 20px;
  max-width: 250px;
  margin: 0 -20px;
  z-index: 1;
  position: relative;
  margin-top: 140px;
}

.pwa-bubble-1 {
  margin-top: 190px;
}

.pwa-bubble-2 {
  padding: 20px 40px;
  margin-top: 90px;
}

.pwa-bubble-3 {
  padding-left: 30px;
}

.android-area {
  padding-top: 50px;
}

.pwa-example-image {
  height: 350px;
  z-index: 2;
  position: relative;
}

.android-example-image {
  height: 300px;
}

/*
 * Edit deadline screen
 */
.edit-deadline-form,
.edit-idle-application-form {
  margin-top: 20px;
}

.blackout-days:after,
.deadline-area:after,
.idle-application-area:after {
  content: " ";
  display: table;
  clear: both;
  margin-bottom: 30px;
}

.before-deadline-reminder,
.before-idle-application-reminder {
  margin-bottom: 30px;
}

.before-deadline-reminder input,
.before-idle-application-reminder input {
  margin-right: 5px;
}

.before-idle-application-reminder label {
  display: block;
  margin-bottom: 5px;
  font-weight: normal;
}

.other-holidays-area {
  margin-top: 5px;
}

.other-holiday {
  float: left;
  width: 120px;
  margin-left: 20px;
}

.other-holidays-item {
  margin-bottom: 15px;
}

.other-holidays-item:after {
  content: " ";
  display: table;
  clear: both;
}

.checkbox.no-margin {
  margin-bottom: 5px;
}

.other-holidays-item {
  position: relative;
}

.other-holidays-item .ui-datepicker-trigger {
  position: absolute;
  top: 6px;
  left: 112px;
  height: 20px;
}

/*
*
*Alerts
*/
.alert-success {
  color: #333 !important;
}

.alert-success button.close {
  opacity: 0.8;
}

/*
   * Circle checkboxes seen on  edit deadline screen
   */
.circle-checkbox {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: relative;
  float: left;
  margin: 0 10px 0 0;
}

.circle-checkbox label {
  text-align: center;
  line-height: 40px;
  font-weight: normal;
  color: black;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  background: #f6f6f6;
}

.circle-checkbox label::after {
  content: "S";
}

.circle-checkbox-monday label::after {
  content: "M";
}

.circle-checkbox-tuesday label::after {
  content: "T";
}

.circle-checkbox-wednesday label::after {
  content: "W";
}

.circle-checkbox-thursday label::after {
  content: "Th";
}

.circle-checkbox-friday label::after {
  content: "F";
}

.circle-checkbox-saturday label::after {
  content: "S";
}

#sig-container {
  margin-bottom: 8px;
}

.signature-fancy {
  border: none;
  background: #f4f4f4;
}

#sig-container .inner-box {
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}

#signature:focus,
#initials:focus {
  outline: none;
}

#sig-container .sig-inner label {
  position: absolute;
  left: 20px;
  bottom: 0;
}

.circle-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.circle-checkbox input[type="checkbox"]:checked + label {
  background: #447ab2;
  color: white;
}

.after-deadline-reminder-days,
.after-idle-application-reminder-days {
  padding-left: 15px;
}

.text-between-drops {
  padding: 7px 10px 0;
}

.left {
  float: left;
}

#systemMonitor .dataTables_paginate {
  float: left;
}

table.dataTable thead > tr > th.systemMonitorSearch {
  padding-left: 5px !important;
  padding-right: 15px !important;
}

.systemMonitorTableOnly {
  overflow-x: scroll;
}
.new-prospect-button {
  margin: 20px 0 15px 0;
}
#old-loan-choice-wrapper .page-intro {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
}
#old-loan-choice-wrapper .plus-sign {
  font-size: 22px;
  margin-right: 24px;
}

.radio-boxes-container {
  display: flex;
  max-width: 1140px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.radio-boxes-container label {
  width: 49%;
  max-width: 550px;
}
.radio-boxes-container input {
  display: none;
}
.yesno-radio-container input {
  opacity: 0;
  width: 0;
  height: 0;
}
.yesno-radio-container label {
  margin-top: -15px;
}
.yesno-radio-container input:focus + span {
  outline: -webkit-focus-ring-color auto 5px;
}
.radio-boxes-container label:hover,
.yesno-radio-container label:hover {
  cursor: pointer;
}
.radio-boxes-container .app-completed:hover {
  cursor: default;
}
.radio-boxes-container .radio-box {
  max-width: 550px;
  height: 91px;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #bdbdbd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 28px;
  margin-bottom: 34px;
}

.radio-boxes-container .radio-box:hover {
  box-shadow: 0px 2px 10px 0px rgba(51, 51, 51, 0.12);
  -webkit-transition: 0.1s linear 0.1s;
  -moz-transition: 0.1s linear 0.1s;
  -o-transition: 0.1s linear 0.1s;
  transition: 0.1s linear 0.1s;
}

.yesno-radio-container .yesno-radio-box {
  margin-right: 15px;
}
.yesno-radio-container .yesno-radio-box:disabled {
  cursor: not-allowed;
}

.yesno-radio-container .yesno-radio-box-inner {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  width: 65px;
  height: 38px;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  display: flex;
  justify-content: center;
  background: #f0f0f0;
  align-items: center;
}

.radio-boxes-container .radio-box.app-completed {
  border: none;
  box-shadow: none;
}

.radio-boxes-container .radio-box:nth-child(2),
.yesno-radio-container .yesno-radio-box:nth-child(2) {
  margin-right: 0;
}
.radio-boxes-container input:checked + span {
  border: solid 2px #347ab7;
}

.yesno-radio-container input:checked + span {
  background: #5787ba;
  border: none;
  color: #fff;
}

.radio-box .loan-name {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.23px;
  color: #333333;
  margin-bottom: 4px;
}
.radio-box .creation-date,
.radio-box .lo-name,
.radio-box .docs-owed,
.radio-box .deadline {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.17px;
  color: #757575;
}
.radio-box .creation-date,
.radio-box .lo-name {
  font-weight: 400;
}
.radio-box .docs-owed,
.radio-box .deadline {
  color: #7b7b7b;
}

.state-forms {
  margin-left: 8px;
}

.state-specific-form label {
  font-size: 13px;
}

#az-lsu-tables .table-title {
  padding: 0 7px;
}
#az-lsu-tables tr {
  display: flex;
  align-items: center;
  min-height: 66px;
}
#az-lsu-tables tr td.col-sm-10 {
  display: flex;
}

#az-lsu-tables .completed-date {
  display: flex;
}

#az-lsu-tables .completed-date .input-group-addon {
  width: auto;
}

.preview-form {
  float: left;
}

/* Small and Extra Small Devices, i.e. Phones */
@media only screen and (max-width: 991px) {
  .top-prequal-banner {
    height: auto;
  }
  #KickoffModal .modal-content {
    height: 325px;
  }

  #KickoffModal .modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  body {
    padding-top: 0px !important;
  }

  .footer {
    padding-bottom: 2px;
    margin-bottom: 19px;
    padding-left: 0;
    padding-right: 0;
  }

  .margin-top-small {
    margin-top: 1em;
  }

  .mobile-text-input {
    height: 41px;
  }

  .mobile-font-size-17 {
    font-size: 17px !important;
  }

  .mobile-font-size-20 {
    font-size: 20px !important;
  }

  .mobile-font-size-34 {
    font-size: 34px;
  }

  .mobile-profile-edit-text-input {
    color: #000000;
  }

  .mobile-borrower-profile-edit-hr-divider {
    border: none;
    margin: 0;
  }

  .mobile-profile-edit-submit-cancel-buttons-wrapper {
    margin: 5px 0 20px 0;
  }

  .edit-profile-delete-account-button {
    font-weight: 500;
  }

  .mobile-divider {
    padding: 0 0 19px 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
  }

  .mobile-submit {
    height: 44px;
    background-color: #398339;
    color: #ffffff;
    border-color: #398339;
    font-weight: 500;
  }

  a.mobile-submit {
    line-height: 2;
  }

  .mobile-cancel {
    height: 44px;
    padding: 11px 12px;
  }

  .mobile-document-info-list .link-action-desc {
    display: inline;
    line-height: 1.5;
  }
  .mobile-document-info-list i.bouncetrash.fa.fa-trash-o.fa-140,
  .mobile-document-info-list i.fa.fa-arrow-circle-o-up {
    margin-left: 0px !important;
  }
  .mobile-document-info-list .link-wrap {
    margin: 1em 0;
  }
  .mobile-document-info-list .floify-button-icon {
    text-align: left;
  }

  .mobile-profile-edit-cancel {
    height: 44px;
    background-color: #ffffff;
    border-color: #398339;
    color: #398339;
    padding: 11px 12px;
  }

  #delete-button-mobile {
    color: #d43f3a;
  }

  .optional-mobile {
    font-size: 12px;
    font-weight: normal;
  }

  .mobile-loan-view-header {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    padding: 20px 0 20px 0;
  }

  .mobile-footer-content {
    float: left !important;
    padding-left: 7px;
  }

  .mobile-loan-info {
    color: #000000;
    margin: -14px -15px 0 -15px;
    padding: 0 15px 0 10px;
  }

  .mobile-grey-height-divider {
    background-color: #efefef;
    height: 1px;
    margin: 20px -15px;
  }

  .mobile-loan-info-text {
    font-size: 16px;
  }

  .mobile-milestone-checkoff-text {
    font-size: 16px;
  }

  .mobile-milestone-list {
    padding: 0 15px 0 10px;
  }

  .mobile-milestone-checkmark {
    color: #5cb85c !important;
  }

  .allMilestones {
    color: #000000;
    background-color: #ffffff;
  }

  .mobile-milestone {
    padding: 0 0 0 0;
    color: #cccccc;
    font-size: 2em;
    display: inline-block;
  }

  .mobile-milestone-line {
    text-align: justify;
    line-height: 0;
    margin-bottom: 5px;
    text-align: center;
  }

  .mobile-milestone-line:after {
    content: "";
    width: 100%;
    display: inline-block;
  }

  .mobile-milestone-indicators {
    line-height: 40px;
    margin-left: -1.5px;
    display: flex;
    flex-wrap: wrap;
  }

  .mobile-milestone .fa-check-circle-o {
    color: #5ab95c;
  }

  .mobile-milestone-counts {
    padding-bottom: 20px;
  }

  .mobile-docs-info-container {
    margin-bottom: 60px;
  }

  .mobile-docs-info {
    background-color: #ffffff;
    margin: 0 -15px 0 -15px;
  }

  .mobile-docs-info h2 {
    padding-left: 10px;
  }

  .mobile-add-new-doc-button-wrapper {
    padding: 0 0 20px 10px;
  }

  .mobile-add-new-doc-button {
    background-color: #398639;
    border-color: #4cae4c;
    color: #ffffff;
    font-weight: bold;
    height: 44px;
    line-height: 30px;
  }

  .table.table-striped.card-style .mobile-loan-dashboard-view-loan-link a {
    background-color: #398639 !important;
    border-color: #4cae4c !important;
  }

  .mobile-document-category-dropdown {
    height: 61px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    background-color: #f5f5f5;
    font-size: 17px;
    color: #000000;
    display: block;
    margin: 0 10px;
    text-decoration: none;
  }

  .mobile-document-category-dropdown:active,
  .mobile-document-category-dropdown:focus {
    text-decoration: none;
    color: #000000;
    background-color: #87c1e1;
  }

  .mobile-document-category-dropdown:-webkit-any-link {
    text-decoration: none;
  }

  .mobile-document-category-name {
    color: inherit;
    text-decoration: none;
    padding-left: 20px;
    line-height: 61px;
  }

  .mobile-document-category {
    margin-bottom: 10px;
  }

  .mobile-documents-category-header {
    color: #000000;
    font-weight: 500;
    font-size: 24px;
    line-height: 61px;
    text-align: center;
    margin: 0 8px 0 -15px;
  }

  .mobile-documents-category-header-chevron {
    height: 61px;
    color: #000000;
    font-weight: normal;
    font-size: 18px;
    line-height: 61px;
    text-align: center;
    float: left;
  }

  .mobile-docs-category-divider {
    background-color: #efefef;
    height: 1px;
    margin: 0 -15px;
  }

  .mobile-documents-category-go-back-link {
    display: inline-block;
    float: left;
  }

  .mobile-documents-category-container {
    margin-top: -14px;
  }

  .mobile-badge-danger,
  .mobile-badge-warning,
  .mobile-badge-success {
    height: 30px;
    width: 30px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    line-height: 30px;
    padding: 0;
    font-size: 17px;
    color: #ffffff;
    margin-bottom: 5px;
    text-align: center;
  }

  .mobile-document-subcategory {
    height: 61px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    background-color: #f5f5f5;
    font-size: 17px;
    color: #000000;
    display: block;
    margin: 10px 0 0 0;
    line-height: 61px;
    font-weight: 500;
    cursor: pointer;
  }

  .mobile-document-subcategory[aria-expanded="true"] {
    box-shadow: 0 2px 4px 0 rgba(174, 174, 174, 0.5);
    -webkit-box-shadow: 0 2px 4px 0 rgba(174, 174, 174, 0.5);
    -moz-box-shadow: 0 2px 4px 0 rgba(174, 174, 174, 0.5);
  }

  .mobile-document-subcategory a {
    color: #000000;
    text-decoration: none;
    padding-left: 20px;
  }

  .mobile-document-chevron {
    font-size: 17px;
    font-weight: normal;
    line-height: 61px;
    padding-right: 20px;
    float: right;
    text-align: right;
  }

  .mobile-document-subcategory-chevron {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
  }

  .mobile-documents-subcategory-count {
    padding-right: 20px;
    color: #4a4a4a;
    font-size: 16px;
  }

  .mobile-document-list {
    list-style: none;
    padding-left: 0;
  }

  .mobile-document-info-list {
    list-style: none;
    padding-left: 0;
  }

  .mobile-document-categories-list .collapsing {
    list-style: none;
    padding-left: 0;
    width: 100% !important;
  }

  .mobile-document-info-list li {
    background-color: #f5f5f5;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    margin: 10px 20px;
  }

  .mobile-document-categories-list {
    list-style: none;
    padding-left: 0;
  }

  .mobile-white-divider-1px {
    background-color: #ffffff;
    height: 1px;
  }

  .mobile-document-name {
    font-size: 17px;
    font-weight: normal;
    color: #000000;
    float: left;
    width: 200px;
  }

  .mobile-document-name-and-actions {
    padding: 20px;
  }

  .mobile-document-name-and-actions button i,
  .mobile-document-name-and-actions a i {
    color: #5cb85c;
    font-size: 20px;
    margin-left: 10px !important;
  }

  .mobile-document-name-and-actions a i {
    margin-right: 1px !important;
  }

  .mobile-document-name-and-actions button img {
    color: #5cb85c;
    font-size: 20px;
    margin-left: 10px !important;
  }

  .mobile-document-name-and-actions form,
  .mobile-document-name-and-actions form span {
    display: inline-block;
    margin: 0 !important;
    padding: 0 !important;
  }

  .mobile-document-uploaded-actions-area .title {
    display: block;
    text-align: center;
    padding-top: 4px;
  }

  .mobile-document-uploaded-actions-area {
    display: flex;
    flex-direction: column;
  }

  .mobile-document-name-and-actions a {
    display: inline-block;
    margin: 0 !important;
    padding: 0 !important;
  }

  .mobile-document-name-and-actions a.btn-success-mobile {
    display: inline-block;
    margin: 1em 2px 0px !important;
    padding: 2px 8px !important;
    float: right !important;
    text-transform: uppercase;
    font-size: 13px;
    background: #398639;
  }

  .mobile-document-name-and-actions a.btn-primary-mobile, .mobile-document-name-and-actions a.btn-secondary-mobile {
    margin: 1em 2px 0px !important;
  }

  .mobile-submit {
    background: #398639;
  }

  #verifyForm .btn-success {
    background-color: #3b863b;
    border-color: #3b863b;
  }

  .link-action-desc {
    color: #3074ab;
  }

  .mobile-document-uploaded-actions {
    display: block;
  }

  .mobile-document-date {
    color: #707070;
    font-size: 14px;
    line-height: 19px;
    padding: 10px 0 10px 0;
    font-weight: normal;
  }

  .mobile-document-description {
    color: #000000;
    font-size: 14px;
    line-height: 19px;
    font-weight: normal;
    padding: 0;
  }

  .mobile-document-uploaded-area {
    background-color: #e3eef4;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    color: #000000;
    font-size: 14px;
    line-height: 19px;
    padding: 10px;
    margin-top: 10px;
  }

  .mobile-document-reject-info {
    margin-top: 15px;
  }

  .mobile-document-alert {
    color: #d5413e;
    font-size: 14px;
  }

  .mobile-document-divider {
    height: 1px;
    background-color: #efefef;
    margin-left: 20px;
  }

  .mobile-no-docs-here {
    margin-top: 20px;
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.77);
  }

  #instructions1,
  #instructions2,
  #instructions3 {
    font-size: 14px;
    font-weight: normal;
  }

  #instructions2 b {
    font-weight: normal;
  }

  .progress-mobile {
    position: absolute;
    width: 95%;
    bottom: 75px;
    margin-left: 10px;
  }

  #KickoffModalNextAction {
    background-color: #5cb85c;
    border-color: #4cae4c;
    font-weight: 500;
  }

  .profile-page-header-mobile {
    border: none;
    margin: 0;
    padding-bottom: 0;
  }

  .mobile-profile-header {
    padding: 20px 0 10px 0;
    margin: -14px 0 0 0;
  }

  .mobile-profile-divider {
    margin: 10px 0 10px 0;
    height: 1px;
    background-color: #efefef;
  }

  .mobile-profile-property-name {
    font-size: 14px;
    color: #3174af;
    padding-bottom: 3px;
  }

  .mobile-profile-property-value {
    font-size: 17px;
    color: #000000;
  }

  .mobile-profile-edit-button {
    background-color: #5cb85c;
    border-color: #4cae4c;
    color: #ffffff;
    font-weight: bold;
    padding: 6px 12px;
    font-size: 14px;
    height: 44px;
    line-height: 30px;
  }

  .mobile-profile-edit-button-wrapper {
    margin-top: 10px;
    margin-bottom: -10px;
  }

  .mobile-profile-edit-delete-account-link {
    font-size: 14px;
    color: #d5413e;
    margin: 30px 0 50px 0;
    background: none;
    border: none;
    float: left !important;
  }

  .mobile-profile-edit-delete-account-link:hover {
    color: #d5413e;
    background: none;
    border: none;
  }

  .borrower-profile-form fieldset {
    border: none;
    padding-left: 0;
  }

  .borrower-profile-form legend {
    display: none;
  }

  .mobile-who-are-you-page-header {
    border: none;
    padding: 0;
    margin: 0;
  }

  .mobile-who-are-you-header {
    padding: 20px 0 10px 0;
    margin: -14px 0 0 0;
  }

  .mobile-who-are-you-divider {
    margin: 10px 0 20px 0;
    height: 1px;
    background-color: #efefef;
  }

  .mobile-who-are-you-description {
    color: #000000;
    margin-bottom: 20px;
  }

  .mobile-consent-divider {
    margin: 10px 0 20px 0;
    height: 1px;
    background-color: #efefef;
  }

  #borrower_loans_table_filter label {
    width: 100%;
    position: relative;
  }

  #borrower_loans_table_filter label:before {
    content: "";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    font-size: 17px;
    top: 0;
    left: 10px;
    color: #999;
    line-height: 44px;
  }

  #borrower_loans_table_filter input {
    font-weight: 400;
    padding-left: 36px;
    font-size: 17px;
    height: 44px;
    line-height: 44px;
  }

  #borrower_loans_table_mobile_filter,
  #realtor_loans_table_mobile_filter {
    width: calc(100% - 66px);
    margin: 0 auto;
    padding-top: 1em;
  }

  #borrower_loans_table_mobile_filter input::placeholder,
  #realtor_loans_table_mobile_filter input::placeholder {
    font-weight: 400;
  }

  #borrower_loans_table_mobile_filter label,
  #realtor_loans_table_mobile_filter label {
    width: 100%;
    position: relative;
    margin-bottom: 0;
  }

  #borrower_loans_table_mobile_filter label:before,
  #realtor_loans_table_mobile_filter label:before {
    content: "";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    font-size: 17px;
    top: -1px;
    left: 10px;
    color: #999;
    line-height: 44px;
  }

  #borrower_loans_table_mobile_filter input,
  #realtor_loans_table_mobile_filter input {
    padding-left: 36px;
    font-size: 17px;
    height: 44px;
    line-height: 44px;
    font-weight: normal;
  }

  #borrower_loans_table_mobile_wrapper table.dataTable {
    margin-top: -4px !important;
  }

  #borrower_loans_table_mobile_wrapper tr {
    margin-bottom: 35px;
  }

  table.borrower-loan-dashboard-table-mobile {
    width: 100% !important;
    border-collapse: separate;
    border-spacing: 0 28px;
  }

  .mobile-loan-dashboard-loan-name {
    float: left;
    color: #000000;
    font-size: 20px;
    font-weight: normal;
    line-height: 25px;
    margin-left: 2px;
    margin-top: 2px;
    max-width: 168px;
  }

  .mobile-loan-dashboard-counts {
    position: absolute;
    right: 13px;
  }

  #gray-bg {
    background: #f3f4f5;
    padding-top: 1px;
  }

  .mobile-loan-dashboard-accepted {
    padding-left: 10px;
  }

  .mobile-loan-dashboard-uploaded {
    padding-left: 10px;
  }

  .mobile-loan-dashboard-loan-in-process {
    clear: both;
  }

  .mobile-loan-dashboard-loan-in-process {
    padding: 10px 0 10px 0;
    color: #3c7aae;
    font-size: 14px;
    font-weight: bold;
  }

  .mobile-loan-dashboard-contact {
    padding: 0 0 3px 0;
    color: #000000;
    font-weight: 300;
    letter-spacing: 0.12px;
  }

  .mobile-loan-dashboard-team {
    color: #000000;
    font-weight: 300;
    letter-spacing: 0.12px;
    line-height: 19px;
  }

  .mobile-loan-dashboard-link-divider {
    height: 1px;
    background-color: #cfcfcf;
    margin: 10px -10px 0 -10px;
  }

  .mobile-loan-dashboard-view-loan-link {
    padding: 10px 0 0 0;
  }

  .table.table-striped.card-style .mobile-loan-dashboard-view-loan-link a {
    background-color: #5cb85c;
    border-color: #4cae4c;
    color: #ffffff;
    font-weight: bold;
    height: 44px;
    line-height: 30px;
  }

  .mobile-bold {
    font-weight: 500;
  }

  .mobile-float-left {
    float: left !important;
  }

  .mobile-float-right {
    float: right !important;
  }

  .mobile-clear-both {
    clear: both;
  }

  .mobile-margin-top-2 {
    margin-top: 2px !important;
  }

  .mobile-margin-top-15 {
    margin-top: 15px !important;
  }

  .mobile-margin-top-20 {
    margin-top: 20px !important;
  }

  .mobile-margin-bottom-20 {
    margin-bottom: 20px !important;
  }

  .mobile-margin-bottom-5 {
    margin-bottom: 5px !important;
  }

  .mobile-margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .mobile-margin-left-10 {
    margin-left: 10px !important;
  }

  .mobile-padding-top-0 {
    padding-top: 0 !important;
  }

  .mobile-padding-bottom-5 {
    padding-bottom: 5px !important;
  }

  .mobile-padding-bottom-20 {
    padding-bottom: 20px !important;
  }

  .mobile-padding-right-15 {
    padding-right: 15px !important;
  }

  .mobile-padding-right-10 {
    padding-right: 10px !important;
  }

  .mobile-padding-right-0 {
    padding-right: 0 !important;
  }

  .mobile-display-block {
    display: block;
  }

  .mobile-height-20 {
    height: 20px;
  }

  .mobile-height-44 {
    height: 44px;
  }

  .mobile-height-100 {
    height: 100px;
  }

  .mobile-height-120 {
    height: 120px;
  }

  .mobile-font-weight-400 {
    font-weight: 400;
  }

  .mobile-width-100-percent {
    width: 100%;
  }

  .mobile-no-border-top {
    border-top: none !important;
  }

  .mobile-no-border-bottom {
    border-bottom: none !important;
  }

  .mobile-who-are-you-submit {
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile-consent-submit {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
  }

  .mobile-consent-submit[disabled] {
    height: 44px;
    background-color: #5cb85c;
    color: #ffffff;
    border-color: #4cae4c;
    pointer-events: none;
  }

  .mobile-footer-bottom-of-page {
    position: absolute;
    bottom: 0;
  }

  .mobile-custom-form-buttons-wrapper {
    min-height: 35px;
  }

  .mobile-custom-form-buttons-wrapper button {
    margin-right: 10px;
  }

  .mobile-custom-form-buttons-cancel-wrapper {
    margin-top: 10px;
  }

  .mobile-custom-form-save-progress {
    height: 44px;
    background-color: #ffffff;
    border-color: #5cb85c;
    color: #5cb85c;
    padding: 11px 12px;
  }

  .mobile-custom-form-cancel {
    height: 44px;
    background-color: #ffffff;
    border-color: #5cb85c;
    color: #5cb85c;
    padding: 11px 12px;
  }

  .mobile-custom-form-divider {
    padding-top: 20px;
    margin-bottom: 19px;
    border-bottom: 1px solid #eee;
    height: 1px;
  }

  .ffu-example-image {
    float: none;
    display: block;
  }
  .state-specific-form .modal-footer {
    display: flex;
    flex-direction: column;
  }

  .state-specific-form .modal-footer button,
  .state-specific-form .modal-footer .btn + .btn {
    margin-bottom: 10px;
  }

  .view-doc-button {
    margin-left: 0;
  }
}
/* CSS ghost inspector – handy CSS for debugging, adds outline to all elements*/
/** {*/
/*background: #000 !important;*/
/*color: #0f0 !important;*/
/*outline: solid #f00 1px !important;*/
/*}*/
.unread {
  font-weight: bold;
  background-color: #ffffff;
}

.read {
  font-weight: normal;
  background-color: #f9f9f9;
}

tr.group,
tr.group:hover {
  background-color: #8c98a5 !important;
}

.text-white {
  color: #fff;
}

.ddtracker {
  cursor: pointer;
}

.badge-tracking {
  background-color: #8c98a5;
}

.convert-disabled {
  cursor: not-allowed;
  color: lightgrey;
}

.checkbox-vertical {
  margin-bottom: 5px;
  display: block;
  font-weight: normal;
}

#rules {
  border-collapse: separate;
  border-bottom: 1pt solid lightgrey;
}

/*
   * Color Picker on AN2 settings
   */
.picker-wrapper,
.slide-wrapper {
  position: relative;
}

.picker-indicator,
.slide-indicator {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.picker {
  cursor: default;
  width: 400px;
  height: 120px;
}

.slide {
  cursor: ew-resize;
  width: 36px;
  height: 380px;
  border: 10px solid white;
}

.slide svg {
  border-radius: 8px;
}

.slide-wrapper {
  margin-left: 10px;
  margin-left: 10px;
  transform: rotate(-90deg);
  transform-origin: top left;
  position: absolute;
  top: 170px;
}

.color-picker-main,
.color-picker-main-domain {
  position: relative;
  min-height: 175px;
}

.picker-indicator {
  width: 24px;
  height: 24px;
  border: 2px solid white;
  border-radius: 30px;
  background-color: transparent;
}

.slide-indicator {
  width: 25px;
  height: 25px;
  border: 1px solid #666;
  background-color: white;
  border-radius: 15px;
  left: 6px;
}

.color-picker-area,
.color-picker-area-domain {
  width: 100%;
  overflow: hidden;
  padding: 10px;
}

.color-picker-manual,
.color-picker-manual-domain {
  clear: both;
  float: left;
  margin: 10px 0 0 20px;
  position: relative;
}

.color-picker-input,
.color-picker-input-domain {
  border: none;
  border-bottom: 1px solid black;
  font-size: 16px;
  padding: 4px 20px 4px 4px;
  max-width: 100px;
  margin-right: 20px;
}

.color-picker-rgba,
.color-picker-rgba-domain {
  max-width: 160px;
}

.color-picker-inner,
.color-picker-inner-domain {
  float: left;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 0 0 20px 0;
}

.inputAlert {
  box-shadow: 0 0 6px 0 #d5413e;
}

.milestone-disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.ob-section {
  margin-bottom: 15px;
}

.ob-section label,
.ob-section .help-block {
  margin-bottom: 0px;
  margin-top: 0px;
  display: block;
  font-weight: normal;
}

.ob-section input[type="text"],
.ob-section input[type="password"] {
  width: 100%;
  border-radius: 3px;
  padding: 6px;
  outline: none;
  border: 1px solid #cecece;
}

label.ob-medium {
  font-weight: 500;
}

.div-table .div-table-row-wrap {
  border-top: 1px solid #d8d8d8;
}
.div-table .div-table-row {
  border-bottom: 1px solid #d8d8d8;
}
.div-table .div-table-row:nth-child(odd) {
  background: #f9f9f9;
}
.div-table .div-table-cell {
  padding: 15px 8px;
  word-break: break-all;
}
.div-table .div-table-head {
  margin: 0;
  color: #797676;
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 10px;
}
.div-table .div-table-head p {
  margin: 0;
  color: #797676;
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 10px;
}
.div-table .div-table-head .div-table-row {
  background: #fff;
  border-bottom: initial;
}
.div-table .div-table-head .div-table-row .div-table-cell {
  padding: 0 8px 0px 8px;
}

.table.table-striped {
  width: 100% !important;
}
.e-sign-tab-column {
  width: 70%;
}
.table.table-striped thead tr th {
  border: none;
  color: #797676;
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 10px;
}
.table.table-striped thead tr th:after {
  opacity: 1;
  bottom: 10px;
}
.table.table-striped.card-style tbody tr.danger {
  background-color: #f2dede !important;
}
.table.table-striped.card-style tbody tr.warning {
  background-color: #fcf8e3 !important;
}
.table.table-striped.card-style tbody tr td a {
  color: #121727;
}
.table.table-striped tbody tr {
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}
.table-striped:not(.milestone-table) > tbody > tr:nth-of-type(odd) {
  background-color: #f8fafc !important;
}
.table-striped:not(.milestone-table) > tbody > tr:nth-of-type(odd):hover {
  background-color: #f2f4f7 !important;
}

.table.table-striped tbody tr td {
  padding: 15px 8px;
  border: none;
  color: #121727;
  word-break: break-word;
  word-wrap: break-word;
  min-width: 65px;
}

.send-pending-docs-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 8px;
}

.send-pending-docs-body {
  position: absolute;
  min-width: 284px;
  max-width: 485px;
  background: #FFFFFF;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
  padding: 20px 0 6px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.send-pending-docs-title {
  padding: 0 24px;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #9AA4B2;
}

.send-pending-docs-btn {
  display: flex;
  align-items: center;
  padding: 10px 24px 10px 26px;
  background: #FFFFFF;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  border: none;
}


.send-pending-docs-btn:hover {
  background: #F8FAFC;
}

.send-pending-docs-btn:disabled {
  background: #FFFFFF;
  color: #CDD5DF;
  cursor: not-allowed;
}

.send-pending-docs-btn-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.send-pending-docs-count {
  display: flex;
  margin-left: 12px;
  border: 1px solid #E3E8EF;
  border-radius: 4px;
  padding: 3px 8px 3px 8px;
  color: #697586;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.stack-and-send-picker-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 8px;
}

.stack-and-send-picker-body {
  position: absolute;
  min-width: 284px;
  max-width: 485px;
  background: #FFFFFF;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
  padding: 20px 0 6px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.stack-and-send-picker-title {
  padding: 0 24px;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #9AA4B2;
}

.stack-and-send-picker-btn {
  display: flex;
  align-items: center;
  padding: 10px 24px 10px 26px;
  background: #FFFFFF;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  border: none;
}

.stack-and-send-picker-btn:hover {
  background: #F8FAFC;
}

.stack-and-send-picker-btn-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-new-doc-picker-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 8px;
}

.add-new-doc-picker-body {
  position: absolute;
  min-width: 284px;
  max-width: 485px;
  background: #FFFFFF;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
  padding: 20px 0 6px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.add-new-doc-picker-title {
  padding: 0 24px;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #9AA4B2;
}

.add-new-doc-picker-btn {
  display: flex;
  align-items: center;
  padding: 10px 24px 10px 26px;
  background: #FFFFFF;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  border: none;
}

.add-new-doc-picker-btn:hover {
  background: #F8FAFC;
}

.add-new-doc-picker-btn-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 900px) {
  .send-pending-docs-modal {
    left: 0 !important;
  }
  .send-pending-docs-body {
    width: 344px;
  }
  .send-pending-docs-btn-text {
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }
  .stack-and-send-picker-modal {
    left: 0 !important;
  }
  .stack-and-send-picker-body {
    width: 344px;
  }
  .stack-and-send-picker-btn-text {
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }
  .add-new-doc-picker-modal {
    left: 0 !important;
  }
  .add-new-doc-picker-body {
    width: 344px;
  }
  .add-new-doc-picker-btn-text {
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }
}

.table.table-striped tbody tr td.loan-action-wrapper-outter {
  min-width: 70px;
}
.table.table-striped tbody tr td .loan-action-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 5px;
}
a .fa-duotone,
a .fa-duotone * {
  --fa-primary-color: #4579b2;
  --fa-secondary-color: #6ba7ea;
  --fa-secondary-opacity: 1;
}
a .fa-duotone.fa-circle-arrow-down,
a .fa-duotone.fa-circle-arrow-down {
  --fa-primary-color: transparent;
  --fa-secondary-color: #4579b2;
}

.table.table-striped tbody tr td .loan-action-wrapper > a {
  margin: 0;
  padding: 0;
}
.table.table-striped tbody tr td a {
  transition: color 0.3s ease;
}
.table.table-striped tbody tr td a:hover {
  color: #337ab7;
  text-decoration: none;
}
.table.table-striped tbody tr td a i {
  color: #337ab7;
}
.table.table-striped tbody tr td .action-name {
  display: none;
}
.table.table-striped tbody tr td .action-mobile-btn {
  display: none;
  position: relative;
  width: 20px;
  left: 37%;
  height: 20px;
  font-size: 21px;
  color: #626974;
  padding: 1em;
}
.table.table-striped tbody tr td .action-mobile-btn i,
.table.table-striped tbody tr td .action-mobile-btn img {
  pointer-events: none;
  transition: opacity 0.3s ease 0s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.table.table-striped tbody tr td .action-mobile-btn .fa-ellipsis-h {
  opacity: 1;
}
.table.table-striped tbody tr td .action-mobile-btn img {
  opacity: 0;
}
.table.table-striped tbody tr td .action-mobile-btn.show-x img {
  opacity: 1;
}
.table.table-striped tbody tr td .action-mobile-btn.show-x .fa-ellipsis-h {
  opacity: 0;
}

div.dataTables_wrapper div.dataTables_info {
  font-size: 14px;
  padding-top: 10px !important;
  color: #626974;
}
div.dataTables_wrapper input[type="checkbox"] {
  margin-left: 7px;
}

.popover {
  z-index: 1040;
}

.floify-esign.floify-esign-popover + .popover {
  display: block !important;
}
@media screen and (max-width: 1400px) {
  .chat-fix {
    float: left !important;
    margin-left: 9px;
    border-left: 1px solid rgba(176, 179, 182, 0.5);
    padding-left: 10px;
  }
}
@media screen and (min-width: 900px) {
  .floify-esign.floify-esign-popover + .popover {
    display: none !important;
  }
}

.container.mobile-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 111px);
}

.container.mobile-container .loan-level-actions {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 12px;
}
.container.mobile-container .loan-level-actions .loan-level-actions_info {
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.container.mobile-container .loan-level-actions .loan-level-actions_buttons {
  display: flex;
  gap: 8px;
}
@media screen and (max-width: 900px) {
  .container.mobile-container .loan-level-actions {
    flex-direction: column;
  }
  .container.mobile-container .loan-level-actions .loan-level-actions_buttons {
    flex-wrap: wrap;
  }
}

div[role="main"] {
  flex: 1 1 auto;
}

#auto-loan-doc-options .checkbox {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 0;
}

#auto-loan-doc-options {
  margin-top: -10px;
}

#auto-loan-doc-options label {
  margin-bottom: 5px;
}

.lowercase {
  text-transform: lowercase;
}

.show-lightbox-docfile {
  color: #2e6ca3;
}

@media only screen and (max-width: 1210px) {
  .dataTables_wrapper table.dataTable thead > tr > th.sorting_asc,
  .dataTables_wrapper table.dataTable thead > tr > th.sorting_desc,
  .dataTables_wrapper table.dataTable thead > tr > th.sorting,
  .dataTables_wrapper table.dataTable thead > tr > td.sorting_asc,
  .dataTables_wrapper table.dataTable thead > tr > td.sorting_desc,
  .dataTables_wrapper table.dataTable thead > tr > td.sorting {
    padding-right: 0px !important;
  }

  .table.table-striped tbody td {
    word-break: break-all;
    word-wrap: break-word;
  }
  div#thirdPartiesList td {
    white-space: nowrap;
  }
}

#prospects_table .unread:last-of-type a:first-of-type {
  margin-left: 0 !important;
}

#prospects_table .prospect-action {
  float: left;
  margin-bottom: 4px;
  margin-right: 3px;
  margin-top: 1px;
}

#prospects_table .prospect-action:last-of-type {
  margin-right: 0;
}

#prospects_table td:last-of-type a {
  height: 22px;
}

@media only screen and (max-width: 900px) {
  #my-tab-content {
    margin-right: -30px;
    margin-left: -30px;
  }
  .radio-boxes-container {
    flex-direction: column;
    -ms-flex-direction: column;
  }

  .radio-boxes-container label {
    width: 100%;
  }

  .new-prospect-button {
    margin-top: 20px;
  }

  #loans_table_wrapper,
  #conditions_pending_loans_table_wrapper,
  #docs_pending_loans_table_wrapper,
  #archived_loans_table_wrapper,
  #pipeline-status-table-wrapper,
  #borrower_loans_table_mobile_wrapper,
  #realtor_loans_table_mobile_wrapper {
    padding: 0 15px 50px 15px;
  }

  #pipeline-status-table-wrapper {
    margin-top: 15px;
  }

  #archived_loans_table_wrapper {
    margin-top: 16px;
  }

  /*page specific table word break fixes*/
  #startrealtors_table td {
    word-break: normal;
  }

  .table-responsive {
    border: none;
  }

  /*icons on mobile for specific data tables*/
  #prospects_table .fa-fw,
  #users-div-table-body .fa-fw,
  #doctable .flow-table-doc .fa-fw {
    width: initial;
  }
  #prospects_table i::after,
  #users-div-table-body i::after,
  #doctable .flow-table-doc i::after {
    content: " " attr(data-original-title);
    font-size: 14px;
    padding-left: 1em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
  }
  #prospects_table a,
  #users-div-table-body a,
  #doctable .flow-table-doc a {
    margin-left: 0 !important;
    margin-bottom: 1em !important;
  }
  #prospects_table tr:first-of-type td:last-of-type,
  #users-div-table-body tr:first-of-type td:last-of-type,
  #doctable .flow-table-doc tr:first-of-type td:last-of-type {
    border: none;
  }
  #prospects_table td:last-of-type,
  #prospects_table div.div-table-cell:last-of-type,
  #users-div-table-body td:last-of-type,
  #users-div-table-body div.div-table-cell:last-of-type,
  #doctable .flow-table-doc td:last-of-type,
  #doctable .flow-table-doc div.div-table-cell:last-of-type {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }

  #prospects_table tbody td {
    height: auto;
  }

  #doctable .flow-table-doc i::after {
    content: " " attr(data-mobile-title);
    font-size: 14px;
    padding-left: 1em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  .card-style {
    width: 100% !important;
  }
  .card-style thead th:not(.select-all-checkbox) {
    display: none;
  }

  .card-style tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .card-style tbody tr {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 22px;
    background: #fff !important;
    border: none !important;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
  }
  .card-style tbody tr .hide-on-mobile {
    display: none;
  }
  .card-style tbody tr td {
    padding: 0 !important;
    flex-basis: 100%;
    margin-bottom: 5px;
  }
  .card-style tbody tr td.id-column-outter,
  .card-style tbody tr td.co-mortgagors-list-row-outter {
    padding: 0 !important;
    flex-basis: 50%;
  }
  .card-style tbody tr td.loan-name-outter {
    order: 1;
    font-size: 15px;
    font-weight: 500;
    color: #121727;
    margin-bottom: 22px;
    max-width: 90%;
  }

  .card-style tbody tr td.loan-action-wrapper-outter {
    order: 2;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  .card-style tbody tr td.loan-action-wrapper-outter a {
    float: left;
    clear: both;
    width: 200px;
    color: #797676 !important;
    margin: 0 !important;
    padding: 10px 15px;
    transition: background 0.3s ease !important;
    border-bottom: 1px solid #f3f4f5;
  }
  .card-style tbody tr td.loan-action-wrapper-outter a span {
    float: left;
  }
  .card-style tbody tr td.loan-action-wrapper-outter a .action-name {
    display: block !important;
    float: left;
  }
  .card-style tbody tr td.loan-action-wrapper-outter a i {
    float: right;
    color: #d8d8d8 !important;
    transition: color 0.3s ease;
  }
  .card-style tbody tr td.loan-action-wrapper-outter a:hover {
    background: #f3f4f5;
    color: #797676 !important;
  }
  .card-style tbody tr td.loan-action-wrapper-outter a:hover:first-child {
    border-radius: 4px 4px 0 0;
  }
  .card-style tbody tr td.loan-action-wrapper-outter a:hover:last-child {
    border-radius: 0 0 4px 4px;
  }
  .card-style tbody tr td.loan-action-wrapper-outter a:hover i {
    color: #bfbfbf !important;
  }
  .card-style tbody tr td .action-wrapper {
    box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.15);
    transform: scaleY(0);
    opacity: 0;
    transition: transform 0s ease 0.3s, opacity 0.3s ease 0s;
    display: block;
    position: absolute;
    right: -5px;
    top: 35px;
    z-index: 99;
    border-radius: 4px;
    background: #fff;
  }
  .card-style tbody tr td .action-wrapper::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 9px solid #fff;
    position: absolute;
    right: 16px;
    top: -9px;
    border-radius: 4px;
  }
  .card-style tbody tr td .action-wrapper.viz {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
    transition: transform 0s ease 0s, opacity 0.3s ease 0.1s;
    grid-template-columns: revert;
  }
  .table.table-striped tbody tr td .loan-action-wrapper > a {
    padding: 10px 10px 10px 15px;
  }
  .card-style tbody tr td .action-wrapper.viz a {
    display: block;
  }
  .card-style tbody tr td.borrower-name-outter {
    order: 3;
    float: left;
    width: 215px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-style tbody tr td.borrower-name-outter .tooltip-item {
    display: none;
  }
  .card-style tbody tr td.borrower-name-outter a,
  .card-style tbody tr td.borrower-name-outter span {
    font-size: 14px;
    color: #797676 !important;
  }
  .card-style tbody tr td.borrower-name-outter .hide-on-mobile {
    display: none;
  }
  .card-style tbody tr td.borrower-name-outter:before {
    content: "BORROWER";
    display: block;
    position: relative;
    font-size: 9px;
    color: #121727;
    margin-bottom: 3px;
  }
  .card-style tbody tr td.co-borrower-name-outter {
    order: 4;
    float: left;
    position: absolute;
    bottom: 22px;
    left: 250px;
    width: 215px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: left 0.3s ease;
  }
  .card-style tbody tr td.co-borrower-name-outter .tooltip-item {
    display: none;
  }
  .card-style tbody tr td.co-borrower-name-outter a,
  .card-style tbody tr td.co-borrower-name-outter span {
    font-size: 14px;
    color: #797676 !important;
  }
  .card-style tbody tr td.co-borrower-name-outter .hide-on-mobile {
    display: none;
  }
  .card-style tbody tr td.co-borrower-name-outter:before {
    content: "CO-BORROWER";
    display: block;
    position: relative;
    font-size: 9px;
    color: #121727;
    margin-bottom: 3px;
  }
  .card-style tbody tr td.loan-doc-status-wrapper-outter,
  .card-style tbody tr td.status-grade-outter {
    order: 5;
    position: absolute;
    right: 12px;
    bottom: 22px;
  }
  .card-style tbody tr td.loan-doc-status-wrapper-outter:before,
  .card-style tbody tr td.status-grade-outter:before {
    content: "FLOW";
    display: block;
    position: relative;
    font-size: 9px;
    color: #121727;
    margin-bottom: 3px;
  }
  .card-style tbody tr td.loan-doc-status-wrapper-outter .loan-id-icon,
  .card-style tbody tr td.status-grade-outter .loan-id-icon {
    display: none;
  }
  .card-style tbody tr td.loan-doc-status-wrapper-outter span,
  .card-style tbody tr td.status-grade-outter span {
    margin-left: 0 !important;
  }
  .card-style tbody tr td.loan-doc-status-wrapper-outter span a,
  .card-style tbody tr td.status-grade-outter span a {
    margin-right: 10px;
  }
  .card-style tbody tr td.status-grade-outter {
    margin-right: 10px;
  }
  .card-style tbody tr td.status-grade-outter:before {
    content: "GRADE";
  }
  .card-style tbody tr td.status-grade-outter span:first-child {
    float: right;
  }
  .card-style tbody tr td .action-mobile-btn {
    display: block !important;
  }

  .borrower-loan-dashboard-table-mobile tbody tr {
    margin-bottom: 30px;
  }

  .borrower-loan-dashboard-table-mobile tbody tr {
    margin-bottom: 30px;
  }
}
.applicants-counts-grid {
  display: flex;
  flex-direction: column;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.collapse-co-mortgagors.showing > .plus {
  display: none;
}
.collapse-co-mortgagors.hiding > .minus {
  display: none;
}
.collapse-co-mortgagors {
  display: inline-block;
  color: #337ab7 !important;
}
.collapse-co-mortgagors > i {
  font-size: 10px;
}
.collapse-co-mortgagors {
  font-size: 12px;
  margin-top: 3px;
}
.co-mortgagor-padding {
  font-size: 10px;
}
.has-co-mortgagors .co-mortgagor-padding {
  display: block;
}
.applicants-badges,
.co-mortgagors-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.co-mortgagors-list.hidden {
  display: none;
}
.co-mortgagors-list > .co-mortgagors-list-row:first-child {
  margin-top: 10px;
}
.co-mortgagors-list-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;
}
.co-mortgagors-list-row > i {
  font-size: 12px;
  color: #d0d5dd;
  display: inline-block;
  width: 14px;
  margin-right: 3px;
  text-align: center;
}
.has-co-mortgagors .summary-badges {
  padding-bottom: 22px;
}
.summary-badges.hidden,
.applicants-badges.hidden > div {
  display: none;
}
.loan-id-icon-spacer {
  display: inline-block;
  width: 18px;
}
.loan-info-data-property-blank {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #b6b8bb;
  white-space: nowrap;
}
.loan-info-data-loan-purpose {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #636973;
}
@media only screen and (max-width: 991px) {
  .div-table .div-table-row {
    padding: 5px 0;
  }
  .div-table .div-table-cell {
    padding: 5px 8px;
  }
  #myAdvanceModal .modal-footer .form-group {
    display: flex;
    flex-direction: column;
  }

  #myAdvanceModal .modal-footer .form-group button {
    width: 100%;
    margin: 0 0 0.5em 0;
  }

  .radio-boxes-container .radio-box,
  .radio-boxes-container label {
    max-width: 100%;
  }

  .radio-boxes-container .radio-box {
    margin-bottom: 14px;
  }
}
@media only screen and (max-width: 650px) {
  .card-style tbody tr td.borrower-name-outter {
    width: 175px;
  }
  .card-style tbody tr td.co-borrower-name-outter {
    width: 175px;
    left: 210px;
  }
}
@media only screen and (max-width: 540px) {
  .card-style tbody tr td.borrower-name-outter {
    width: 130px;
  }
  .card-style tbody tr td.co-borrower-name-outter {
    width: 130px;
    left: 160px;
  }
}
@media only screen and (max-width: 450px) {
  .card-style tbody tr td.borrower-name-outter {
    width: 100px;
  }
  .card-style tbody tr td.co-borrower-name-outter {
    width: 100px;
    left: 130px;
  }
}
.tab-pane.user-roles {
  margin-bottom: 50vh;
}
.footer {
  margin-top: 20px;
  padding: 20px 0;
  color: #777;
  border-top: 1px solid #e5e5e5;
}
.footer .cash-content {
  font-weight: 500;
}
.footer .footer-left-text .cash-content {
  margin-top: -2px;
}
.footer .cash-content .money {
  height: 30px;
  margin-top: -5px;
  /* transform: scale(0.6); */
}
.footer .cash-content .money.flying {
  animation-name: flappycash;
  animation-timing-function: ease-in-out;
  animation-duration: 2.7s;
  transform: scale(1);
}

@keyframes flappycash {
  0% {
    transform: scale(0.6);
  }
  30% {
    transform: scale(0.75);
  }
  70% {
    transform: scale(1.12);
  }
  85% {
    transform: scale(1.12);
  }
  100% {
    transform: scale(1);
  }
}

.mobile-footer-item {
  float: left;
  border-right: 1px solid rgba(176, 179, 182, 0.5);
  padding-right: 10px;
  margin-right: 10px;
}
.mobile-footer-item:last-child {
  border-right: none;
  padding-right: 0px;
  margin-right: 0px;
}
.mobile-footer-item i {
  margin-right: 5px;
}
@media only screen and (min-width: 900px) {
  .mobile-cash-content {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .footer-left-text {
    display: none;
  }

  .mobile-footer-item {
    clear: both;
    margin-bottom: 10px;
    border-right: none;
  }

  .mobile-footer-item a {
    color: #306ea6 !important;
  }
}
.floify-modal .modal-header h4 {
  margin: 15px 0;
}
.floify-modal .modal-middle {
  padding: 5px 15px 25px 15px;
}
.floify-modal .modal-middle .modal-intro {
  margin-top: 15px;
  margin-bottom: 0;
}
.floify-modal .modal-middle label {
  margin-top: 15px;
}
.floify-modal .modal-middle input:not(.no-styles) {
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  line-height: 35px;
  padding: 0 15px;
}
.floify-modal .modal-middle input.no-styles {
  border: none;
}
.floify-modal .modal-middle input:focus {
  outline: none;
}
.floify-modal .modal-middle .input-style-wrap {
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  line-height: 35px;
  padding: 0 15px;
}
.floify-modal .modal-footer {
  padding: 15px;
}
.floify-modal .modal-footer a,
.floify-modal .modal-footer button {
  margin-right: 10px;
}
.floify-modal .modal-footer a:last-child,
.floify-modal .modal-footer button:last-child {
  margin-right: 0;
}

.floify-dropdown {
  position: relative;
}
.floify-dropdown .floify-dropdown-selected {
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  line-height: 35px;
  padding: 0 15px;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}
.floify-dropdown .floify-dropdown-selected.placeholder {
  color: #d8d8d8;
}
.floify-dropdown .floify-dropdown-selected:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #626974;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.floify-dropdown .floify-dropdown-selected.viz {
  box-shadow: 0px 0px 5px #337ab7;
}
.floify-dropdown .floify-dropdown-options {
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0px 2px 10px #626974;
  position: absolute;
  width: 100%;
  border-radius: 3px;
  overflow-y: scroll;
  transition: opacity 0.3s ease 0s, height 0.3s ease 0s, padding 0s ease 0.3s,
    margin 0s ease 0.3s;
  z-index: 9;
}
.floify-dropdown .floify-dropdown-options.viz {
  opacity: 1;
  height: 150px;
  padding: 15px 0;
  margin-top: 5px;
  transition: opacity 0.3s ease 0.1s, height 0.3s ease 0.1s, padding 0s ease 0s,
    margin 0s ease 0s;
}
.floify-dropdown .floify-dropdown-options .floify-dropdown-option {
  cursor: pointer;
  line-height: 24px;
  padding: 0 15px;
  width: 100%;
}
.floify-dropdown .floify-dropdown-options .floify-dropdown-option:hover {
  background: #e3e3e3;
}

.other-dropdown label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.other-dropdown .other-switcher {
  font-size: 11px;
  color: #286090;
  font-weight: 600;
  cursor: pointer;
}

.other-dropdown .other-switcher:hover {
  text-decoration: underline;
}

.IE-11-error-container {
  width: 372px;
  margin-left: auto;
  margin-right: auto;
}

.IE-11-error-desktop {
  background-color: #ffffff;
}

.IE-11-error-mask {
  object-fit: contain;
  margin-top: 50px;
  position: relative;
}

.IE-11-error-desktop-mask-img {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
}

.IE-11-error-ie-favicon {
  position: absolute;
  width: 150px;
  height: 150px;
  z-index: 1;
  margin: 40px 111px;
  top: 0px;
  left: 0px;
}

.IE-11-error-uh-oh {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  color: #121727;
  margin-top: 30px;
}

.IE-11-error-message {
  margin-top: 19px;
  color: #626974;
  text-align: center;
  font-size: 15px;
  line-height: 1.4;
}

.IE-11-continue-message {
  margin-top: 21px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #626974;
}

.IE-11-error-downloads {
  width: 100%;
}

.IE-11-error-chrome-download {
  margin-top: 21px;
  height: 45px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(228, 228, 228, 0.5);
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
}

.IE-11-error-download-favicon {
  width: 25px;
  height: 25px;
  margin: 10px 20px;
}

.IE-11-error-firefox-download {
  margin-top: 15px;
  height: 45px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(228, 228, 228, 0.5);
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
}

.flex {
  display: flex;
}
.flex.flex-grow {
  flex-grow: 1;
}
.flex.justify-around {
  justify-content: space-around;
}
.flex.justify-between {
  justify-content: space-between;
}
.flex.align-self-center {
  align-self: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.form-char-limit input {
  border: none;
  margin-right: 1em;
}
.form-char-limit .char-limit {
  color: #b7b7b7;
}

.form-char-limit:focus-within {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
    0 0 8px rgb(102 175 233 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
.form-char-limit input:focus {
  outline: none;
}

.limit-reqs {
  margin-left: 1em;
  color: #ff6e6d;
  margin-bottom: 0;
  white-space: nowrap;
}

.ellipsis-menu .ellipsis-toggle {
  display: none;
  cursor: pointer;
}
.ellipsis-menu .ellipsis-links-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.ellipsis-menu .ellipsis-links-wrap .link-wrap {
  /*Leaving for reference 7-6-22*/
  /* height: 20px;
  margin-bottom: 10px; */
}
.ellipsis-menu .ellipsis-links-wrap .link-wrap button.view-doc-button {
  margin-bottom: 0;
}
.ellipsis-menu .ellipsis-links-wrap .link-wrap .link-action-desc {
  display: none;
}
.ellipsis-menu .ellipsis-links-wrap .link-wrap i.disabled {
  color: gray;
}
.ellipsis-menu .ellipsis-links-wrap .link-wrap i.disabled:hover {
  cursor: not-allowed;
}

@media only screen and (max-width: 900px) {
  .ellipsis-menu .ellipsis-toggle {
    display: block;
    width: 46px;
    height: 20px;
    position: relative;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .ellipsis-menu .ellipsis-toggle .fa-times,
  .ellipsis-menu .ellipsis-toggle .fa-ellipsis-h {
    font-size: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
  }
  .ellipsis-menu .ellipsis-toggle .fa-times {
    opacity: 0;
  }
  .ellipsis-menu .ellipsis-toggle .fa-ellipsis-h {
    opacity: 1;
  }
  .ellipsis-menu .ellipsis-links-wrap {
    display: block;
    position: absolute;
    background: #fff;
    box-shadow: 0px 0px 8px rgba(18, 23, 39, 0.35);
    border-radius: 4px;
    width: 0px;
    right: 0;
    top: 32px;
    opacity: 0;
    transition: max-height 0.3s ease 0s, width 0s ease 0.4s,
      opacity 0.3s ease 0s;
    z-index: 99;
  }
  .ellipsis-menu .ellipsis-links-wrap:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 9px solid #fff;
    position: absolute;
    right: 15px;
    top: -9px;
  }
  .ellipsis-menu .ellipsis-links-wrap .link-wrap {
    padding: 10px 10px;
    height: initial;
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 0;
    display: none;
    position: relative;
    border-bottom: 1px solid #f3f4f5;
    transition: background 0.3s ease;
    cursor: pointer;
  }
  .ellipsis-menu .ellipsis-links-wrap .link-wrap:last-child {
    border-bottom: 0px;
  }
  .ellipsis-menu .ellipsis-links-wrap .link-wrap:hover {
    background: #d8d8d8;
  }
  .ellipsis-menu .ellipsis-links-wrap .link-wrap:hover i {
    color: #b0b3b6;
  }
  .ellipsis-menu .ellipsis-links-wrap .link-wrap a,
  .ellipsis-menu .ellipsis-links-wrap .link-wrap form,
  .ellipsis-menu .ellipsis-links-wrap .link-wrap button {
    margin: 0 !important;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .ellipsis-menu .ellipsis-links-wrap .link-wrap a span,
  .ellipsis-menu .ellipsis-links-wrap .link-wrap form span,
  .ellipsis-menu .ellipsis-links-wrap .link-wrap button span {
    width: 100%;
    height: 100%;
    display: block;
  }
  .ellipsis-menu .ellipsis-links-wrap .link-wrap a span button,
  .ellipsis-menu .ellipsis-links-wrap .link-wrap form span button,
  .ellipsis-menu .ellipsis-links-wrap .link-wrap button span button {
    display: block;
    width: 100%;
    height: 100%;
  }
  .ellipsis-menu .ellipsis-links-wrap .link-wrap i {
    color: #d8d8d8;
    transition: color 0.3s ease;
    position: absolute;
    right: 0;
  }
  .ellipsis-menu .ellipsis-links-wrap .link-wrap .link-action-desc {
    width: calc(100% - 30px);
    color: #797676;
    position: relative;
    white-space: nowrap;
    width: calc(100% - 37px);
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    text-align: left;
  }
  .ellipsis-menu.viz .ellipsis-links-wrap {
    width: 250px;
    opacity: 1;
    transition: max-height 0.3s ease 0.1s, width 0s ease 0s,
      opacity 0.3s ease 0.1s;
  }
  .ellipsis-menu.viz .ellipsis-links-wrap .link-wrap {
    display: block;
  }
  .ellipsis-menu.viz .ellipsis-links-wrap .link-wrap .link-action-desc {
    display: block;
  }
}
.pageBlocker {
  position: fixed;
  width: 0;
  height: 0;
  left: 0;
  top: 90px;
  text-align: center;
  z-index: 998;
  overflow: hidden;
  background: #fff;
  box-shadow: inset 0 9px 10px -10px rgba(18, 23, 39, 0.5);
}
.pageBlocker.viz {
  width: 100%;
  height: 100%;
}
.pageBlocker #pageBlockerContentWrap {
  top: 30px;
  position: relative;
}
.pageBlocker #pageBlockerImgWrap {
  position: relative;
}
.pageBlocker #pageBlockerImgWrap img {
  display: none;
}
.pageBlocker #pageBlockerImgWrap img.viz {
  display: block;
  margin: 0 auto;
}
.pageBlocker #pageBlockerMessage {
  text-align: center;
  width: 100%;
  color: #333333;
  background: #e6eaf0;
  margin-top: 30px;
  padding: 15px 0;
  font-size: 15px;
  line-height: 24px;
}
.pageBlocker .btn {
  margin-top: 1em;
}

@media only screen and (max-width: 900px) {
  .pageBlocker {
    top: 48px;
  }
}
.landing-page-company-name {
  margin-top: 12px !important;
}

label.disabled {
  color: #b9b9b9;
}

.edit-license-btn-wrap {
  margin-top: 20px;
}

.controls-row {
  padding-bottom: 10px;
}

.new-loan-wrapper .btn {
  float: left;
}
.new-loan-wrapper a {
  float: left;
}
.new-loan-wrapper #arrowBouncing {
  display: none;
  position: absolute;
  margin-left: 5px;
  float: left;
  color: #626974;
}
.new-loan-wrapper .btn-warning {
  margin-left: 22px;
}

#filter-group-wrapper {
  float: right;
}

#pending-alert-wrapper #pending-review-wrapper {
  cursor: pointer;
}
#pending-alert-wrapper.ring .bell-icon i {
  animation-name: ring;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 0.75s;
}

.text-center .pending-review-wrapper {
  height: 40px;
  margin: auto;
  border: 1px solid #f0ad4e;
  border-radius: 4px;
  overflow: hidden;
  float: left;
}
.text-center .pending-review-wrapper .bell-icon {
  width: 40px;
  height: 40px;
  background: #f0ad4e;
  color: #fff;
  line-height: 38px;
  float: left;
  font-size: 20px;
}
.text-center .pending-review-wrapper .alert-badge {
  line-height: 40px;
  color: #797676;
  margin-left: 15px;
  margin-right: 15px;
}

.form-group .question-icon,
.form-group #modalDesktopNotifsAnchor {
  line-height: 40px;
  float: right;
}
.form-group .question-icon i,
.form-group #modalDesktopNotifsAnchor i {
  color: #797676;
  font-size: 16px;
}
.form-group .loanFlowFilter-wrapper {
  width: 70%;
}
.form-group #loanFlowFilter-label {
  color: #d8d8d8;
  top: -22px;
  font-weight: 400;
}
.form-group #loanFlowFilter {
  height: 40px;
  color: #626974;
  box-shadow: none;
}

#manager-index-tabs {
  border-bottom: none;
}
#manager-index-tabs li a {
  font-size: 16px;
  padding: 0 15px 24px;
  color: #626974;
  transition: color 0.3s ease;
  border: none;
  outline: none;
}
#manager-index-tabs li a:hover,
#manager-index-tabs li a:active {
  border: none;
  outline: none;
  background: transparent;
  color: #337ab7;
}
#manager-index-tabs li.active a {
  border: none;
  color: #337ab7;
  background: transparent;
  cursor: pointer;
}

#tab-highlight-wrapper {
  width: 100%;
  height: 4px;
  background: #d8d8d8;
  border-radius: 9px;
}

.invite-borrowers.sweet-alert .sa-icon {
  float: left;
  width: 30px;
  height: 30px;
}

.icon-circle {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-swal-icon {
  position: absolute;
  left: -75px;
  font-size: 25px;
}

.custom-swal-icon.exclamation {
  background: #ededed;
}
.custom-swal-icon.check {
  background: #e8f5e8;
}

.custom-swal-icon .check {
  color: #4cae4c;
}
.invite-borrowers.sweet-alert {
  text-align: left;
  width: 490px;
  padding-right: 32px;
  padding-bottom: 60px;
}

.invite-borrowers .text-limit {
  margin-top: 21px;
}

.invite-borrowers .invite-btn,
.invite-borrowers .cancel,
.invite-borrowers .confirm {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.invite-borrowers h2,
.invite-borrowers .lead {
  float: right;
  width: 80%;
  justify-content: left;
}

.invite-borrowers .sa-button-container {
  float: right;
  width: 80%;
  justify-content: flex-end;
}

.invite-borrowers h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 22px;
}

.invite-borrowers h3 {
  font-size: 15px;
  color: #333;
  font-weight: 500;
}

.invite-borrowers .needs-list-swal {
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 100px;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
}

textarea.needs-list-swal {
  resize: vertical;
}

.invite-borrowers .sa-button-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
  right: 0px;
  height: 67px;
  display: flex;
  align-items: center;
  padding: 16px 30px 16px 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  background-color: #f5f5f5 !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.invite-borrowers .help,
.invite-borrowers .text-limit {
  font-size: 12px;
}

.invite-borrowers .cancel {
  height: 34px;
}

#tab-highlight {
  width: 100px;
  height: 4px;
  background: #337ab7;
  border-radius: 9px;
  transition: left 0.8s cubic-bezier(0.77, 0, 0.175, 1),
    width 0.8s cubic-bezier(0.77, 0, 0.175, 1);
}

.mobile-filer-btn {
  display: none;
  font-size: 21px;
  position: absolute;
  right: 16px;
  top: 28px;
  color: #337ab7;
  color: #626974;
}
.mobile-filer-btn.active {
  color: #337ab7;
}

.mobileLoanFlowFilter-wrapper {
  display: none;
  padding: 0 15px;
  position: relative;
  transition: margin-bottom 0.3s ease;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: height 0.3s ease 0.2s, opacity 0.3s ease 0s,
    margin-bottom 0.3s ease 0.2s;
}
.mobileLoanFlowFilter-wrapper .mobileLoanFlowFilter-label {
  font-size: 14px;
  color: #797676;
  font-weight: 400;
}
.mobileLoanFlowFilter-wrapper.viz {
  margin-bottom: 15px;
  height: 70px;
  opacity: 1;
  transition: height 0.3s ease 0s, opacity 0.3s ease 0.2s,
    margin-bottom 0.3s ease 0s;
}
.loan-dashboard-search-box {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 33.33333%;
  height: 40px;
}
.loan-dashboard-search-box .dataTables_filter {
  width: 70%;
  height: 100%;
  float: right;
  padding-left: 15px;
}
.loan-dashboard-search-box .dataTables_filter label,
.loan-dashboard-search-box .dataTables_filter input {
  width: 100%;
  height: 100%;
}
.loan-dashboard-search-box .dataTables_filter label {
  position: relative;
}
.loan-dashboard-search-box .dataTables_filter label:before {
  content: "\f002";
  font-family: "Font Awesome 6 Pro";
  left: 10px;
  color: #d8d8d8;
}
.loan-dashboard-search-box .dataTables_filter input {
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d8d8d8;
  font-size: 14px;
  font-weight: 400;
  color: #626974;
  padding-left: 33px;
}
.loan-dashboard-search-box .dataTables_filter input::placeholder {
  opacity: 0.5;
}
@media only screen and (max-width: 900px) {
  #docs-awaiting-signature-filter-target.loan-dashboard-search-box .dataTables_filter{
    width: 100%;
    float: none;
    padding-left: 0;
  }
  #docs-awaiting-signature-filter-target.loan-dashboard-search-box {
    width: calc(100% - 66px);
    margin-right: 35px;
  }
}
@media only screen and (max-width: 1250px) {
  .new-loan-wrapper #arrowBouncing {
    display: none;
  }
  .new-loan-wrapper #requestDemoBtn {
    margin-left: 12px;
    position: absolute;
  }
}
@media only screen and (max-width: 940px) {
  .new-loan-wrapper #arrowBouncing {
    display: none;
  }
  .new-loan-wrapper #requestDemoBtn {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .controls-row {
    margin-bottom: 10px;
  }

  .new-loan-wrapper .btn-default {
    font-size: 12px;
    padding: 0 10px;
  }
  .new-loan-wrapper .btn-default i {
    margin-right: 5px;
  }
  .new-loan-wrapper .hide-on-mobile {
    display: none;
  }

  .mobile-filer-btn {
    display: none;
  }

  .mobileLoanFlowFilter-wrapper {
    display: block;
  }

  #start-new-wrapper {
    float: right;
    padding: 0;
  }
  #start-new-wrapper a {
    float: none;
    width: 100%;
  }

  #pending-alert-wrapper {
    display: none;
  }

  #filter-group-wrapper {
    display: none;
  }

  #navigation-tab-wrapper {
    margin-left: -30px;
    margin-right: -30px;
    overflow-x: scroll;
    white-space: nowrap;
  }

  #manager-index-tabs li {
    float: none;
    display: inline-block;
  }
  #manager-index-tabs li a {
    padding-bottom: 10px;
  }

  #tab-highlight-wrapper {
    width: calc(100% + 60px);
    overflow: hidden;
  }

  #my-tab-content {
    background: #f3f4f5;
    padding-top: 1px;
  }

  #active-filter-target,
  #conditions-pending-filter-target,
  #docs-pending-filter-target,
  #archived-filter-target {
    float: none !important;
    left: 30px;
    width: calc(100% - 66px);
    position: relative;
    margin-bottom: 10px;
  }
  #waiting-docs-filter-target .dataTables_filter,
  #open-docs-filter-target .dataTables_filter,
  #closed-docs-filter-target .dataTables_filter {
    width: 100%;
    padding-left: 0;
  }
  #waiting-docs-filter-target,
  #open-docs-filter-target,
  #closed-docs-filter-target {
    float: none !important;
    left: 15px;
    width: calc(100% - 30px);
    position: relative;
    margin-bottom: 10px;
  }
  #active-filter-target .dataTables_filter,
  #conditions-pending-filter-target .dataTables_filter,
  #docs-pending-filter-target .dataTables_filter,
  #archived-filter-target .dataTables_filter {
    float: none;
    padding-left: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  #start-new-wrapper {
    width: 100%;
  }
}
#users-div-table-head {
  margin-top: 40px;
}

@media only screen and (max-width: 900px) {
  #users-bs-callout {
    float: left;
    clear: both;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .user-count {
    float: left;
    clear: both;
    margin-bottom: 10px;
  }

  .users-top-btn {
    float: left !important;
    clear: both;
    margin-bottom: 10px;
    margin-left: 0;
  }

  #users-div-table-head {
    display: none;
    clear: both;
  }

  #users-div-table-body {
    margin-top: 40px;
    clear: both;
    margin-top: 20px;
    float: left;
    width: 100%;
  }
}
.flow-outter-wrap {
  position: relative;
}

.loan-flow-status-label {
  margin-right: 4px;
  position: relative;
  display: inline-block;
}

#allMilestones {
  display: inline-block;
  position: relative;
  top: 3px;
}

.milestone-btn {
  color: #121727;
  background: #fff;
  border: 1px solid #d8d8d8;
  padding: 6px 6px;
  line-height: 1.4;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 3px;
  float: left;
}
.milestone-btn .milestone-icon-wrapper {
  width: 20px;
}

.borrower-contact-info-wrapper {
  position: absolute;
  display: inline-block;
  float: none !important;
  right: 0;
  margin-right: 8px;
}

@media screen and (max-width: 1200px) {
  .borrower-contact-info-wrapper {
    display: flex;
    flex-direction: column;
  }

  .lender-name-label {
    display: flex;
    align-self: flex-end;
  }

  .borrower-contact-info-wrapper #lender-logged-in {
    position: absolute;
    top: 1.2em;
  }
}
.voa-button-links {
  margin-top: 1em;
}

.custom-button {
  cursor: pointer;
}

button#cancelVOALinkToBorrower {
  margin-right: 6px;
}

.plaid-account-name {
  border-top: 1px solid rgba(216, 216, 216, 0.7);
  border-bottom: 1px solid rgba(216, 216, 216, 0.7);
  padding: 15px 8px;
  display: flex;
  justify-content: space-between;
}
.plaid-account-name .remove-button {
  cursor: pointer;
  color: #337ab7;
  font-size: 140%;
}

div.plaid-account-name:nth-of-type(odd) {
  background-color: #f9f9f9;
}

#plaidAccountsList {
  margin: 1em 0;
}

#plaidAddAccount {
  margin-top: 1em;
}

#milestone-wrap {
  clear: both;
}
#milestone-wrap .flow-outter-wrap {
  padding: 12px;
}

#flow-mid-nav-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

#flow-nav-pills {
  /*width: 100%;*/
  order: 1;
  margin-top: -4px;
  display: inline-flex;
  align-items: center;
}

#flow-nav-pills > li {
  margin-right: 15px;
}

#flow-nav-pills > li.conditions > a {
  background-color: #dadce3;
  color: #555;
}

#flow-nav-pills > li.conditions.active > a {
  border: 2px solid #777;
}

#flow-nav-pills > li.owed > a {
  background-color: #f7c1b5;
  color: #7f1d1d;
}

#flow-nav-pills > li.owed.active > a {
  border: 2px solid #7f1d1d;
}

#flow-nav-pills > li.pending > a {
  background-color: #f9e1a9;
  color: #8e580b;
}

#flow-nav-pills > li.pending.active > a {
  border: 2px solid #8e580b;
}

#flow-nav-pills > li.accepted > a {
  background-color: #c4ecbb;
  color: #215c10;
}

#flow-nav-pills > li.accepted.active > a {
  border: 2px solid #215c10;
}
/*#flow-nav-pills li.owed:not(.active) {*/
/*  margin-left: -10px;*/
/*}*/

#flow-tabs-right-nav {
  order: 2;
  max-width: 50%;
  margin-top: 2px;
  display: flex;
  align-items: center;
}

#flow-table-tabs {
  clear: both;
}

.lender-name-wrapper {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  position: relative;
  top: 4px;
}

.lender-name-label {
  top: -2px;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .borrower-contact-info-wrapper {
    display: flex;
    flex-direction: column;
  }

  .borrower-contact-info-wrapper .lender-name-label {
    display: flex;
    align-self: flex-end;
  }

  .borrower-contact-info-wrapper .lender-name-wrapper {
    max-width: 150px;
  }
}

.nav-pills-style.v-next a {
  padding-bottom: 7px !important;
  padding-top: 7px !important;
}

#flow-tabs-right-nav.v-next .add-new-doc-btn-group {
  display: flex;
  margin-left: 8px;
  white-space: nowrap;
}

#flow-tabs-right-nav.v-next .borrower-pair-context-btn-groups {
  display: flex;
  margin-left: 8px;
  width: 100%;
}

#flow-tabs-right-nav.v-next .como-dropdown-menu {
  padding: 0px 0px 6px 0px;
  text-align: left;
}

#flow-tabs-right-nav.v-next .como-dropdown-menu__heading {
  color: #ADADAD;
  padding: 20px 24px 8px 24px;
  white-space: nowrap;
}

#flow-tabs-right-nav.v-next .como-dropdown-menu .borrower-pair-item {
  color: #555;
  padding: 10px 24px;
}

#flow-tabs-right-nav.v-next .como-dropdown-menu .borrower-pair-item i {
  color: #D3D3D3;
  margin-right: 8px;
  width: 16px;
}

@media only screen and (max-width: 1200px) {
  :root {
    --dropdown-toggle-mobile-width: 49px;
    --anchor-margin-bottom: 1em;
  }
  
  #flow-audit-log-btn-wrap {
    position: relative;
    right: 15px;
  }
  #flow-audit-log-btn-wrap #flow-audit-log-btn {
    height: 30px;
    line-height: 29px;
  }

  #flow-mid-nav-wrap {
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 0px;
  }

  #flow-nav-pills {
    order: 2;
    margin-bottom: 20px;
  }

  #flow-tabs-right-nav {
    order: 1;
    margin-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  #flow-tabs-right-nav a:not(.dropdown-item) {
    position: relative;
    height: 30px;
    display: block;
    line-height: 29px;
    float: left !important;
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: var(--anchor-margin-bottom) !important;
  }
  #flow-tabs-right-nav i::after {
    content: " " attr(data-mobile-title);
    font-size: 14px;
    padding-left: 1em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    color: inherit;
  }
  #flow-tabs-right-nav .fa-fw {
    width: initial;
  }
  #flow-tabs-right-nav .dropdown {
    width: 100%;
  }
  #flow-tabs-right-nav .dropdown-menu {
    top: calc(100% - var(--anchor-margin-bottom) + 6px);
    text-align: center;
  }
  #flow-tabs-right-nav .state-forms-dropdown {
    left: 0;
  }

  #flow-tabs-right-nav.v-next a {
    height: auto;
  }
  #flow-tabs-right-nav.v-next .add-new-doc-btn-group {
    margin-left: 0;
    width: 100%;
  }
  #flow-tabs-right-nav.v-next .borrower-pair-context-btn-groups {
    margin-left: 0;
  }
  #flow-tabs-right-nav.v-next .borrower-pair-context-btn-groups button {
    height: 43px;
  }
  #flow-tabs-right-nav.v-next .borrower-pair-context-btn-groups .flex-fill {
    flex: 1;
  }
  #flow-tabs-right-nav.v-next .borrower-pair-context-btn-groups .flex-fill button {
    width: 100%;
  }
  #flow-tabs-right-nav.v-next .btn-group .add-new-doc-button, 
  #flow-tabs-right-nav.v-next .borrower-pair-context-btn-groups .flex-fill button {
    /* keep button text centered */
    padding-left: calc(12px + var(--dropdown-toggle-mobile-width));
  }
  #flow-tabs-right-nav.v-next .btn-group .dropdown-toggle {
    width: var(--dropdown-toggle-mobile-width);
    margin-bottom: var(--anchor-margin-bottom);
  }
  #flow-tabs-right-nav.v-next .btn-group .dropdown-toggle i {
    font-size: 14px;
    width: 14px;
  }
  #flow-tabs-right-nav.v-next .dropdown-menu a {
    margin-bottom: 0 !important;
  }
  #flow-tabs-right-nav.v-next .como-dropdown-menu {
    font-size: 16px;
    text-align: left;
  }
  #flow-tabs-right-nav.v-next .como-dropdown-menu .borrower-pair-item i {
    width: 20px;
  }
  #flow-tabs-right-nav.v-next .completed-docs-menu {
    left: 0;
  }
  .state-forms {
    margin-left: 0;
  }
}

@media only screen and (max-width: 900px) {
  .lender-name-wrapper {
    max-width: 100%;
  }

  #zestimatespan {
    display: none;
  }

  #milestone-wrap .flow-outter-wrap {
    margin-bottom: 20px;
  }
  #milestone-wrap .loan-flow-status-label {
    float: left;
    clear: both;
    top: initial;
    transform: initial;
    margin-bottom: 20px;
  }
  #milestone-wrap #allMilestones {
    float: left;
    clear: both;
    top: initial;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  #milestone-wrap #allMilestones .milestone-btn {
    margin-bottom: 10px;
  }
  #milestone-wrap .borrower-contact-info-wrapper {
    max-width: 100%;
    float: right !important;
    clear: both;
    right: initial;
    top: initial;
    position: relative;
    transform: initial;
  }
}
@media only screen and (max-width: 600px) {
  #flow-audit-log-btn-wrap {
    position: relative;
    right: initial;
    padding-bottom: 10px !important;
  }
  #flow-audit-log-btn-wrap #flow-audit-log-btn {
    float: left !important;
  }

  #flow-tabs-right-nav {
    margin-bottom: 10px;
  }


  #flow-nav-pills {
    display: flex;
    flex-direction: column;
  }
  #flow-nav-pills li a {
    padding: 10px 5px;
    margin: 5px 0;
  }
}
.flow-table {
  padding: 20px 0;
}
.flow-table #flow-table-header {
  border-bottom: 1px solid #d8d8d8;
  display: flex;
}
.flow-table #flow-table-header .column-heading {
  color: #797676;
  font-weight: 500;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.flow-table .flow-table-row {
  border-bottom: 1px solid #d8d8d8;
  padding: 15px 0 5px 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.flow-table .flow-table-row .flow-table-inner-row {
  display: flex;
  width: 100%;
}
#nodocshere.flow-table-row {
  justify-content: center;
}
.flow-table .flow-table-row.color {
  background: #f9fafb;
}
.flow-table .flow-table-row .flow-table-actions .link-wrap {
  margin-right: 5px;
}
.flow-table .flow-table-row .flow-table-doc,
.flow-table .flow-table-row .flow-table-status,
.flow-table .flow-table-row .flow-table-name,
.flow-table .flow-table-row .flow-table-desc,
.flow-table .flow-table-row .flow-table-borrower-set,
.flow-table .flow-table-row .flow-table-actions {
  padding-right: 0px;
}
.flow-table .select-all-checkbox-container,
.flow-table .flow-table-row .flow-table-select {
  width: 20px;
}

@media only screen and (max-width: 900px) {
  .flow-table .flow-table-row {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
  }
  .documents-table-row {
    display: flex;
    flex-direction: column;
  }
  .flow-table .flow-table-row .flow-table-cell {
    width: 100%;
    margin-bottom: 10px;
  }
  .flow-table .flow-table-row .flow-table-name {
    order: 1;
    font-size: 17px;
    width: calc(100% - 30px);
  }
  .flow-table .flow-table-row .flow-table-desc {
    order: 3;
  }
  .flow-table .flow-table-row .flow-table-status {
    order: 2;
    color: #b0b3b6;
  }
  .flow-table .flow-table-row .flow-table-actions {
    position: absolute;
    float: right;
    right: 0;
    width: initial;
  }
  .flow-table .flow-table-row .flow-table-doc {
    order: 4;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}

.styled-radio-group input {
  margin-right: 5px;
}

.styled-radio-group label {
  display: block;
  font-weight: 400;
}

.styled-radio-group label:hover {
  cursor: pointer;
}

.styled-radio-group {
  padding-top: 7px;
}

#disclosure-signature-method .radio-group input {
  margin-right: 5px;
}

#disclosure-signature-method .radio-group div {
  margin-bottom: 5px;
}
#settings-license-section-wrapper,
#settings-notifications-section-wrapper {
  margin-top: 40px;
}

#settings-license-section #license-inner-wrapper {
  margin-bottom: 12px;
}

.edit-license-page-btn {
  display: none;
}
.edit-license-page-btn.viz {
  display: inline-block;
}

#addLicenseInputWrap {
  display: flex;
}

#addLicenseInputPrefix {
  float: left;
  padding-right: 10px;
  line-height: 37px;
  opacity: 0.5;
}

#addLicenseId {
  float: left;
  line-height: 35px;
  flex: 1;
}

.styled-radio-group .disabled {
  background: none;
  opacity: 0.4;
}

#addLicensePrefixSelectorWrap {
  display: none;
  padding: 0 25px 0 5px;
  position: relative;
}
#addLicensePrefixSelectorWrap:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #626974;
  position: absolute;
  right: 0;
  top: 13px;
}
#addLicensePrefixSelectorWrap.viz {
  display: block;
}

#addLicensePrefixSelector {
  position: absolute;
  min-width: 100%;
  margin-left: -5px;
  padding: 0;
  background: #fff;
  border-radius: 4px;
  top: 42px;
  box-shadow: 0px 2px 10px #626974;
  height: 0;
  transition: height 0.3s ease, padding 0.3s ease;
  overflow: hidden;
}
#addLicensePrefixSelector.viz {
  height: 60px;
  padding: 5px 0px;
}
#addLicensePrefixSelector .prefix-option {
  line-height: 24px;
  cursor: pointer;
  padding: 0 10px;
}
#addLicensePrefixSelector .prefix-option:hover {
  background: #e3e3e3;
}

#licenseErrorMsg {
  background: rgba(217, 83, 80, 0.25);
  color: #d5413e;
  width: 100%;
  border-radius: 3px;
  padding: 0 8px;
  margin: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}
#licenseErrorMsg.viz {
  height: 40px;
  opacity: 1;
  margin-top: 10px;
  padding: 10px 8px;
}

.edit-email-form .email-input-field,
.coborrower-separate-email .email-input-field {
  width: 31%;
  display: inline-block;
  vertical-align: top;
}

.edit-email-form label {
  display: block;
}
.invalid-email-notice {
  color: #cd1700;
}
.editEmail {
  margin-left: 7px;
}
.editMode,
.bo-edit-email-button-wrapper,
.cobo-edit-email-button-wrapper {
  display: inline-block;
  margin-left: 7px;
}

.coborrower-separate-email {
  margin-top: 5px;
}

.confirmUse {
  padding-left: 15px;
}

/*
  Floify Encompass Mismatch Styles
*/
.mismatch-tooltip {
  display: none;
  color: #fe3838;
  margin-left: 5px;
}

.floify-mismatch .mismatch-tooltip {
  display: inline-block;
}

.floify-mismatch input {
  border: 1px solid #fe3838;
}

#cobo-email-section .radio-group {
  display: flex;
}

#cobo-email-section .cobo-radio-buttons {
  display: flex;
  flex-direction: column;
}

#cobo-email-section .floify-mismatch #coBorrowerMail {
  border: 1px solid #ccc;
}

.cobo-wrapper.floify-mismatch {
  border: 1px solid #d5413e;
  border-radius: 4px;
  padding: 8px;
  margin: 0 0 0 -8px;
  width: 77%;
}

#cobo-email-section .radio-group.floify-mismatch {
  border: 1px solid #fe3838;
  width: 31%;
  margin: 0 -8px 0 0;
  padding: 8px;
  border-radius: 4px;
}

#floify-mismatch-error-top a {
  color: #a94442;
}

#floify-mismatch-error-top a:hover {
  color: #a94442;
  text-decoration: underline;
}

#cobo-email-section .floify-mismatch .mismatch-tooltip {
  position: absolute;
  left: 32%;
  top: 0;
}

#modalAuditLog .loan-dashboard-search-box .dataTables_filter label:before {
  top: 110% !important;
}

@media screen and (max-width: 991px) {
  #cobo-email-section .radio-group.floify-mismatch {
    width: 50%;
  }
  #cobo-email-section .floify-mismatch .mismatch-tooltip {
    left: 50%;
  }
}

@media screen and (max-width: 767px) {
  #modalEditLoanBorrowerSendNeedsList::after,
  #modalEditLoanBorrowerTextNeedsList::after,
  #modalEditLoanBorrowerMail::after,
  #modalEditLoanBorrowerText::after,
  #modalEditLoanBorrowerForgotPassword::after,
  #modalEditLoanBorrowerForgotPasswordText::after,
  #modalEditLoanCoBorrowerTextNeedsList::after,
  #modalEditLoanCoBorrowerText::after,
  #modalEditLoanCoBorrowerTextNeedsList::after,
  #modalEditLoanCoBorrowerForgotPasswordText::after {
    content: attr(data-after-title);
  }

  .mobile-icons-list {
    display: flex;
    flex-direction: column;
  }

  #borrower-nickname-input {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  div#cobo-nickname-input {
    width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  div#cobo-email-input {
    width: 100% !important;
  }

  .edit-email-form .email-input-field,
  .coborrower-separate-email .email-input-field {
    width: 100%;
  }

  .confirmUse {
    padding-left: 0px;
  }

  .yesUse,
  .yesShareEmail {
    margin-bottom: 10px;
  }
  #coborrowerSection .radio-row label {
    display: inline;
  }

  #coborrowerSection .radio-group {
    margin-bottom: 15px;
  }

  /*Edit Loan Flow Mobile*/

  .editEmail,
  .editMode,
  .editMode,
  .bo-edit-email-button-wrapper,
  .cobo-edit-email-button-wrapper {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media screen and (max-width: 650px) {
  #cobo-email-section .radio-group.floify-mismatch {
    width: 90%;
  }
  #cobo-email-section .floify-mismatch .mismatch-tooltip {
    left: 87%;
  }
}

#modalAssignSupportUsers .modal-dialog {
  width: 900px;
}
#modalAssignSupportUsers h4 {
  font-size: 18px;
  font-weight: 400;
  margin: 15px 0;
  color: #121727;
}
#modalAssignSupportUsers .subHeading {
  font-size: 14px;
  color: #333333;
  margin-bottom: 20px;
}
#modalAssignSupportUsers .bold-subheading {
  font-size: 14px;
  font-weight: 500;
  color: #121727;
  margin: 0 0 12px 0;
}
#modalAssignSupportUsers .input-wrapper,
#modalPartnerEdit .input-wrapper,
#myAddEditRealtorModal .input-wrapper,
#editForm .input-wrapper {
  margin-bottom: 20px;
}
#modalAssignSupportUsers .input-wrapper label,
#modalPartnerEdit .input-wrapper label,
#myAddEditRealtorModal .input-wrapper label #editForm .input-wrapper label {
  font-size: 14px;
  color: #121727;
}
#modalAssignSupportUsers .input-wrapper .name-display,
#modalPartnerEdit .input-wrapper .name-display,
#myAddEditRealtorModal .input-wrapper .name-display,
#editForm .input-wrapper .name-display,
#newManager .name-display,
#newManager .team-display {
  border: 1px solid #d8d8d8;
  padding: 0 6px;
  border-radius: 4px;
  min-height: 42px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
#modalAssignSupportUsers .input-wrapper .name-display input,
#modalPartnerEdit .input-wrapper .name-display input,
#myAddEditRealtorModal .input-wrapper .name-display input,
#editForm .input-wrapper .name-display input,
#newManager .name-display input,
#newManager .team-display input {
  border: none;
  box-shadow: none;
  width: auto;
  height: 40px;
  flex-basis: 100px;
  flex: 1;
  background: transparent;
}
#modalAssignSupportUsers .form-check,
#modalPartnerEdit .form-check,
#myAddEditRealtorModal .form-check,
#editForm .form-check {
  margin-bottom: 12px;
}
#modalAssignSupportUsers .form-check input,
#modalPartnerEdit .form-check input,
#myAddEditRealtorModal .form-check input,
#editForm .form-check input {
  float: left;
}
#modalAssignSupportUsers .form-check p,
#modalPartnerEdit .form-check p,
#myAddEditRealtorModal .form-check p,
#editForm .form-check p {
  float: left;
  font-size: 14px;
  color: #333333;
  margin-left: 12px;
}
#hidden-username-input,
#hidden-teamname-input,
#hidden-licensedStates-input,
#add-hidden-licensedStates-input,
#profile-hidden-licensedStates-input {
  display: none;
}
#modalAssignSupportUsers .results-wrapper,
#modalPartnerEdit .results-wrapper,
#myAddEditRealtorModal .results-wrapper,
#newManager .results-wrapper,
#editForm .results-wrapper {
  pointer-events: none;
  top: 40px;
  left: 0px;
  position: absolute;
  z-index: 999;
  background: #fff;
  filter: Alpha(opacity=0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin-top: -5px;
  margin-left: 12px;
  overflow-y: scroll;
  width: 0;
  max-height: 0;
  opacity: 0;
  transition: width 0s ease 0.4s, height 0s ease 0.4s, opacity 0.3s ease 0s;
}
#modalAssignSupportUsers .results-wrapper.viz,
#modalPartnerEdit .results-wrapper.viz,
#myAddEditRealtorModal .results-wrapper.viz,
#newManager .results-wrapper.viz,
#editForm .results-wrapper.viz {
  opacity: 1;
  width: 300px;
  max-height: 300px;
  transition: width 0s ease 0s, height 0s ease 0s, opacity 0.3s ease 0.1s;
}
#modalAssignSupportUsers .results-wrapper .result-node,
#modalPartnerEdit .results-wrapper .result-node,
#myAddEditRealtorModal .results-wrapper .result-node,
#editForm .results-wrapper .result-node,
#newManager .results-wrapper .result-node {
  pointer-events: all !important;
  opacity: 0;
  transition: opacity 0.3s ease 0s, padding-left 0.3s ease 0.4s,
    padding-top 0s ease 0.3s, padding-bottom 0s ease 0.3s,
    max-height 0.3s ease 0.4s, background-color 0.3s ease 0s,
    border-width 0s ease 0.3s;
  max-height: 0;
  padding: 0;
  pointer-events: none;
  border-bottom: 0px solid transparent;
  cursor: pointer;
}
#modalAssignSupportUsers .results-wrapper .result-node:hover,
#modalPartnerEdit .results-wrapper .result-node:hover,
#myAddEditRealtorModal .results-wrapper .result-node:hover,
#editForm .results-wrapper .result-node:hover,
#newManager .results-wrapper .result-node:hover {
  background-color: #d8d8d8;
  transition: opacity 0.3s ease 0s, padding-left 0.3s ease 0s,
    padding-top 0s ease 0.3s, padding-bottom 0s ease 0.3s,
    max-height 0.3s ease 0.3s, background-color 0.3s ease 0s,
    border-width 0s ease 0.3s;
}
#modalAssignSupportUsers .results-wrapper .result-node.viz,
#modalPartnerEdit .results-wrapper .result-node.viz,
#myAddEditRealtorModal .results-wrapper .result-node.viz,
#editForm .results-wrapper .result-node.viz,
#newManager .results-wrapper .result-node.viz {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  opacity: 1;
  max-height: 52px;
  pointer-events: initial;
  transition: opacity 0.3s ease 0s, padding-left 0.3s ease 0s,
    padding-top 0.3s ease 0s, padding-bottom 0.3s ease 0s,
    max-height 0.3s ease 0s, background-color 0.3s ease 0s,
    border-width 0.3s ease 0s;
  border-bottom: 1px solid #d8d8d8;
}
#modalAssignSupportUsers .results-wrapper .result-node .key1-value,
#modalPartnerEdit .results-wrapper .result-node .key1-value,
#myAddEditRealtorModal .results-wrapper .result-node .key1-value,
#editForm .results-wrapper .result-node .key1-value,
#newManager .results-wrapper .result-node .key1-value {
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#modalAssignSupportUsers .results-wrapper .result-node .key2-value,
#modalPartnerEdit .results-wrapper .result-node .key2-value,
#myAddEditRealtorModal .results-wrapper .result-node .key2-value,
#editForm .results-wrapper .result-node .key2-value {
  font-size: 13px;
  margin: 0;
}
#modalAssignSupportUsers .selected-node,
#modalPartnerEdit .selected-node,
#myAddEditRealtorModal .selected-node,
#editForm .selected-node,
#newManager .selected-node {
  flex: 1;
  flex-basis: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 50%;
  margin-left: 0px;
  padding: 0;
  line-height: 24px;
  opacity: 0;
  max-width: 0;
  height: 0;
  float: left;
  border-radius: 4px;
  border: 0px solid #d8d8d8;
  transition: opacity 0.3s ease 0s, max-width 0.3s ease 0.3s,
    height 0.3s ease 0.3s, margin 0.3s ease 0.3s, padding 0.3s ease 0.3s,
    border 0.3s ease 0.3s;
}
#modalAssignSupportUsers .selected-node i,
#modalPartnerEdit .selected-node i,
#myAddEditRealtorModal .selected-node i,
#editForm .selected-node i,
#newManager .selected-node i {
  line-height: 24px;
  margin-left: 5px;
  position: absolute;
  right: 12px;
}
#modalAssignSupportUsers .selected-node.viz,
#modalPartnerEdit .selected-node.viz,
#myAddEditRealtorModal .selected-node.viz,
#editForm .selected-node.viz,
#newManager .selected-node.viz {
  margin: 8px 6px 0 6px;
  padding: 0 28px 0 12px;
  max-width: 175px;
  opacity: 1;
  height: 24px;
  border: 1px solid #d8d8d8;
  transition: opacity 0.3s ease 0.2s, max-width 0.3s ease 0s,
    height 0.3s ease 0s, margin 0.3s ease 0s, padding 0.3s ease 0s,
    border 0.3s ease 0s;
}
#modalPartnerEdit .selected-node,
#myAddEditRealtorModal .selected-node,
#editForm .selected-node {
  flex: 0 auto;
  overflow: visible;
}
#modalPartnerEdit .selected-node:hover,
#myAddEditRealtorModal .selected-node:hover,
#editForm .selected-node:hover {
  cursor: pointer;
}

select {
  cursor: pointer;
}
select[disabled] {
  background-color: #ebebeb !important;
}
.cloneModal h4,
.tree-modal h4 {
  margin: 15px 0;
}
.cloneModal .form-wrap,
.tree-modal .form-wrap {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}
.cloneModal .section,
.tree-modal .section {
  padding: 0;
  height: 100%;
}
.cloneModal #clone-section-one.section {
  padding: 0;
  height: calc(100% - 30px);
}
.cloneModal .section .left,
.cloneModal .section .right,
.tree-modal .section .left,
.tree-modal .section .right {
  height: 100%;
}
.cloneModal .section .heading,
.tree-modal .section .heading {
  margin: 20px 0 10px 0;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  height: 20px;
}
.cloneModal .section .content,
.tree-modal .section .content {
  height: calc(100% - 65px);
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
.cloneModal .cloneTree,
.cloneModal .tree-view,
.tree-modal .cloneTree,
.tree-modal .tree-view {
  height: 100%;
  overflow: hidden;
}
.cloneModal .cloneTree .ui-fancytree,
.cloneModal .tree-view .ui-fancytree,
.tree-modal .cloneTree .ui-fancytree,
.tree-modal .tree-view .ui-fancytree {
  height: 100%;
}
.cloneModal .cloneTree .ui-fancytree li,
.cloneModal .tree-view .ui-fancytree li,
.tree-modal .cloneTree .ui-fancytree li,
.tree-modal .tree-view .ui-fancytree li {
  height: 100%;
}
.cloneModal .cloneTree .ui-fancytree li .fancytree-node,
.cloneModal .tree-view .ui-fancytree li .fancytree-node,
.tree-modal .cloneTree .ui-fancytree li .fancytree-node,
.tree-modal .tree-view .ui-fancytree li .fancytree-node {
  border-bottom: 1px solid #d8d8d8;
}
.cloneModal .cloneTree .ui-fancytree li .fancytree-node .fancytree-expander,
.cloneModal .tree-view .ui-fancytree li .fancytree-node .fancytree-expander,
.tree-modal .cloneTree .ui-fancytree li .fancytree-node .fancytree-expander,
.tree-modal .tree-view .ui-fancytree li .fancytree-node .fancytree-expander {
  display: none;
  width: 0;
  height: 0;
}
.cloneModal .cloneTree .ui-fancytree li .fancytree-node .fancytree-title,
.cloneModal .tree-view .ui-fancytree li .fancytree-node .fancytree-title,
.tree-modal .cloneTree .ui-fancytree li .fancytree-node .fancytree-title,
.tree-modal .tree-view .ui-fancytree li .fancytree-node .fancytree-title {
  color: #b0b3b6;
  white-space: normal;
}
.cloneModal .cloneTree .ui-fancytree li ul,
.cloneModal .tree-view .ui-fancytree li ul,
.tree-modal .cloneTree .ui-fancytree li ul,
.tree-modal .tree-view .ui-fancytree li ul {
  height: calc(100% - 32px);
  overflow-y: scroll;
}
.cloneModal .cloneTree .ui-fancytree li ul li,
.cloneModal .tree-view .ui-fancytree li ul li,
.tree-modal .cloneTree .ui-fancytree li ul li,
.tree-modal .tree-view .ui-fancytree li ul li {
  height: initial;
}
.cloneModal .cloneTree .ui-fancytree li ul li:nth-child(odd),
.cloneModal .tree-view .ui-fancytree li ul li:nth-child(odd),
.tree-modal .cloneTree .ui-fancytree li ul li:nth-child(odd),
.tree-modal .tree-view .ui-fancytree li ul li:nth-child(odd) {
  background: #f9f9f9;
}
.cloneModal .cloneTree .ui-fancytree li ul li .fancytree-node,
.cloneModal .tree-view .ui-fancytree li ul li .fancytree-node,
.tree-modal .cloneTree .ui-fancytree li ul li .fancytree-node,
.tree-modal .tree-view .ui-fancytree li ul li .fancytree-node {
  border-color: transparent;
}
.cloneModal
  .cloneTree
  .ui-fancytree
  li
  ul
  li
  .fancytree-node
  .fancytree-expander,
.cloneModal
  .tree-view
  .ui-fancytree
  li
  ul
  li
  .fancytree-node
  .fancytree-expander,
.tree-modal
  .cloneTree
  .ui-fancytree
  li
  ul
  li
  .fancytree-node
  .fancytree-expander,
.tree-modal
  .tree-view
  .ui-fancytree
  li
  ul
  li
  .fancytree-node
  .fancytree-expander {
  display: block;
  width: 15px;
  height: 15px;
}
.cloneModal .cloneTree .ui-fancytree li ul li .fancytree-node .fancytree-title,
.cloneModal .tree-view .ui-fancytree li ul li .fancytree-node .fancytree-title,
.tree-modal .cloneTree .ui-fancytree li ul li .fancytree-node .fancytree-title,
.tree-modal .tree-view .ui-fancytree li ul li .fancytree-node .fancytree-title {
  color: #333333;
}
.cloneModal .cloneTree .ui-fancytree li ul li ul,
.cloneModal .tree-view .ui-fancytree li ul li ul,
.tree-modal .cloneTree .ui-fancytree li ul li ul,
.tree-modal .tree-view .ui-fancytree li ul li ul {
  overflow-y: hidden;
}
.cloneModal .cloneTree ul.fancytree-container,
.cloneModal .tree-view ul.fancytree-container,
.tree-modal .cloneTree ul.fancytree-container,
.tree-modal .tree-view ul.fancytree-container {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  padding: 0;
  border: none;
}
.cloneModal .cloneTree ul.fancytree-container:focus,
.cloneModal .tree-view ul.fancytree-container:focus,
.tree-modal .cloneTree ul.fancytree-container:focus,
.tree-modal .tree-view ul.fancytree-container:focus {
  outline: none;
  border: none;
}
.cloneModal .cloneTree span.fancytree-node,
.cloneModal .tree-view span.fancytree-node,
.tree-modal .cloneTree span.fancytree-node,
.tree-modal .tree-view span.fancytree-node {
  display: flex;
}
.cloneModal .cloneTree span.fancytree-node:hover span.fancytree-title,
.cloneModal .tree-view span.fancytree-node:hover span.fancytree-title,
.tree-modal .cloneTree span.fancytree-node:hover span.fancytree-title,
.tree-modal .tree-view span.fancytree-node:hover span.fancytree-title {
  background: transparent;
  border-color: transparent;
}
.cloneModal .cloneTree span.fancytree-node .fancytree-expander,
.cloneModal .tree-view span.fancytree-node .fancytree-expander,
.tree-modal .cloneTree span.fancytree-node .fancytree-expander,
.tree-modal .tree-view span.fancytree-node .fancytree-expander {
  order: 2;
  background-image: none;
  width: 15px;
  height: 15px;
  position: relative;
  margin: 0;
  top: 8px;
}
.cloneModal .cloneTree span.fancytree-node .fancytree-expander:before,
.cloneModal .tree-view span.fancytree-node .fancytree-expander:before,
.tree-modal .cloneTree span.fancytree-node .fancytree-expander:before,
.tree-modal .tree-view span.fancytree-node .fancytree-expander:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 7px solid #626974;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.cloneModal .cloneTree span.fancytree-node .fancytree-checkbox,
.cloneModal .tree-view span.fancytree-node .fancytree-checkbox,
.tree-modal #cloneConfigTree span.fancytree-node .fancytree-checkbox,
.tree-modal .tree-view span.fancytree-node .fancytree-checkbox,
#modalNewCloneConfiguration
  #modalConsentInstallCRMIntegration
  #replaceAllCloneWrap
  span.fancytree-checkbox {
  order: 1;
  background-image: none;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  position: relative;
  margin: 0 15px 0 12px;
  top: 8px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.cloneModal .cloneTree span.fancytree-node .fancytree-title,
.cloneModal .tree-view span.fancytree-node .fancytree-title,
.tree-modal .cloneTree span.fancytree-node .fancytree-title,
.tree-modal .tree-view span.fancytree-node .fancytree-title {
  order: 3;
}
.cloneModal .cloneTree span.fancytree-active span.fancytree-title,
.cloneModal .tree-view span.fancytree-active span.fancytree-title,
.tree-modal .cloneTree span.fancytree-active span.fancytree-title,
.tree-modal .tree-view span.fancytree-active span.fancytree-title {
  background: transparent;
  border-color: transparent;
}
.cloneModal .cloneTree span.fancytree-title,
.cloneModal .tree-view span.fancytree-title,
.tree-modal .cloneTree span.fancytree-title,
.tree-modal .tree-view span.fancytree-title {
  line-height: 31px;
  color: #333333;
}
.cloneModal .cloneTree .fancytree-partsel span.fancytree-checkbox,
.cloneModal .tree-view .fancytree-partsel span.fancytree-checkbox,
.tree-modal .cloneTree .fancytree-partsel span.fancytree-checkbox,
.tree-modal .tree-view .fancytree-partsel span.fancytree-checkbox {
  background-color: #337ab7;
  border-color: #337ab7;
}
.cloneModal .cloneTree .fancytree-partsel span.fancytree-checkbox:before,
.cloneModal .tree-view .fancytree-partsel span.fancytree-checkbox:before,
.tree-modal .cloneTree .fancytree-partsel span.fancytree-checkbox:before,
.tree-modal .tree-view .fancytree-partsel span.fancytree-checkbox:before {
  top: 7px;
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  color: white;
  font-size: 9px;
  position: absolute;
}
.cloneModal .cloneTree .fancytree-selected span.fancytree-checkbox,
.cloneModal .tree-view .fancytree-selected span.fancytree-checkbox,
.tree-modal .cloneTree .fancytree-selected span.fancytree-checkbox,
.tree-modal .tree-view .fancytree-selected span.fancytree-checkbox,
#modalNewCloneConfiguration.cloneModal
  #modalConsentInstallCRMIntegration.cloneModal
  #replaceAllCloneWrap.fancytree-selected
  span.fancytree-checkbox {
  background-color: #337ab7;
  border-color: #337ab7;
}
.cloneModal .cloneTree .fancytree-selected span.fancytree-checkbox:before,
.cloneModal .tree-view .fancytree-selected span.fancytree-checkbox:before,
.tree-modal .cloneTree .fancytree-selected span.fancytree-checkbox:before,
.tree-modal .tree-view .fancytree-selected span.fancytree-checkbox:before {
  top: 7px;
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  color: white;
  font-size: 9px;
  position: absolute;
}
.cloneModal
  #replaceAllCloneWrap.fancytree-selected
  span.fancytree-checkbox:before {
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  color: white;
  font-size: 9px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cloneModal .cloneTree .fancytree-exp-nl span.fancytree-expander,
.cloneModal .tree-view .fancytree-exp-nl span.fancytree-expander,
.tree-modal .cloneTree .fancytree-exp-nl span.fancytree-expander,
.tree-modal .tree-view .fancytree-exp-nl span.fancytree-expander {
  width: 0;
  height: 0;
}
.cloneModal .cloneTree .fancytree-exp-nl span.fancytree-expander:before,
.cloneModal .tree-view .fancytree-exp-nl span.fancytree-expander:before,
.tree-modal .cloneTree .fancytree-exp-nl span.fancytree-expander:before,
.tree-modal .tree-view .fancytree-exp-nl span.fancytree-expander:before {
  display: none;
}
.cloneModal .cloneTree .fancytree-exp-e span.fancytree-expander:before,
.cloneModal .cloneTree .fancytree-exp-el span.fancytree-expander:before,
.cloneModal .tree-view .fancytree-exp-e span.fancytree-expander:before,
.cloneModal .tree-view .fancytree-exp-el span.fancytree-expander:before,
.tree-modal .cloneTree .fancytree-exp-e span.fancytree-expander:before,
.tree-modal .cloneTree .fancytree-exp-el span.fancytree-expander:before,
.tree-modal .tree-view .fancytree-exp-e span.fancytree-expander:before,
.tree-modal .tree-view .fancytree-exp-el span.fancytree-expander:before {
  transform: translate(-50%, -50%) rotate(180deg);
}
.cloneModal
  .cloneTree
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-focused
  span.fancytree-title,
.cloneModal
  .tree-view
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-focused
  span.fancytree-title,
.tree-modal
  .cloneTree
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-focused
  span.fancytree-title,
.tree-modal
  .tree-view
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-focused
  span.fancytree-title {
  border-color: transparent;
}
.cloneModal
  .cloneTree
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-active
  span.fancytree-title,
.cloneModal
  .tree-view
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-active
  span.fancytree-title,
.tree-modal
  .cloneTree
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-active
  span.fancytree-title,
.tree-modal
  .tree-view
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-active
  span.fancytree-title {
  background: transparent;
  border-color: transparent;
}
.cloneModal
  .cloneTree
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-selected
  span.fancytree-title,
.cloneModal
  .tree-view
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-selected
  span.fancytree-title,
.tree-modal
  .cloneTree
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-selected
  span.fancytree-title,
.tree-modal
  .tree-view
  .fancytree-plain.fancytree-container.fancytree-treefocus
  span.fancytree-selected
  span.fancytree-title {
  background: transparent;
  border-color: transparent;
}
.cloneModal
  .cloneTree
  .fancytree-plain
  span.fancytree-selected
  span.fancytree-title,
.cloneModal
  .tree-view
  .fancytree-plain
  span.fancytree-selected
  span.fancytree-title,
.tree-modal
  .cloneTree
  .fancytree-plain
  span.fancytree-selected
  span.fancytree-title,
.tree-modal
  .tree-view
  .fancytree-plain
  span.fancytree-selected
  span.fancytree-title {
  border-color: transparent;
  background-color: transparent;
}
.cloneModal .cloneTree ul.fancytree-container ul,
.cloneModal .tree-view ul.fancytree-container ul,
.tree-modal .cloneTree ul.fancytree-container ul,
.tree-modal .tree-view ul.fancytree-container ul {
  padding: 0;
}
.cloneModal .cloneTree ul[role="group"] li,
.cloneModal .tree-view ul[role="group"] li,
.tree-modal .cloneTree ul[role="group"] li,
.tree-modal .tree-view ul[role="group"] li {
  padding: 0 0 0 16px;
}
.cloneModal #selected-settings-content,
.cloneModal .tsup-selected-content,
.tree-modal #selected-settings-content,
.tree-modal .tsup-selected-content {
  overflow-y: scroll;
}
.cloneModal #selected-settings-content .clone-selected-item,
.cloneModal #selected-settings-content .tsup-selected-item,
.cloneModal .tsup-selected-content .clone-selected-item,
.cloneModal .tsup-selected-content .tsup-selected-item,
.tree-modal #selected-settings-content .clone-selected-item,
.tree-modal #selected-settings-content .tsup-selected-item,
.tree-modal .tsup-selected-content .clone-selected-item,
.tree-modal .tsup-selected-content .tsup-selected-item {
  padding: 6px 10px;
}
.cloneModal #selected-settings-content .clone-selected-item:nth-child(odd),
.cloneModal #selected-settings-content .tsup-selected-item:nth-child(odd),
.cloneModal .tsup-selected-content .clone-selected-item:nth-child(odd),
.cloneModal .tsup-selected-content .tsup-selected-item:nth-child(odd),
.tree-modal #selected-settings-content .clone-selected-item:nth-child(odd),
.tree-modal #selected-settings-content .tsup-selected-item:nth-child(odd),
.tree-modal .tsup-selected-content .clone-selected-item:nth-child(odd),
.tree-modal .tsup-selected-content .tsup-selected-item:nth-child(odd) {
  background: #f9f9f9;
}
.cloneModal #team-search-wrap,
.tree-modal #team-search-wrap {
  width: 100%;
  height: 80px;
  padding: 10px;
  border-bottom: 1px solid #d8d8d8;
}
.cloneModal #team-search-wrap .search-icon input,
.tree-modal #team-search-wrap .search-icon input {
  width: 100%;
}
.cloneModal #team-search-wrap #team-select-all,
.tree-modal #team-search-wrap #team-select-all {
  height: 15px;
  margin-top: 12px;
}
.cloneModal #team-search-wrap #team-select-all .checkbox,
.tree-modal #team-search-wrap #team-select-all .checkbox {
  width: 15px;
  height: 15px;
  float: left;
  margin: 0 12px 0 0;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.cloneModal #team-search-wrap #team-select-all .checkbox.partial,
.tree-modal #team-search-wrap #team-select-all .checkbox.partial {
  background: #337ab7;
  border-color: #337ab7;
}
.cloneModal #team-search-wrap #team-select-all .checkbox.partial:before,
.tree-modal #team-search-wrap #team-select-all .checkbox.partial:before {
  top: 7px;
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  color: white;
  font-size: 9px;
  position: absolute;
}
.cloneModal #team-search-wrap #team-select-all .checkbox.full,
.tree-modal #team-search-wrap #team-select-all .checkbox.full {
  background: #337ab7;
  border-color: #337ab7;
}
.cloneModal #team-search-wrap #team-select-all .checkbox.full:before,
.tree-modal #team-search-wrap #team-select-all .checkbox.full:before {
  top: 7px;
  content: "";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  color: white;
  font-size: 9px;
  position: absolute;
}
.cloneModal #team-search-wrap #team-select-all p,
.tree-modal #team-search-wrap #team-select-all p {
  float: left;
  margin: 0;
  line-height: 15px;
  color: #b0b3b6;
}
.cloneModal #team-results-wrap,
.tree-modal #team-results-wrap {
  width: 100%;
  height: calc(100% - 80px);
}
.cloneModal #team-selected-wrap,
.tree-modal #team-selected-wrap {
  width: 100%;
  height: 100%;
}
.cloneModal #team-results-wrap,
.cloneModal #team-selected-wrap,
.tree-modal #team-results-wrap,
.tree-modal #team-selected-wrap {
  overflow-y: scroll;
}
.cloneModal #team-results-wrap .team-item:nth-child(odd),
.cloneModal #team-selected-wrap .team-item:nth-child(odd),
.tree-modal #team-results-wrap .team-item:nth-child(odd),
.tree-modal #team-selected-wrap .team-item:nth-child(odd) {
  background: #f9f9f9;
}
.cloneModal #team-results-wrap .team-item,
.cloneModal #team-selected-wrap .team-item,
.tree-modal #team-results-wrap .team-item,
.tree-modal #team-selected-wrap .team-item {
  padding: 10px 10px 10px 10px;
  position: relative;
}
.cloneModal #team-results-wrap .team-item.has-checkbox,
.cloneModal #team-selected-wrap .team-item.has-checkbox,
.tree-modal #team-results-wrap .team-item.has-checkbox,
.tree-modal #team-selected-wrap .team-item.has-checkbox {
  padding: 10px 10px 10px 39px;
}
.cloneModal #team-results-wrap .team-item .checkbox,
.cloneModal #team-selected-wrap .team-item .checkbox,
.tree-modal #team-results-wrap .team-item .checkbox,
.tree-modal #team-selected-wrap .team-item .checkbox {
  float: left;
  margin: 0 12px 0 0;
  position: absolute;
  left: 10px;
}
.cloneModal #team-results-wrap .team-item .text-wrap,
.cloneModal #team-selected-wrap .team-item .text-wrap,
.tree-modal #team-results-wrap .team-item .text-wrap,
.tree-modal #team-selected-wrap .team-item .text-wrap {
  float: left;
  width: 100%;
  text-overflow: ellipsis;
}
.cloneModal #team-results-wrap .team-item .team-name,
.cloneModal #team-selected-wrap .team-item .team-name,
.tree-modal #team-results-wrap .team-item .team-name,
.tree-modal #team-selected-wrap .team-item .team-name {
  margin: 0;
  font-size: 14px;
  color: #333333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cloneModal #team-results-wrap .team-item .team-email,
.cloneModal #team-selected-wrap .team-item .team-email,
.tree-modal #team-results-wrap .team-item .team-email,
.tree-modal #team-selected-wrap .team-item .team-email {
  margin: 0;
  font-size: 13px;
  color: #626974;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cloneModal .modal-footer,
.tree-modal .modal-footer {
  height: 67px;
}
.cloneModal #clone-prev-btn,
.tree-modal #clone-prev-btn {
  position: absolute;
  left: 15px;
}
.cloneModal #clone-next-btn,
.cloneModal #clone-submit-btn,
.tree-modal #clone-next-btn,
.tree-modal #clone-submit-btn {
  right: 15px;
}
.cloneModal #clone-prev-btn,
.cloneModal #clone-next-btn,
.cloneModal #clone-submit-btn,
.tree-modal #clone-prev-btn,
.tree-modal #clone-next-btn,
.tree-modal #clone-submit-btn {
  position: absolute;
}

.fancytree-exp-n span.fancytree-expander,
.fancytree-exp-nl span.fancytree-expander {
  display: none !important;
}

#nmls-sync-encompass {
  margin-top: 5px;
  cursor: pointer;
}
#nmls-sync-encompass i {
  float: left;
  line-height: 1.42857143;
  color: #337ab7;
  margin-right: 7px;
}
#nmls-sync-encompass p {
  float: left;
  color: #337ab7;
}

#companyDisclaimerCounter {
  position: absolute;
  bottom: 38px;
  right: 22px;
  color: #b0b3b6;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-fill {
  flex: 1 1 auto;
}

.align-items-center {
  align-items: center;
}

.custom-question-actions button {
  margin: 0 5px;
}

.custom-question-actions.flex-column button {
  margin-bottom: 5px;
}

.padding-top-7 {
  padding-top: 7px;
}

.custom-question-items-list li .single-input-delete {
  margin-top: 10px;
  margin-left: 5px;
}

.custom-question-label.disabled {
  color: #ccc;
}

.custom-question-label:focus-visible {
  outline: none;
}

.custom-question-label-char-count-container {
  margin-bottom: 5px;
  padding-left: 0px;
}

.custom-question-input-char-count-container {
  margin-left: 30px;
}

.custom-question-input-char-count-container
  input.custom-question-input.flex.flex-grow {
  border: none;
  height: 100%;
  margin-left: -10px;
  margin-top: 0px;
}

tr.custom-question-row td .custom-question-label-char-count-container {
  padding: 0;
  position: relative;
}
tr.custom-question-row td p.char-limit {
  position: absolute;
  top: 6px;
  right: 10px;
}
tr.custom-question-row td .custom-question-label {
  border: none;
  margin-bottom: 0px;
  margin-top: 0px;
  width: auto;

  border-radius: 3px;
  padding: 6px 12px;
  padding-right: 40px;
  resize: vertical;

  min-height: 53px;
  max-height: 100px;
}

tr.custom-question-row .form-multiline {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.custom-question-type.disabled {
  color: #ccc;
}

.conditional-question-row select:disabled {
  color: #ccc;
}

.conditional-question-row select:disabled:hover {
  cursor: not-allowed;
}

.ui-sortable:hover,
.ui-sortable .grabbable:hover {
  cursor: grab;
}

.ui-sortable-disabled:hover {
  cursor: default;
}

.custom-question-items-list .ui-sortable {
  cursor: default;
}

.single-input-delete:hover,
.addListItem,
.milestoneSet-actions:hover,
.delete-conditions-row:hover {
  cursor: pointer;
}

.single-input-delete {
  color: #337ab7;
  font-size: 120%;
}

.whiteLabelHelpBlock {
  margin: 3px 0 3px 0;
  word-break: break-all;
}
.whiteLabelHelpBlock p {
  margin: 0;
}

#whiteLabelURLPrefix {
  transition: all 0.3s ease;
}

.teamDragNDrop {
  margin-bottom: 40px;
}

#urlPrefixLabel {
  padding-left: 28px;
}

#whiteLabelDragNDrop {
  padding-left: 28px;
}

#whiteLabelDragNDropInner {
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 15px;
  margin-top: 5px;
}

.dragItemWrap {
  float: left;
  margin-right: 10px;
}
.dragItemWrap label {
  font-weight: 400;
  float: left;
  line-height: 28px;
}
.dragItemWrap .dragItem,
.dragItemWrap .teamDragItem {
  float: left;
  background: #fdfdc8;
  padding: 0 7px;
  margin-left: 5px;
  line-height: 28px;
  cursor: grab;
}

.urlPrefixWrapper {
  position: relative;
  padding-left: 28px;
}

#teamUrlIndicator,
#companyUrlIndicator {
  color: #d5413e;
  position: absolute;
  right: 12px;
  top: 12px;
  transition: 0.3s ease;
}
#teamUrlIndicator.valid,
#companyUrlIndicator.valid {
  opacity: 0;
}

#companyUrlIndicator {
  right: 24px;
}

.enableWhiteLabelWrapper {
  margin-top: 10px;
}

.enableWhiteLabelWrapper #enableTeamWhiteLabel {
  float: left;
  width: 24px;
  margin-right: 5px;
  cursor: pointer;
}

.enableWhiteLabelWrapper .help-block {
  float: left;
  line-height: 11px;
  margin-bottom: 0;
}

.enableWhiteLabelWrapper .help-block p {
  margin-bottom: 0;
}

#whiteLabelURLOptions {
  padding-left: 28px;
}

#whiteLabelURLOptions input {
  float: left;
  clear: both;
  margin: 0 10px 0 0;
  height: 21px;
}

#whiteLabelURLOptions span {
  float: left;
  line-height: 21px;
  word-break: break-all;
  max-width: calc(100% - 24px);
}

.whiteLabelRadio {
  cursor: pointer;
}

.help-block.danger {
  background: #f2dede !important;
  padding: 0.2em;
}

.prospects-filter {
  display: inline-block;
}

.prospects-filter {
  padding-left: 10px;
}
.prospects-filter label {
  float: left;
}
.prospects-filter .prospects-summary-details {
  float: left;
  clear: both;
}

#prsopects-filter-wrap {
  float: right;
}

#startNewProspect .input-row {
  margin-bottom: 19px;
}

#startNewProspect .input-row:last-child {
  margin-bottom: -10px;
}

#startNewProspect .modal-sm {
  width: 350px;
}

#startNewProspect .col-xs-3 {
  padding-right: 0;
}

#startNewProspect .col-xs-9 {
  padding-left: 0;
}

@media only screen and (max-width: 1200px) {
  .prospects-filter {
    clear: both;
  }

  #prsopects-filter-wrap {
    float: left;
    clear: both;
    margin-top: 15px;
  }
  #prsopects-filter-wrap .form-group {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 448px) {
  .prospects-filter {
    padding-left: 0px;
  }
  .prospects-filter .prospects-summary-details .mobile-block {
    display: block;
  }
  .prospects-filter .prospects-summary-details .mobile-block .bar-divider {
    display: none;
  }
}
.floify-app-row {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  width: calc(100% - 60px);
  margin: 0;
}
.floify-app-row:before {
  display: none;
}
.floify-app-row .floify-app-cell {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}
.floify-app-row .floify-app-cell .help-block {
  height: 48px;
}
.floify-app-row .floify-app-cell .panel {
  height: calc(100% - 48px);
  margin: 0 0 10px 0;
}
.floify-app-row .floify-app-cell .panel .help-block {
  height: initial;
}

#meet-minimum input {
  margin-right: 5px;
}
#meet-minimum ul {
  list-style-type: none;
  padding-left: 1.5em;
}
#meet-minimum ul li {
  margin-bottom: 0.5em;
}

.appActionsWrapper {
  display: flex;
  justify-content: space-between;
  align-content: start;
  position: relative;
}

.justify-content-center {
  justify-content: center;
}

.unavailable-apps.floify-app-row .floify-app-cell .panel {
  height: 100%;
}

.appActionsWrapper .installUninstallBtnWrap a {
  width: 100%;
}
.appActionsWrapper .apiKeyBtnWrap {
  width: calc(50% - 5px);
}
.appActionsWrapper .apiKeyBtnWrap .seeMore {
  width: 100%;
}
.appActionsWrapper .apiKeyBtnWrap .seeMore i {
  margin-left: 3px;
}
.settingsApiKeyBtnWrap {
  position: relative;
}
.settingsApiKeyBtnWrap .apiKeyCopyWrap {
  width: 300px;
  top: 32px;
  right: -13px;
  left: initial;
}
.apiKeyCopyWrap {
  z-index: 9;
  position: absolute;
  width: 100%;
  background: white;
  left: 0;
  padding: 10px 15px 15px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  top: 55px;
}
.apiKeyCopyWrap::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
  position: absolute;
  top: -10px;
  right: 10px;
}
.apiKeyCopyWrap .copyDescription {
  width: 100%;
  display: inline-block;
  margin: 0 0 12px 0;
  text-align: left;
}

.apiKeyInputWrap input {
  width: calc(100% - 35px) !important;
  box-sizing: border-box;
  display: inline-block;
}
.apiKeyInputWrap span {
  width: 35px;
  box-sizing: border-box;
  display: inline-block;
  line-height: 1.42857143;
}

.copyApiKeyOKBtn {
  float: right;
  margin-top: 15px;
}

.copySuccessMsg {
  float: left;
  background: #fff1a0;
  margin: 10px 0 0;
  padding: 6px 12px;
  border-radius: 4px;
  line-height: 1.42857143;
  border: 1px solid #fff1a0;
  display: none;
}

@media only screen and (max-width: 1150px) {
  .apiKeyBtnWrap .seeMore i {
    display: none;
  }

  .copySuccessMsg {
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .floify-app-row {
    grid-template-columns: 50% 50%;
    width: calc(100% - 20px);
  }
}
@media only screen and (max-width: 600px) {
  .floify-app-row {
    grid-template-columns: 100%;
    grid-column-gap: 0px;
  }
}
#apply-now-notifications {
  padding: 10px;
}
#apply-now-notifications p {
  padding: 10px;
  margin-bottom: 0;
}
#apply-now-notifications .bg-grey {
  background: #f9f9f9;
}
#apply-now-notifications .flex {
  display: flex;
}
#apply-now-notifications .flex.space-between {
  justify-content: space-between;
}
#apply-now-notifications .flex.align-center {
  align-items: center;
}
#apply-now-notifications .margin-50-right {
  margin-right: 50%;
}

.form-grouping fieldset #apply-now-notifications a {
  margin-bottom: 0;
}

.questions-disabled {
  color: #9c9c9c;
}
.urlaVersionWrapper {
  margin-bottom: 25px;
}
.quick-assist-wrapper .checkbox {
  margin-bottom: 0;
}
.urlaVersionWrapper div,
.no-password-wrapper div,
.quick-assist-wrapper div,
.import-mismo-34-settings-wrapper div {
  clear: both;
  float: left;
}
.urlaVersionWrapper input,
.urlaVersionWrapper p,
.no-password-wrapper input,
.no-password-wrapper p,
.quick-assist-wrapper input,
.quick-assist-wrapper p,
.import-mismo-34-settings-wrapper input,
.import-mismo-34-settings-wrapper p {
  float: left;
}
.urlaVersionWrapper p,
#no-password-container div p,
#quick-assist-radios-container div p,
#import-mismo-34-settings-container div p, #import-los-settings-container div p {
  margin-left: 10px;
}
#no-password-container,
#import-mismo-34-settings-container, #import-los-settings-container {
  margin-left: 15px;
  margin-bottom: 0;
}
#clipkey {
  cursor: pointer;
}
.noPasswordExtraOptions p {
  width: 100%;
  float: left;
  padding-left: 20px;
}
.noPasswordExtraOptions input,
.teamNoPasswordOptions input {
  margin-right: 7px;
}
.noPasswordExtraOptions .radioWrap {
  float: left;
  padding-left: 20px;
  width: 100%;
}
#emailApplicaitonLinkModal .form-group div {
  float: left;
  width: 100%;
}
#emailApplicaitonLinkModal .form-group div label {
  width: 100%;
  float: left;
}
#emailApplicaitonLinkModal .form-group div input {
  float: left;
  margin-bottom: 24px;
  border-radius: 4px;
  width: 200px;
}
#emailApplicaitonLinkModal .form-group div textarea {
  float: left;
  width: 100%;
  resize: none;
  border-radius: 4px;
}
#languageInputCopyWrap {
  display: flex;
  align-content: space-between;
  position: relative;
}
#languageInputCopy {
  width: 100%;
  display: flex;
}
#languageInputCopy span {
  margin-left: -5px;
}
#languageInputCopy span img {
  display: block;
  right: 6px;
  position: relative;
  top: 2px;
}
#languageCopySuccess,
#languageCopySuccessAN {
  flex-grow: 1;
  margin-left: 10px;
  border-radius: 4px;
  background: #fff19f;
  text-align: center;
  line-height: 34px;
  transition: opacity 0.3s ease;
  position: absolute;
  right: 0;
  top: -34px;
  padding: 0 10px;
}

#languageCopySuccess.notViz,
#languageCopySuccessAN.notViz {
  opacity: 0;
  display: none;
}
#languageCopySuccess.viz,
#languageCopySuccessAN.viz {
  opacity: 1;
  display: block;
}
#languageInputCopy #spanishLink,
#languageInputCopy #spanishLinkAN {
  color: #8e8e8e;
  background: #fff;
}
#languageInputCopy #clipkey,
#languageInputCopy #clipkeyAN {
  background: #fff;
  border: 1px solid #ccc;
  padding-left: 16px;
  padding-right: 16px;
}
#languageInputCopy #clipkeyAN:hover {
  cursor: pointer;
}
.CRMPropertyType.teamOnly, .integrationPropertyType.teamOnly {
  opacity: 0.5;
  pointer-events: none;
}
.requiredMarker {
  color: #eb0000;
  font-size: 6px;
  margin: 0 5px;
  position: relative;
  top: -6px;
}
#whoAmI {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
#whoAmI.noConsents {
  flex-direction: column;
}
#whoAmILeft {
  display: flex;
  flex-direction: column;
  width: 40%;
}
#whoAmIRight {
  width: 60%;
  padding-left: 60px;
}
#whoAmISubHeader {
  font-family: HelveticaNeue;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.39px;
  color: #333333;
  margin-bottom: 30px;
}
#whoAreYouSubmit {
  margin-top: 30px;
  float: left;
  clear: both;
}
.whoAmISelect {
  height: 75px;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #bdbdbd;
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 0 24px;
  cursor: pointer;
  transition: border 0.3s ease;
  float: left;
}
.whoAmIInputs:first-of-type {
  margin-bottom: 38px;
}
.whoAmIInputs .help-block {
  margin-top: 0px;
}
.whoAmIInputs .consent-input {
  margin-bottom: 5px;
}
.whoAmIInputs .consent-input .form-group,
.whoAmIInputs .consent-input .form-group .help-block {
  margin: 0;
  padding: 0;
}
.whoAmIInputs .text-consent .left-text {
  margin-right: 10px;
}
.whoAmIInputs .text-consent .left-text,
.whoAmIInputs .text-consent .right-text .radio-label {
  vertical-align: text-bottom;
}
.whoAmIInputs .text-consent {
  margin-top: 10px;
}
.whoAmIInputs .finePrint {
  font-size: 10px;
}
.whoAmIInputs .finePrint a {
  color: #737373;
  text-decoration: underline;
}
.whoAmISelect.selected {
  border: solid 2px #347ab7;
}
.whoAmISelect i {
  float: left;
  font-size: 24px;
  width: 30px;
  color: #616974;
  line-height: 75px;
  margin-right: 24px;
}
.whoAmISelect p {
  line-height: 75px;
  font-size: 20px;
  letter-spacing: -0.23px;
  color: #333333;
  height: 100%;
}
#switchSharedEmailBorrowers {
  cursor: pointer;
  border-left: 1px solid #626974;
  padding-left: 15px;
  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  color: #626974;
}
#switchSharedEmailBorrowers span {
  transition: 0.3s ease;
}
#switchSharedEmailBorrowers:hover span {
  color: #347ab7;
}
#switchSharedEmailBorrowers:hover i {
  color: #347ab7;
}
#switchSharedEmailBorrowers:hover #otherSharedEmailBorrower i {
  color: #626974;
}
#otherSharedEmailBorrower {
  display: none;
  position: absolute;
  height: 41px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  line-height: 41px;
  padding: 0 15px;
  top: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 0;
  cursor: pointer;
  color: #626974;
}
#otherSharedEmailBorrower:hover {
  color: #347ab7;
}
#switchSharedUserFormWrap {
  float: right;
  margin-top: 30px;
}
#switchSharedUserFormWrap p {
  float: left;
  margin-right: 20px;
  line-height: 28px;
  margin-bottom: 0;
}
#switchSharedUserButtonForm {
  float: left;
}
#switchSharedUserButton {
  line-height: 26px;
  height: 28px;
  border-radius: 3px;
  border: solid 1px #696969;
  background-color: #777772;
  padding: 0 8px;
  font-size: 14px;
  letter-spacing: -0.01px;
  color: #ffffff;
  /* float:left; */
  /* transform:translateY(-50%); */
}
#switchSharedUserButton i {
  margin-right: 8px;
}
#esignSwitchLoanFlowBtn {
  line-height: 34px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #696969;
  background-color: #9b9b9b;
  font-family: HelveticaNeue;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.17px;
  color: #ffffff;
  padding: 0 12px;
  margin-right: 15px;
  float: left;
}
#esignSwitchWrapper {
  margin: 35px 0 50px 0;
}
#esignSwitchBorrowerForm {
  float: left;
}
#esignSwitchBorrowerSubmit {
  background-color: #347ab7;
  line-height: 34px;
  height: 34px;
  border-radius: 4px;
  color: white;
  padding: 0 12px;
  margin: 0;
  display: block;
}
#esignSwitchBorrowerSubmit i {
  margin-right: 12px;
  color: white;
  line-height: 34px;
  font-size: 15px;
  float: left;
}
#esignSwitchBorrowerSubmit p {
  font-family: HelveticaNeue;
  line-height: 34px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.17px;
  color: #ffffff;
  margin: 0;
  height: 34px;
  float: left;
}

#esignNextEnvelopeLoanFlowBtn {
  line-height: 34px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #696969;
  background-color: #9b9b9b;
  font-family: HelveticaNeue;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.17px;
  color: #ffffff;
  padding: 0 12px;
  margin-right: 15px;
  float: left;
}
#esignNextEnvelopeWrapper {
  margin: 35px 0 50px 0;
}
#esignNextEnvelopeForm {
  float: left;
}
#esignNextEnvelopeSubmit {
  background-color: #347ab7;
  line-height: 34px;
  height: 34px;
  border-radius: 4px;
  color: white;
  padding: 0 12px;
  margin: 0;
  display: block;
}
#esignNextEnvelopeSubmit p {
  font-family: HelveticaNeue;
  line-height: 34px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.17px;
  color: #ffffff;
  margin: 0;
  height: 34px;
  float: left;
}

@media only screen and (max-width: 991px) {
  .whoAmISelect {
    width: 100%;
  }
  .whoAmIInputs {
    width: 100%;
    padding-left: 0px;
  }
  #borrowerSelect {
    order: 1;
  }
  #coboSelect {
    order: 2;
  }
  #bothSelect {
    order: 3;
  }
  #whoAmIBorrowerInputs {
    order: 4;
  }
  #whoAmICoboInputs {
    order: 5;
  }
}

#uploading-progress .progress-message {
  color: #737373;
  margin-bottom: 10px;
}

#uploading-progress .progress-bar {
  position: relative;
  width: 100%;
  height: 5px;
  border-radius: 4px;
  background-color: #e6e6e6;
}

#uploading-progress .progress-complete {
  height: 5px;
  border-radius: 4px;
  background-color: #337ab7;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

@media only screen and (max-width: 1091px) {
  #switchSharedUserFormWrap {
    float: left;
    clear: both;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 900px) {
  .page-header.mobile-who-are-you-page-header {
    margin: 24px 0 10px;
  }
  #esignSwitchBorrowerForm {
    clear: both;
    margin-top: 15px;
  }
  #esignNextEnvelopeForm {
    clear: both;
    margin-top: 15px;
  }
  #switchSharedEmailBorrowers {
    border-left: initial;
    padding-left: initial;
  }
  #otherSharedEmailBorrower {
    right: initial;
    top: initial;
    margin-top: 10px;
  }
}

#modalApplyNowLoanAssignmentForm select {
  width: 50%;
}
#modalApplyNowLoanAssignmentForm select[name="defaultLoanOwner"] {
  margin-bottom: 35px;
}
#replaceAllCloneWrap {
  padding: 15px 15px 0 15px;
}
#replaceAllCloneWrap .fancytree-checkbox {
  float: left;
  top: 0px !important;
  cursor: pointer;
}
#replaceAllCloneWrap p {
  float: left;
  margin: 0;
  line-height: 15px;
  cursor: pointer;
}
#cloneConfigTree.block {
  cursor: not-allowed;
}
#cloneConfigTree.block .ui-fancytree {
  pointer-events: none;
  opacity: 0.35;
}

.floify-app-cell .panel-heading {
  min-height: 62px;
}

@media (min-width: 1200px) {
  .modal-xl {
    width: 1140px;
  }
}

#disclosure-hybrid-e-closing .hybridEClosingSectionInputWrap {
  width: 100%;
}
#disclosure-hybrid-e-closing .hybridEClosingSectionLabel {
  font-weight: 400;
}
#disclosure-hybrid-e-closing .hybridEClosingSectionLabel span {
  font-weight: 700;
}
#disclosure-hybrid-e-closing .hybridEClosingSectionInputOne {
  width: calc(100% - 260px);
  float: left;
}
#disclosure-hybrid-e-closing .signingPeriodHour {
  width: 40px;
  float: left;
  padding: 6px 10px;
}
#disclosure-hybrid-e-closing .signingPeriodHour::-webkit-outer-spin-button,
#disclosure-hybrid-e-closing .signingPeriodHour::-webkit-inner-spin-button,
#disclosure-hybrid-e-closing .signingPeriodMin::-webkit-outer-spin-button,
#disclosure-hybrid-e-closing .signingPeriodMin::-webkit-inner-spin-button,
#disclosure-hybrid-e-closing .signingPeriodHour,
#disclosure-hybrid-e-closing .signingPeriodMin {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
#disclosure-hybrid-e-closing .signingPeriodDivider {
  float: left;
  line-height: 34px;
  width: 10px;
  text-align: center;
}
#disclosure-hybrid-e-closing .signingPeriodMin {
  width: 40px;
  float: left;
  padding: 6px 10px;
}
#disclosure-hybrid-e-closing .signingPeriodAmPm {
  width: 60px;
  float: left;
  margin-left: 10px;
  padding: 6px 8px;
}
#disclosure-hybrid-e-closing .hybridEClosingSectionDivider {
  width: 30px;
  line-height: 34px;
  float: left;
  text-align: center;
}
#disclosure-hybrid-e-closing .hybridEClosingSectionInputTwo {
  width: calc(28% - 30px);
  float: left;
}
#disclosure-hybrid-e-closing .hybridEClosingSectionInputThree {
  width: 60px;
  float: left;
  margin-left: 10px;
  padding: 6px 8px;
}
.wickedpicker {
  z-index: 9999;
}
#disclosure-hybrid-e-closing #closingDateFieldWrap {
  width: 100%;
}
#disclosure-hybrid-e-closing #closingDateFieldWrap label {
  width: 100%;
  float: left;
}
#disclosure-hybrid-e-closing #closingDateField {
  margin-bottom: 10px;
  width: 40%;
  float: left;
  clear: both;
}
#hybridEClosingCloseTimeZoneDisplay {
  line-height: 34px;
  margin-left: 19px;
  float: left;
}
.esig-button-3[disabled] {
  opacity: 0.25;
}
.esig-button-3 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
}
.hybridEClosingFakeInput {
  display: block;
  width: 100%;
  min-height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #f1f1f1;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  pointer-events: none;
}
.hybridEClosingRealInput {
  display: block;
  width: 100%;
  min-height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  resize: none;
}
#modalHybridEClosingText .char-limit {
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 25px;
  color: #777;
}
.justify-between {
  justify-content: space-between;
}
.disabledEsignButton,
.esig-disabled {
  opacity: 0.25;
}
button._pendo-badge {
  /* make sure pendo badges don't overlap modals */
  z-index: unset !important;
}
img[id^="pendo-image-badge"] {
  width: 16px !important;
  height: 16px !important;
}
.pendo-resource-center-badge-notification-bubble {
  display: none;
}
.org-icon {
  max-height: 26px;
  position: relative;
  top: -7px;
  cursor: pointer;
}
.unlinkOrgBtn {
  cursor: pointer;
}
#auditTrailReactTarget {
  width: 100%;
  margin-top: 3px;
}

#template-section {
  padding-left: 7px;
}
.mainDIV {
  padding-bottom: 20px;
}
.greyBG {
  background-color: #f9fbfe;
}
.editMilestoneHeading {
  text-transform: uppercase;
  font-weight: 500;
  color: #797676;
  margin-bottom: 10px;
}
#prospectsBorrowerLinksMain {
  position: relative;
  float: left;
  height: 40px;
  margin: 20px 20px 15px 0;
}
#prospectsBorrowerLinksBtn {
  background: #fff;
  padding: 0 15px;
  border: 1px solid #d8d8d8;
  float: left;
  position: relative;
  border-radius: 4px;
  line-height: 39px;
  height: 40px;
  color: #626974;
  cursor: pointer;
}
#prospectsBorrowerLinksMain:hover #prospectsBorrowerLinksWrap {
  display: block;
}
#prospectsBorrowerLinksMain:hover #prospectsBorrowerLinksBtn {
  z-index: 100;
  border-bottom: none;
  box-shadow: 0px -1px 2px #dedede;
  border-radius: 4px 4px 0 0;
  height: 36px;
}
#prospectsBorrowerLinksBtn i {
  transform-origin: center;
  transform: rotate(180deg);
  position: relative;
  top: -3px;
  margin-left: 10px;
  color: #626974;
}
#prospectsBorrowerLinksWrap {
  padding-top: 5px;
  background: #fff;
  border: 1px solid #d8d8d8;
  /* float:left; */
  border-radius: 0 4px 4px 4px;
  /* line-height:40px; */
  box-shadow: 0px 1px 4px #dedede;
  color: #626974;
  position: absolute;
  display: none;
  top: 35px;
  z-index: 99;
}
.prospectsBorrowerLink {
  height: 40px;
  padding: 0 25px 0 15px;
  border-bottom: 1px solid #d8d8d8;
}
.prospectsBorrowerLink p {
  white-space: nowrap;
  line-height: 40px;
}
.assigned_to_me_loan_tabs #manager-index-tabs li a {
  font-size: 16px;
  padding-bottom: 10px;
}
.company_loan_tabs #manager-index-tabs li a {
  font-size: 14px;
  padding-bottom: 10px;
}
.importUrlaQuestionsButton #importCustomQuestions {
  float: left;
}
.importUrlaQuestionsButton #result {
  width: 100%;
}
@media only screen and (max-width: 900px) {
  #prospectsBorrowerLinksWrap {
    width: calc(100vw - 60px);
  }
  .prospectsBorrowerLink p {
    display: inline;
    white-space: initial;
    line-height: initial;
  }
  .prospectsBorrowerLink {
    height: initial;
    padding: 10px 25px 10px 15px;
  }
  #modalSwitchTeams .modal-dialog {
    width: calc(100% - 20px);
  }
}

@media only screen and (max-width: 425px) {
  #prospectsBorrowerLinksMain {
    margin: 20px 10px 15px 0;
  }
  #prospectsBorrowerLinksBtn {
    padding: 0 10px;
  }
  #prospectsBorrowerLinksBtn i {
    margin-left: 5px;
  }
  .new-prospect-button {
    padding: 0 10px;
  }
  .new-prospect-button i {
    margin-right: 5px !important;
  }
  .dataTables_paginate .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .dataTables_paginate .pagination .paginate_button.previous {
    width: 100% !important;
    margin-bottom: 5px;
  }
  .dataTables_paginate .pagination .paginate_button.next {
    width: 100% !important;
    margin-top: 5px;
  }
  .dataTables_paginate .pagination .paginate_button.previous a,
  .dataTables_paginate .pagination .paginate_button.next a {
    width: 100%;
    border-radius: 4px;
    margin-left: 0;
  }
}
@media only screen and (max-width: 360px) {
  #prospectsBorrowerLinksBtn,
  .new-prospect-button {
    font-size: 12px;
  }
  #prospectsBorrowerLinksBtn {
    padding: 0 7px;
  }
  #prospectsBorrowerLinksBtn i {
    margin-left: 3px;
  }
  .new-prospect-button {
    padding: 0 7px;
  }
  new-prospect-button i {
    margin-right: 3px !important;
  }
}
.oneViewFilter {
  height: 40px;
}
.loan-dashboard-search-box.one-view {
  width: 50%;
}
#filter-group-wrapper.one-view {
  float: left;
  width: 50%;
  position: relative;
}
.loan-dashboard-search-box.one-view .dataTables_filter {
  width: 50%;
  height: 40px;
}
@media only screen and (max-width: 900px) {
  #filter-group-wrapper.one-view {
    /*width:100%;*/
    display: block;
  }
  .loan-dashboard-search-box.one-view .dataTables_filter {
    float: left;
  }
  .loan-dashboard-search-box.one-view {
    float: right;
  }
}
@media only screen and (max-width: 650px) {
  .loan-dashboard-search-box.one-view {
    width: 100%;
    float: none;
  }
}
@media only screen and (max-width: 650px) {
  #filter-group-wrapper.one-view {
    width: 100%;
    /*display:block;*/
  }
  .loan-dashboard-search-box.one-view .dataTables_filter {
    width: 100%;
    clear: both;
    padding-left: 0;
  }
  .loan-dashboard-search-box {
    height: initial;
  }
}
.flo-pulse {
  -webkit-animation-name: flo-pulse;
  animation-name: flo-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes flo-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes flo-pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
div.dataTables_wrapper div.dataTables_processing {
  position: fixed !important;
}
.azTxFooterText {
  margin-right: 10px;
  display: inline-block;
}
.header-unsaved-changes {
  /*top:-8px;*/
  float: right;
  position: relative;
  margin: 20px 0 10px 0;
}
.reassign-prospect {
  cursor: pointer;
}
.settings-indent {
  padding-left: 20px;
}
.company-encompass-soft-pull label span {
  margin-left: 5px;
  font-weight: 400;
}
.company-encompass-soft-pull .help-block,
.company-encompass-soft-pull label span {
  font-size: 12px;
  color: #919191;
}
.applyNowCreditPullOptions {
  padding-left: 20px;
}
.applyNowCreditPullOptions label {
  width: calc(33.33% - 20px);
  padding: 0;
  margin-right: 10px;
}
.applyNowCreditPullOptions input {
  width: 100%;
}
.applyNowCreditPullOptions select,
.applyNowCreditPullOptions input {
  margin-bottom: 15px;
}
.applyNowCreditPullOptions input[disabled] {
  cursor: not-allowed;
}
#applyNowTeamCreditCheck {
  padding-left: 20px;
}
#applyNowTeamCreditCheck .teamCreditAppRadioLabel {
  padding-left: 5px;
  margin-bottom: 5px;
}
.applyNowCreditPullOptions label.error {
  color: #e71c04;
}
.applyNowCreditPullOptions label.error input {
  border-color: #e71c04;
}
#useSoftPullCheckBox {
  margin: 15px 0 20px 0;
}
#creditCheckTabs {
  margin: 0 0 20px 0;
}
#teamObCheckbox {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
#integrations-azure-icon {
  height: 22px;
  top: -1px;
  position: relative;
}
.credit-check-app-modal .password-field.hidden {
  display: none;
}
.credit-check-app-modal .password-field.btn {
  display: block;
  width: 100%;
}
.credit-check-app-modal .optional {
  font-size: 11px;
  font-weight: 500;
  color: #888888;
}

#quickPackDocSearchInputLabel {
  position: absolute;
  top: -25px;
}
#templateDocSearchInputWrap {
  /*margin:0 0 20px 6px;*/
  width: 33%;
  display: inline-block;
  margin-bottom: 20px;
}
#templateDocSearchInputWrap label {
  /*position:absolute;*/
  /*top:-24px;*/
}
#teamTemplateDocActionsWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#teamTemplateDocActionsWrap a {
  height: 22px;
  margin-bottom: 20px;
}
#teamTemplateDocActionsAndSearchWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
@media only screen and (max-width: 768px) {
  #quickPackDocSearchInput {
    margin-top: 35px;
  }
}
.prospectIsEditing {
  margin-left: 8px;
  color: #92de92 !important;
}
#coPilotOptOutCheckLabel {
  margin-left: 10px;
  margin-top: -1px;
}
#coPilotTeamSetting, #quickAssistTeamSetting {
  margin-bottom: 25px;
}
#coPilotOptOutCheckLabel {
  font-weight: normal;
}
#coPilotOptOutFormGroup {
  margin: 20px 0 5px 0;
}
.sapButton {
  height: 20px;
  border-radius: 3px;
  padding: 0 8px;
  line-height: 20px;
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}
.sapButton i {
  margin-right: 5px;
}
.sapButton.esignWaiting {
  background-color: #dce1e3;
  color: #9aa3a7;
}

.sapButton.esignReady {
  background-color: #bfc8cd;
  color: #55636b;
}
.sapButton.esignReady:hover {
  background-color: #abb8be;
}

.sapButton.esignSigned {
  background-color: #3dbd69;
  color: #ffffff;
}
.sapButton.esignSigned:hover {
  background-color: #24a851;
}
.sapButton.esignSigned i {
  color: #2da557;
}
#loanDocNamesWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
#loanDocNamesWrapper div {
  margin-right: 50px;
}
.sapTableWrapper {
  margin-bottom: 50px;
}
#sapDownloadWrapper h4 {
  margin-bottom: 20px;
}
#sapDownloadWrapper label {
  font-weight: 400;
}
#sapDownloadWrapper div {
  margin-bottom: 5px;
}
#sapDownloadWrapper .btn {
  margin-top: 10px;
}
.sapEsign {
  background: #ffcf00;
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 3px;
  border: 2px solid #e0c032;
  margin-top: 1px;
  margin-left: 5px;
}
.sapEsign i {
  width: 100%;
  text-align: center;
  color: #7d6701 !important;
}
#sapFileInputWrap {
  padding: 30px 20px;
}
.sapButton.esignWaiting:hover .closingTimePopOver {
  display: block;
}
.closingTimePopOver {
  background: #fff;
  border-radius: 6px;
  border: solid 2px #b2b2b2;
  width: 300px;
  /*padding:20px;*/
  position: absolute;
  left: -310px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
.closingTimePopOverTitle {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #131727;
  background: #f7f7f7;
  padding: 7px 20px;
  line-height: 1.71;
  margin: 0;
  border-radius: 6px 6px 0 0;
}
.closingTimePopOverText {
  padding: 15px 20px;
  margin: 0;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0px;
  color: #131727;
  font-weight: 400;
}
.right-arrow:after,
.right-arrow:before {
  /*left: 97%;*/
  /*right:-10px;*/
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.right-arrow:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #ffffff;
  border-width: 10px;
  margin-top: -10px;
  right: -20px;
}
.right-arrow:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: #b2b2b2;
  border-width: 12px;
  margin-top: -12px;
  right: -24px;
}
.sapTable tbody tr:hover {
  cursor: pointer;
}
.an3ShowMoreBtn {
  color: #337ab7;
  font-style: italic;
  cursor: pointer;
  margin-left: 8px;
}

/* because ".no-margin" exists above but does not work as advertised */
.really-no-margin {
  margin: 0;
}

.new-modal {
  color: #333;
}
.new-modal h1 {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.69px;
}
.new-modal input {
  margin-right: 10px;
  margin-bottom: 4px;
}
.new-modal textarea {
  min-height: 100px;
  resize: vertical;
}
.new-modal .modal-footer {
  margin-top: 0;
}

.modal-body .form-group.checkbox.modalToggle {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
  margin-top: 5px;

  width: calc(100% + 30px);
  margin-left: -15px;
  padding-left: 20px;
}
.modal-body .form-group.checkbox.modalToggle.unselected {
  margin-bottom: 0;
  padding-bottom: 2px;
  border-bottom: none;
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-padding {
  padding: 0;
}
.no-border {
  border: none;
}
.help-span {
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #8f8f8f;
}

.pointer {
  cursor: pointer;
}
.fade-text {
  color: rgba(0, 0, 0, 0.3);
}

/* ===== BEGIN Document Viewer Specific ===== */
.btn-secondary {
  background-color: #e3e3e3;
  border: solid 1px #ccc;
  border-radius: 3px;
  color: #333;
  padding: 9px 12px 9px 12px;
  height: 34px;
}

/* Modal Header */
#myModal.document-viewer #modalheaderdiv {
  position: relative;
}
#myModal.document-viewer #btn-rename-doc {
  line-height: initial;
  padding: 5px 12px 4px;
  margin: 0 10px;
}
#myModal.document-viewer #docRenameInput {
  padding: 0px 5px;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.21);
  border: solid 1px #cccccc;
  background-color: #ffffff;
  width: 35%;

  flex-grow: 1;
  position: relative;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
}

#myModal.document-viewer .modal-header-controls {
  display: flex;
}
#myModal.document-viewer .modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
}

#myModal.document-viewer .modal-header .modal-header-controls .btn {
  padding-top: 4px;
  padding-bottom: 5px;
}

#myModal.document-viewer .modal-header .modal-header-controls .btn-secondary {
  line-height: initial;
  margin-right: 10px;
}

#myModal.document-viewer .modal-header .modal-header-controls .close {
  border-left: 1px solid #e5e5e5;
  padding-left: 10px;
}

#myModal.document-viewer .modal-header .rename-buttons {
  white-space: nowrap;
  margin-right: 10px;
}
#myModal.document-viewer .modal-header .rename-buttons button {
  line-height: normal;
  height: 34px;
}

#myModal.document-viewer .modal-header .mobile-messages {
  display: none;
}
#myModal.document-viewer .renameSuccess {
  background-color: #ebf6ff;
  color: #3278b7;

  height: 34px;
  line-height: 33px;
  width: 160px;
}
#myModal.document-viewer .renameSuccess,
#myModal.document-viewer .renameError {
  text-align: center;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  z-index: 20;
  margin-left: 6px;
}
#myModal.document-viewer .renameError {
  background-color: #fbded8;
  font-size: 12px;
  color: #b91c1c;
  height: 36px;
  padding: 1px 9px;
  width: 165px;
}
#myModal.document-viewer #myModalLabel {
  display: inline-block;
  margin: 0;
  padding: 5px 0 5px 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#myModal.document-viewer .flex-spacer {
  flex-grow: 1;
}

@media screen and (max-width: 1155px) {
  #myModal.document-viewer .modal-header .mobile-messages {
    display: inline-block;
  }

  #myModal.document-viewer .modal-header .modal-header-controls {
    float: right;
  }
  #myModal.document-viewer .modal-header {
    display: block;
  }
  #myModal.document-viewer #myModalLabel {
    padding-left: 16px;
    margin-bottom: 10px;
    display: block;
  }
  #myModal.document-viewer #myModalLabel {
    display: block;
    max-width: 100%;
    margin-bottom: 10px;
  }
  #myModal.document-viewer .renameSuccess,
  #myModal.document-viewer .renameError {
    position: absolute;
    right: unset;
  }
  #myModal.document-viewer .renameSuccess {
    top: 56px;
    left: 180px;
    width: 155px;
  }
  #myModal.document-viewer .renameError {
    top: 55px;
    left: 165px;
  }

  #myModal.document-viewer .modal-header .rename-buttons {
    margin-right: 10px;
    display: inline-block;
  }
  #myModal.document-viewer .modal-header .flex-spacer {
    display: none;
    flex-grow: unset;
  }
  #myModal.document-viewer .modal-header .modal-header-controls #btn-next-doc {
    margin-right: 10px;
  }
  #myModal.document-viewer #docRenameInput {
    display: block;
    width: 90%;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  #myModal.document-viewer .modal-header .modal-header-controls button.close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

@media screen and (max-width: 555px) {
  #myModal.document-viewer .modal-header .rename-buttons {
    margin-bottom: 0;
  }
}

/* Modal Body */
#myModal.document-viewer .modal-body {
  padding-bottom: 0px;
}

/* Modal FOOTER */
#myModal.document-viewer .modal-footer {
  padding: 12px 14px;
}

#myModal.document-viewer .modal-footer .actions-right {
  display: flex;
}

#myModal.document-viewer .modal-footer .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#myModal.document-viewer .modal-footer .btn .fa {
  padding-right: 10px;
}

/* Delete */
#myModal.document-viewer .modal-footer #btn-delete {
  margin-left: 4px;
}

/* Delete Prompt */
#myModal.document-viewer .modal-footer .delete-prompt {
  text-align: left;
}

#myModal.document-viewer .modal-footer .delete-prompt .delete-prompt-text {
  border-right: 1px solid #e5e5e5;
  margin-right: 15px;
  padding: 8px 15px 8px 0px;
}

/* Accept */
#myModal.document-viewer .modal-footer #btn-accept {
}

/* Reject */
#myModal.document-viewer .modal-footer #btn-reject {
  margin-left: 10px;
}

/* Reject Prompt */
#myModal.document-viewer .modal-footer .reject-prompt {
  display: flex;
  flex-wrap: nowrap;
}

#myModal.document-viewer .modal-footer .reject-prompt #confirm-reject {
  height: 34px;
}

#myModal.document-viewer .modal-footer .reject-prompt #cancel-reject {
  margin-left: 10px;
}

#myModal.document-viewer .modal-footer .reject-prompt .reject-code-container,
#myModal.document-viewer .modal-footer .reject-prompt .reject-reason-container {
  display: flex;
  align-items: center;
}

#myModal.document-viewer .modal-footer .reject-prompt .reject-code-container {
  margin-right: 30px;
}

#myModal.document-viewer
  .modal-footer
  .reject-prompt
  .reject-code-container
  .rejectCode {
  width: 160px;
}

#myModal.document-viewer .modal-footer .reject-prompt .reject-reason-container {
  width: 100%;
}

#myModal.document-viewer
  .modal-footer
  .reject-prompt
  .reject-code-container
  label,
#myModal.document-viewer
  .modal-footer
  .reject-prompt
  .reject-reason-container
  label {
  margin-bottom: 0px;
  margin-right: 10px;
  white-space: nowrap;
}

#myModal.document-viewer
  .modal-footer
  .reject-prompt
  .reject-code-container.has-error
  #rejectCode,
#myModal.document-viewer .error-text {
  color: #a94442;
}

#myModal.document-viewer .modal-footer .reject-prompt .accept-reject-container {
  border-right: 1px solid #e5e5e5;
  font-size: 12px;
  margin: 0px 15px;
  min-width: 425px;
  padding-right: 10px;
  text-align: left;
}

/* ===== END Document Viewer Specific ===== */

/* ===== BEGIN Indiv Loan Pending Docs Bulk Actions Specific ===== */

.docs-pending-table .bulk-actions-content,
.docs-accepted-table .bulk-actions-content {
  margin: 0px 0px 15px 15px;
}

.docs-pending-table .num-selected-text {
  color: #787676;
}

.docs-pending-table .bulk-actions-container .bulk-actions-content .btn i {
  margin-right: 5px;
}

/* --- START SPECIAL HANDLING --- */
/* Note (floifygene -20210426): this is part of a temporary hotfix for FLO-7582 as
** the bulk actions feature on Docs Owed and Docs Accepted has not yet been
** applied. This block of styling should be removed as FLO-7367 is worked on */
/* .docs-pending-table .uploaded-docs-col-heading {  // floifymatt (7/26/22) leaving commented out in case we need to revert back to this
  margin-right: 40px;
  white-space: nowrap;
}

.docs-pending-table .flow-table-doc.col-sm-1 {
  margin-right: 40px;
} */

/* --- END SPECIAL HANDLING --- */

.docs-pending-table .flow-table-doc {
  white-space: nowrap;
}

.indiv-loan-pending-docs.modal .modal-dialog {
  overflow-y: initial !important;
}

#bulk-accept-modal .modal-body,
#bulk-delete-modal .modal-body {
  overflow-y: auto;
  max-height: 80vh;
}

.indiv-loan-pending-docs.modal .bulk-reject-doc-list-container {
  margin-bottom: 0px;
}

.indiv-loan-pending-docs.modal #bulk-reject-doc-list {
  overflow-y: auto;
  max-height: 50vh;
}

.indiv-loan-pending-docs.modal #bulk-reject-doc-list input:last-child {
  margin-bottom: 15px;
}

.indiv-loan-pending-docs.modal input[class*="doc-item-"] {
  margin-bottom: 10px;
}

.indiv-loan-pending-docs .bulk-actions-container .bulk-actions-content {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 12px;
  letter-spacing: -0.1px;
  margin: 20px 0px 20px 8px;
}

#bulk-reject-modal .doc-list-separator {
  border: solid 1px #e5e5e5;
  margin-left: -5px;
}

#bulk-reject-modal .reject-code-container.has-error #rejectCode {
  color: #a94442;
}

#bulk-reject-modal .accept-reject-container input {
  margin-right: 5px;
}

/* ===== END Indiv Loan Pending Docs Bulk Actions Specific ===== */

/* ===== BEGIN Loan Pipeline Bulk Actions Specific ===== */
#loans_table thead .select-all-checkbox,
#company_active_loans_table thead .select-all-checkbox,
#company_archived_loans_table thead .select-all-checkbox,
#archived_loans_table thead .select-all-checkbox {
  padding-left: 8px !important;
  width: 20px;
}

.mobile-select-all-msg {
  display: none;
}

#loans_table tbody tr td:first-child,
#company_active_loans_table tbody tr td:first-child,
#company_archived_loans_table tbody tr td:first-child,
#archived_loans_table tbody tr td:first-child {
  min-width: 20px;
}

.archived-loans-table .bulk-actions-container .in-view-or-all-content,
.archived-loans-table .bulk-actions-container .bulk-actions-content,
.archived-loans-table .bulk-actions-container .bulk-actions-controls,
#assigned-to-me-active-loans .bulk-actions-container .bulk-actions-controls,
#assigned-to-me-active-loans .bulk-actions-container .bulk-actions-content,
#assigned-to-me-active-loans .bulk-actions-container .in-view-or-all-content,
#assigned-to-me-archived-loans .bulk-actions-container .bulk-actions-controls,
#assigned-to-me-archived-loans .bulk-actions-container .bulk-actions-content,
#assigned-to-me-archived-loans .bulk-actions-container .in-view-or-all-content,
.active-loans-table .bulk-actions-container .in-view-or-all-content,
.active-loans-table .bulk-actions-container .bulk-actions-content,
.active-loans-table .bulk-actions-container .bulk-actions-controls {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 12px;
  height: 26px;
  letter-spacing: -0.1px;
  margin: 27px 0px 27px 8px;
}

.active-loans-table .bulk-actions-container .btn i,
#assigned-to-me-active-loans .bulk-actions-container .btn i,
#assigned-to-me-archived-loans .bulk-actions-container .btn i,
.archived-loans-table .bulk-actions-container .btn i {
  margin-right: 5px;
}

.bulk-actions-container .in-view-or-all-content .in-view-or-all-text {
  background-color: rgba(50, 122, 183, 0.11);
  border-radius: 3px;
  height: 26px;
  margin-right: 10px;
  padding: 5px 10px;
  white-space: nowrap;
}

.bulk-actions-container .in-view-or-all-content .btn.btn-secondary,
.bulk-actions-container .bulk-actions-controls .btn,
.bulk-actions-container .bulk-actions-content .btn {
  margin-right: 10px;
  max-height: 25px;
  padding: 3px 10px;
}

@media (max-width: 507px) {
  .bulk-actions-container .bulk-actions-content #btn-copy {
    margin-top: 8px;
  }
}

.bulk-actions-container .bulk-actions-controls .num-selected-text,
.bulk-actions-container .bulk-actions-content .num-selected-text {
  color: #787676;
  font-size: 14px;
  margin-right: 10px;
}

.bulk-actions-container .bulk-actions-controls .num-selected,
.bulk-actions-container .bulk-actions-content .num-selected {
  font-weight: bold;
}

#bulk-add-followers-modal .modal-header,
#bulk-add-followers-modal .modal-body {
  padding-left: 30px;
  padding-right: 30px;
}

#bulk-add-followers-modal .followers-table {
  margin-top: 20px;
}

#bulk-add-followers-modal .followers-table th input[type="checkbox"] {
  margin-right: 2px;
}

#bulk-add-followers-modal .followers-table tr th:first-child,
#bulk-add-followers-modal .followers-table tr td:first-child {
  width: 120px;
}

/* ===== END Loan Pipeline Bulk Actions Specific ===== */

/* ===== BEGIN Prospects Bulk Actions Specific ===== */
#prospects_table thead th:nth-child(5) {
  width: 130px !important;
}
#prospects_table thead .select-all-checkbox-container {
  padding-left: 8px !important;
  width: 20px !important;
}

#prospects_table tbody .borrower-contact-info,
#prospects_table tbody .coborrower-contact-info {
  color: #626974;
  font-size: 12.5px;
  font-weight: 500;
  margin: 3px 0px 5px 0px;
}

#prospects_table tbody .bullet-separator {
  color: #c8ced8;
  font-size: 10px;
}

.prospects-bulk-actions.bulk-actions-container {
  align-items: center;
  display: flex;
  height: 80px;
}

.prospects-bulk-actions.bulk-actions-container .bulk-actions-content {
  margin-left: 8px;
}

.prospects-bulk-actions.bulk-actions-container .bulk-actions-content .btn i {
  margin-right: 5px;
}

.prospects-bulk-actions.bulk-actions-container .num-selected-text {
  margin-right: 25px;
}

/* ===== END Prospects Bulk Actions Specific ===== */

/* ===== BEGIN Admin > Users Bulk Actions Specific ===== */
#users_table_wrapper .bulk-actions-container {
  margin-top: -25px;
}

#users_table .select-all-checkbox-container {
  padding-left: 8px !important;
}

#bulk-convert-modal .modal-body {
  padding-left: 25px;
}

#bulk-convert-modal .modal-body label {
  display: block;
}

#bulk-convert-modal .modal-body #trial-exp-date {
  border-color: #e5e5e5;
  border-radius: 5px;
  border-style: solid;
  padding-inline-start: 10px;
  width: 250px;
}
#users_table_wrapper .bulk-actions-container .bulk-actions-content .btn i {
  margin-right: 5px;
}

#users_table .select-all-checkbox-container,
#users_table tbody tr td:first-child {
  min-width: 25px;
  width: 25px;
}

/* ===== END Admin > Users Bulk Actions Specific ===== */

/* ===== BEGIN Settings > Template Documents Bulk Actions Specific ===== */
#templates .bulk-actions-container {
  margin-left: 8px;
}

#templates .select-all-checkbox-container {
  width: 30px;
}
#templates .checkbox-container {
  min-width: 30px;
}

#templates .bulk-actions-container .num-selected-text {
  color: #787676;
  margin-right: 15px;
}

#templates .bulk-actions-container .num-selected {
  font-weight: bold;
}

#templates .bulk-actions-container .btn i {
  margin-right: 5px;
}

/* ===== END Settings > Template Documents Bulk Actions Specific ===== */

/* ===== BEGIN Settings > [Realtors, Partners] Bulk Actions Specific ===== */
#realtors_table .select-all-checkbox-container {
  padding-left: 8px !important;
  width: 30px !important;
}

#realtors_table tbody td:first-child {
  min-width: 30px;
}

.realtors-partners.bulk-actions-container {
  margin-top: 30px;
}

.realtors-partners.bulk-actions-container .bulk-actions-content .btn i {
  margin-right: 5px;
}

.mobile-select-all-th {
  display: none;
}

/* ===== END Settings > [Realtors, Partners] Bulk Actions Specific ===== */

/* ===== BEGIN Company Level LoanBeam Integration Modal Specific ===== */
#modalEditLoanBeamSettings .modal-header {
  padding: 15px 30px 15px 30px;
}

#modalEditLoanBeamSettings .modal-header h1 {
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
}

#modalEditLoanBeamSettings .modal-header .close {
  margin-top: 8px;
}

#modalEditLoanBeamSettings .modal-body .col-sm-12 {
  padding: 0px 30px 0px 30px;
}

#modalEditLoanBeamSettings .modal-body .api-key-input-container {
  padding: 0px 15px 0px 15px;
}

#modalEditLoanBeamSettings
  .modal-body
  .api-key-input-container
  .error-missing-api-key,
#modalEditLoanBeamSettings
  .modal-body
  .api-key-input-container
  .error-invalid-creds,
#modalEditLoanBeamSettings
  .modal-body
  .api-key-input-container
  .successful-connection-msg {
  margin-top: 15px;
  margin-bottom: 0px;
}

#modalEditLoanBeamSettings .modal-body .allow-lb-checkbox-container {
  margin: 10px 0px 20px 0px;
}

#modalEditLoanBeamSettings .modal-body .allow-lb-checkbox-container label {
  color: #737373;
  font-weight: normal;
}

#modalEditLoanBeamSettings .modal-body .lb-credentials-container,
#modalEditLoanBeamSettings .modal-body .callback-url-container {
  border-top: 1px solid #e5e5e5;
}

#modalEditLoanBeamSettings .modal-body .lb-credentials-container {
  padding-top: 20px;
}

#modalEditLoanBeamSettings .modal-body .callback-url-container {
  padding-top: 20px;
  margin-top: 15px;
}

#modalEditLoanBeamSettings
  .modal-body
  .callback-url-container
  .callback-url-input-section {
  align-items: center;
  display: flex;
  white-space: nowrap;
}

#modalEditLoanBeamSettings
  .modal-body
  .callback-url-container
  .callback-url-input-section
  #callback-url {
  width: 65%;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
}

#modalEditLoanBeamSettings
  .modal-body
  .callback-url-container
  .callback-url-input-section
  #clipkey {
  height: 34px;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  justify-content: center;
  padding: 6px 15px;
  margin-right: 10px;
}

#modalEditLoanBeamSettings
  .modal-body
  .callback-url-container
  .callback-url-input-section
  #callback-url-copied-msg {
  background-color: #fff1a0;
  padding: 6px 12px;
  border-radius: 4px;
  display: none;
}

#modalEditLoanBeamSettings .input-desc {
  color: #737373;
  font-size: 12px;
  padding-top: 10px;
}
/* ===== END Company Level LoanBeam Integration Modal Specific ===== */

/* ===== BEGIN Team Level LoanBeam Integration Modal Specific ===== */
#modalEditLoanBeamTeamSettings .modal-header {
  padding: 15px 30px 15px 30px;
}

#modalEditLoanBeamTeamSettings .modal-header h1 {
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
}

#modalEditLoanBeamTeamSettings .enable-loanbeam-checkbox-container {
  margin: 20px 15px;
}

#modalEditLoanBeamTeamSettings .enable-loanbeam-checkbox-container label {
  font-weight: normal;
}

#modalEditLoanBeamTeamSettings .user-info-container,
#modalEditLoanBeamTeamSettings .location-select-container {
  border-top: 1px solid #e5e5e5;
}

#modalEditLoanBeamTeamSettings .user-info-container,
#modalEditLoanBeamTeamSettings .location-select-container,
#modalEditLoanBeamTeamSettings .missing-items-container,
#modalEditLoanBeamTeamSettings .borrower-response-container,
#modalEditLoanBeamTeamSettings .auto-send-container {
  padding: 20px 15px 0px 15px;
}

#modalEditLoanBeamTeamSettings .borrower-response-container .col-sm-12 {
  margin-bottom: -10px;
}

#modalEditLoanBeamTeamSettings
  .enable-loanbeam-checkbox-container
  input[type="checkbox"] {
  margin-right: 5px;
}

#modalEditLoanBeamTeamSettings .missing-items-container .col-sm-1,
#modalEditLoanBeamTeamSettings .auto-send-container .col-sm-1 {
  padding-right: 0px;
  margin-right: -10px;
}

#modalEditLoanBeamTeamSettings .missing-items-container .col-sm-11,
#modalEditLoanBeamTeamSettings .auto-send-container .col-sm-11 {
  padding-left: 0px;
}

#modalEditLoanBeamTeamSettings .desc-text {
  color: #727272;
  font-size: 12px;
}

#modalEditLoanBeamTeamSettings .desc-text.auto-send-desc {
  margin-top: 5px;
}

/* ===== END Team Level LoanBeam Integration Modal Specific ===== */

/* ===== BEGIN (Main) LoanBeam Modal Specific ===== */
.loanbeam-modal-order-info {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  margin-left: -20px;
  padding-left: 5px;
}

.order-info-heading {
  font-weight: bold;
}

.order-info-body label {
  font-weight: normal;
}

.order-info-body .existing-order-info {
  color: #8f8f8f;
  font-size: 12px;
  margin-left: 5px;
}

.order-info-body .jointOrSeparate {
  margin-bottom: 10px;
}

.order-info-body .jointOrSeparate .col-sm-1 {
  margin-right: -10px;
}

.order-info-body .jointOrSeparate .order-selection .col-sm-1 {
  margin-right: -5px;
}

.order-info-body .jointOrSeparate .col-sm-11 {
  padding-left: 0px;
}

.order-info-body .jointly-desc,
.order-info-body .jointly-application-id,
.order-info-body .separately-desc {
  color: #737373;
  font-size: 12px;
  margin-bottom: 0px;
}

.order-info-body .jointly-application-id {
  color: initial;
  margin-top: 5px;
}

.order-info-body .order-selection {
  margin-top: 15px;
}

.order-info-body .order-selection .col-sm-11 {
  padding-left: 0px;
}

.order-info-body .order-selection .borrower-name,
.order-info-body .order-selection .co-borrower-name {
  margin-bottom: 0px;
}

.order-info-body .order-selection .application-id-label {
  color: #424242;
  font-size: 12px;
  font-weight: bold;
}

.order-info-body .order-selection .borrower-application-id,
.order-info-body .order-selection .co-borrower-application-id {
  color: #3a3a3a;
  font-size: 12px;
}

.order-info-body .order-split .col-sm-11 {
  padding-left: 0px;
}

.order-info-body .order-split .order-split-warning {
  font-size: 12px;
}

.order-info-body .order-info {
  margin-left: 0px;
}

.order-info-body .order-info .borrower-order-info,
.order-info-body .order-info .co-borrower-order-info {
  font-size: 12px;
}

.order-info-body .order-info .borrower-application-id,
.order-info-body .order-info .co-borrower-application-id {
  color: #8f8f8f;
}

#loanbeam-modal .loanbeam-modal-documents-tables td {
  padding: 10px 8px;
}

#loanbeam-modal .loanbeam-modal-documents-tables .select-doc-container {
  min-width: 25px;
  width: 25px;
}

#loanbeam-modal .loanbeam-modal-documents-tables .doc-details {
  width: 425px;
}

#loanbeam-modal .loanbeam-modal-documents-tables .status-container {
  width: 225px;
}

#loanbeam-modal .loanbeam-modal-documents-tables .doc-details.on-order,
#loanbeam-modal .loanbeam-modal-documents-tables .order-assignee {
  color: #787676;
  font-style: italic;
}

.doc-details p {
  margin: 0px;
}

.doc-details .doc-desc {
  color: #737373;
  font-size: 12px;
}

.loanbeam-modal-documents-tables table[disabled="true"]:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
}

/* ===== END (Main) LoanBeam Modal Specific ===== */

/* ===== BEGIN Pending Conditions/LoanBeam Missing Notes Modal ===== */
#pendingConditions .lb-missing-notes-heading,
#pendingConditions .pending-conditions-heading {
  font-size: 16px;
  font-weight: bold;
}

#pendingConditions .missing-notes-owner,
#pendingConditions .pending-conditions-subheading {
  color: #8f8f8f;
  font-size: 14px;
}

#pendingConditions .missing-notes-owner .borrower-name,
#pendingConditions .missing-notes-owner .coborrower-name {
  color: #333;
  font-weight: bold;
}

#pendingConditionsForm hr {
  border-bottom: 1px solid #e5e5e5;
  margin: 25px -25px;
}
/* ===== END Pending Conditions/LoanBeam Missing Notes Modal ===== */

/* ===== BEGIN (Main) LoanBeam related styling ===== */
.lb-order-status-container {
  color: #787676;
  font-style: italic;
  white-space: nowrap;
}

.lb-order-status-container p {
  margin-bottom: 0px;
}

.flow-table-status .lb-order-status-container .lb-order-status.completed {
  color: #337ab7;
  font-style: normal;
}

.flow-table-status .lb-order-status-container .lb-order-status.processing {
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.flow-table-status .loanbeam-auto-send p {
  color: #787676;
  font-size: 13px;
  margin-bottom: 0px;
}

.flow-table-status .loanbeam-auto-send .loanbeam-auto-send-icon {
  margin-right: 7px;
}

.flow-table-status .loanbeam-auto-send .loanbeam-auto-send-icon.fa-times {
  color: #cd1700;
}

.flow-table-status .loanbeam-auto-send .loanbeam-auto-send-icon.fa-check {
  color: #24a851;
}

.flow-table-status .loanbeam-auto-send .order-assignee {
  font-size: 13px;
  font-style: italic;
}

.flow-table-status .loanbeam-auto-send a {
  color: #333;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}

.flow-table-status .loanbeam-auto-send .loanbeam-auto-send-nsf {
  color: #424242;
  font-weight: bold;
}

.flow-table-actions .send-to-loanbeam-action:hover {
  cursor: pointer;
}
.flow-table-actions .send-to-loanbeam-action[disabled]:hover {
  cursor: not-allowed;
}

.flow-table-actions .send-to-loanbeam-action[disabled] i {
  opacity: 0.4;
}

/* ===== END (Main) LoanBeam related styling ===== */

/* ===== BEGIN Edit Loan Flow page ===== */

.edit-loan__heading {
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.364286px;
  color: #131727;
}

.edit-loan__detail-icon {
  color: #737373;
  margin-left: 10px;
}

.edit-loan__paragraph {
  color: #737373;
}

.edit-loan-encompass__file-heading {
  margin-top: 0;
}

.edit-loan-encompass__selected-file {
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  padding: 15px 28px;
  display: flex;
  align-items: center;
}

.edit-loan-encompass__file-heading {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
}
.edit-loan-encompass__selected-file-radio {
  margin-right: 18px;
}

.edit-loan-encompass__selected-file-id {
  display: block;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
}

.edit-loan-encompass__selected-file-description {
  display: block;
  font-weight: 500;
  font-size: 12px;
  color: #8e8e8e;
  margin-bottom: 0;
}

.edit-loan-encompass__selected-file-description-detail {
  color: #2e76f6;
  margin-left: 11px;
}

.edit-loan-borrowers__select {
  width: 300px;
  max-width: 100%;
  text-overflow: ellipsis;
}

.edit-loan-borrowers__heading {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.364286px;
  color: #333333;
  margin-bottom: 15px;
}

.edit-loan-borrowers__drag-background {
  background: #f8fafc;
  border-radius: 8px;
  padding: 24px;
}

.edit-loan-borrowers__empty-background {
  padding: 24px;
}

.edit-loan-borrowers__borrower-divider {
  display: flex;
  align-items: center;
  height: 45px;
}

.edit-loan-borrowers__primary-borrower-heading {
  font-size: 12px;
  color: #4a9eff;
}

.edit-loan-borrowers__co-borrower-heading {
  font-size: 12px;
  color: #475569;
  flex-grow: 1;
  align-self: flex-end;
}

.edit-loan-borrowers__primary-inactive-borrower-heading {
  font-size: 12px;
  color: #94a3b8;
}

.edit-loan-borrowers__draggable-borrower {
  background: #ffffff;
  border: 1px solid #e0e6ea;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 21px;
  padding-right: 16px;
  height: 64px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.edit-loan-borrowers__draggable-borrower-empty-slot {
  border: 1px dashed #e0e6ea;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 21px;
  padding-right: 16px;
  height: 64px;
}

.edit-loan-borrowers__draggable-borrower:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.edit-loan-borrowers__draggable-grip {
  color: #94a3b8;
  font-size: 2rem;
  margin-right: 21px;
}

.edit-loan-borrowers__add-icon {
  color: #94a3b8;
  margin-right: 20px;
}

.edit-loan-borrowers__draggable-text-wrapper {
  flex-grow: 1;
}

.edit-loan-borrowers__draggable-borrower-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.222985px;
  color: #0f172a;
  margin: 0;
  margin-bottom: 4px;
}

.edit-loan-borrowers__empty-slot-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.222985px;
  margin: 0;
  color: #64748b;
}

.edit-loan-borrowers__draggable-borrower-details {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.002859px;
  color: #334155;
  margin: 0;
}

.text-button,
.icon-button {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: #4579b2;
}

.text-button {
  font-size: 14px;
  height: 32px;
}

.icon-button {
  padding: 0;
  height: 32px;
  width: 32px;
  font-size: 15px;
}

.text-button:hover {
  background-color: #ebebeb;
}

.icon-button:hover {
  background-color: #ebebeb;
}

.icon-button .fa-pencil-square-o {
  position: relative;
  left: 2px;
}

/* ===== END Edit Loan Flow page ===== */

@media screen and (max-width: 900px) {
  .mobile-select-all-msg {
    margin-left: 5px !important;
    display: block !important;
  }
  #loans_table thead .select-all-checkbox,
  #archived_loans_table thead .select-all-checkbox,
  #company_archived_loans_table thead .select-all-checkbox,
  #company_active_loans_table thead .select-all-checkbox {
    display: flex !important;
    width: 100% !important;
    padding-left: 0 !important;
  }
  .active-loans-table .bulk-actions-container .in-view-or-all-content,
  #assigned-to-me-archived-loans .bulk-actions-container .in-view-or-all-content,
  #assigned-to-me-active-loans .bulk-actions-container .in-view-or-all-content {
    height: auto !important;
    flex-wrap: wrap !important;
    margin: 20px 15px 10px 15px !important;
  }
  .bulk-actions-container .in-view-or-all-content .in-view-or-all-text {
    height: auto !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    white-space: normal !important;
  }
  #prospects_table thead .select-all-checkbox-container {
    display: flex !important;
    width: auto !important;
  }
  #flow-nav-pills li.owed:not(.active) {
    margin-left: 0px;
  }
  ul#flow-nav-pills {
    flex-direction: column;
  }
  #flow-nav-pills > li {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  #flow-nav-pills li a {
    margin: 5px 0;
  }
  .num-selected-text {
    display: block;
    margin-bottom: 5px;
  }
  #flow-table-header div:not(.select-all-checkbox-container) {
    display: none;
  }
  .flow-table #flow-table-header .column-heading {
    width: auto;
  }
  .mobile-select-all-th {
    margin-left: 5px;
    display: inline;
  }
}

@media only screen and (min-width: 992px) {
  li.activity-list-item {
    display: none;
  }

  #loanbeam-modal .modal-dialog {
    width: 960px;
  }

  .order-info-body .jointOrSeparate .col-sm-1 {
    margin-right: -40px;
  }

  .order-info-body .jointOrSeparate .order-selection .col-sm-1 {
    margin-right: -35px;
  }
}
.react-tiny-popover-container {
  z-index: 20000;
}
#fixedActionFooterContainerEditLoan {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 0;
  background: #fff;
  border-top: 1px solid #E3E8EF;
  z-index: 10;
}
.ir-waterfall-step-history-toggle {
  display: block;
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
}
.ir-waterfall-step-history-container {
  margin-top: 6px;
}
.ir-waterfall-step-history {
  padding: 0;
}
.ir-waterfall-step-row {
  display: flex;
  margin-top: 4px;
}
.ir-waterfall-step {
  display: grid;
  grid-template-columns: 28% 10% 41% 21%;
  grid-gap: 4px;
  width: 100%;
  font-size: 12px;
  line-height: 14px;
}

.ir-waterfall-step > div {
  padding: 8px 10px;
  background: #E8E8E8;
}

.ir-waterfall-step .step-vendor {
  background: #D1D1D1;
}

#modalInstallCompanyLevelAppProperties {
  margin-top: 14px;
}

@media (max-width: 900px) {
  .document-actions-wrapper {
    padding-top: 24px;
  }
}

/* BEGIN: custom dropdown web component -------------------- */
dropdown-button .dropdown .dropdown-toggle {
  background-color: white !important;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 1px 2px 0px #1018280d;
  height: 34px;
  min-width: 220px;
  padding: 0px 12px 0px 16px;
}

dropdown-button .dropdown .dropdown-toggle .dropdown-toggle__content {
  align-items: center;
  display: flex;
  text-align: left;
}

dropdown-button .dropdown .dropdown-toggle .dropdown-toggle__label {
  flex: 1 1 auto;
}

dropdown-button .dropdown .dropdown-toggle .dropdown-toggle__content i {
  font-size: 12px;
}

dropdown-button .dropdown-menu {
  position: absolute;
  top: auto;
  left: auto;

  padding: 4px;
  border-radius: 6px;
  border: 1px solid #e4e4e4;
  min-width: 350px;
}

dropdown-button .dropdown-menu .dropdown-menu-option {
  cursor: pointer;
  border-radius: 6px;
  padding: 12px 16px;
}

dropdown-button .dropdown-menu .dropdown-menu-option.selected,
.dropdown-menu-option:hover {
  background-color: #f9f9f9;
}

dropdown-button .dropdown-menu .dropdown-menu-option.selected i {
  color: #4576ae;
}

dropdown-button .dropdown-menu .dropdown-menu-option__label {
  display: flex;
  align-items: center;
  gap: 8px;
}

dropdown-button .dropdown-menu .dropdown-menu-option__description {
  color: #626974;
}
/* END: custom dropdown web component -------------------- */

.pointer-events-none {
  pointer-events: none;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.fv-tooltip {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  max-width: 300px !important;
  padding: 8px 12px !important;
  margin-top: -5px !important;
  justify-content: center !important;
  align-items: flex-end !important;
  opacity: 1 !important;
  border-radius: 8px !important;
  background: #21201c !important;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
  -webkit-fonts-smoothing: none !important;
}
.fv-tooltip::after {
  display: none !important;
}